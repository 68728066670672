import { useState, useEffect } from "react";
import { useTheme } from "../../contexts/ThemeContext";
import { useNavigate, useLocation } from "react-router-dom";
import { XMarkIcon } from "@heroicons/react/24/outline";
import giftCardService from '../../services/giftCardService';
import toast from 'react-hot-toast';

const AddGiftCard = () => {
  const { theme } = useTheme();
  const isDarkMode = theme === "dark";
  const navigate = useNavigate();
  const location = useLocation();
  const editData = location.state?.editData;

  const initialState = {
    amount: "",
    discount: "",
    validity: "never",
    validityDays: "",
    id: null // Add id field for editing
  };

  const [formData, setFormData] = useState(editData || initialState);
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};
    if (!formData.amount || formData.amount <= 0) {
      newErrors.amount = "Amount is required and must be greater than 0";
    }
    if (
      !formData.discount ||
      formData.discount < 0 ||
      formData.discount > 100
    ) {
      newErrors.discount = "Discount must be between 0 and 100";
    }
    if (
      formData.validity === "days" &&
      (!formData.validityDays || formData.validityDays <= 0)
    ) {
      newErrors.validityDays = "Please enter valid number of days";
    }
    return newErrors;
  };

  const handleSubmit = async (action) => {
    const newErrors = validateForm();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    try {
      if (formData.id) {
        // Update existing gift card
        if(action === 'save') {
        await giftCardService.updateGiftCard(formData.id, formData);
        }
        else{
          await giftCardService.updateGiftCard(formData.id, formData);
          await giftCardService.publishGiftCard(formData.id);
        }

      } else {
        // Create new gift card
        if (action === 'save') {
          await giftCardService.addGiftCard(formData);
        } else {
          await giftCardService.addAndPublishGiftCard(formData);
        }
      }
      navigate('/giftcards');
    } catch (error) {
      console.error(`Error ${action === 'save' ? 'saving' : 'publishing'} gift card:`, error);
    }
  };

  return (
    <div className="container mx-auto px-4 py-6">
      {/* Header */}
      <div className="flex justify-between items-center mb-6">
        <h1
          className={`text-2xl font-semibold ${
            isDarkMode ? "text-white" : "text-gray-900"
          }`}
        >
          Add Gift Card
        </h1>
        <button
          onClick={() => navigate("/giftcards")}
          className={`p-2 rounded-lg ${
            isDarkMode
              ? "text-slate-400 hover:text-slate-300 hover:bg-slate-700"
              : "text-gray-500 hover:text-gray-700 hover:bg-gray-100"
          }`}
        >
          <XMarkIcon className="h-6 w-6" />
        </button>
      </div>

      {/* Form */}
      <div
        className={`rounded-lg border p-6 ${
          isDarkMode
            ? "bg-slate-800 border-slate-700"
            : "bg-white border-gray-200"
        }`}
      >
        <form className="space-y-4">
          <div>
            <label
              className={`block mb-2 ${
                isDarkMode ? "text-slate-300" : "text-gray-700"
              }`}
            >
              Amount <span className="text-red-500">*</span>
            </label>
            <input
              type="number"
              value={formData.amount}
              onChange={(e) =>
                setFormData({ ...formData, amount: e.target.value })
              }
              className={`w-full px-3 py-2 rounded-lg border ${
                isDarkMode
                  ? "bg-slate-700 border-slate-600 text-white"
                  : "bg-white border-gray-300"
              }`}
              min="0"
            />
            {errors.amount && (
              <p className="text-red-500 text-sm mt-1">{errors.amount}</p>
            )}
          </div>

          <div>
            <label
              className={`block mb-2 ${
                isDarkMode ? "text-slate-300" : "text-gray-700"
              }`}
            >
              Discount (%) <span className="text-red-500">*</span>
            </label>
            <input
              type="number"
              value={formData.discount}
              onChange={(e) =>
                setFormData({ ...formData, discount: e.target.value })
              }
              className={`w-full px-3 py-2 rounded-lg border ${
                isDarkMode
                  ? "bg-slate-700 border-slate-600 text-white"
                  : "bg-white border-gray-300"
              }`}
              min="0"
              max="100"
            />
            {errors.discount && (
              <p className="text-red-500 text-sm mt-1">{errors.discount}</p>
            )}
          </div>

          <div>
            <label
              className={`block mb-2 ${
                isDarkMode ? "text-slate-300" : "text-gray-700"
              }`}
            >
              Validity <span className="text-red-500">*</span>
            </label>
            <div className="flex gap-4">
              <label className="flex items-center">
                <input
                  type="radio"
                  value="never"
                  checked={formData.validity === "never"}
                  onChange={(e) =>
                    setFormData({ ...formData, validity: e.target.value })
                  }
                  className="mr-2"
                />
                <span
                  className={isDarkMode ? "text-slate-300" : "text-gray-700"}
                >
                  Never expires
                </span>
              </label>
              <label className="flex items-center">
                <input
                  type="radio"
                  value="days"
                  checked={formData.validity === "days"}
                  onChange={(e) =>
                    setFormData({ ...formData, validity: e.target.value })
                  }
                  className="mr-2"
                />
                <span
                  className={isDarkMode ? "text-slate-300" : "text-gray-700"}
                >
                  Days
                </span>
              </label>
            </div>
            {formData.validity === "days" && (
              <input
                type="number"
                value={formData.validityDays}
                onChange={(e) =>
                  setFormData({ ...formData, validityDays: e.target.value })
                }
                className={`mt-2 w-full px-3 py-2 rounded-lg border ${
                  isDarkMode
                    ? "bg-slate-700 border-slate-600 text-white"
                    : "bg-white border-gray-300"
                }`}
                min="1"
                placeholder="Number of days"
              />
            )}
            {errors.validityDays && (
              <p className="text-red-500 text-sm mt-1">{errors.validityDays}</p>
            )}
          </div>

          <div className="flex justify-end gap-2 mt-6">
            <button
              type="button"
              onClick={() => navigate("/giftcards")}
              className={`px-4 py-2 rounded-lg ${
                isDarkMode
                  ? "bg-slate-700 text-slate-300 hover:bg-slate-600"
                  : "bg-gray-100 text-gray-700 hover:bg-gray-200"
              }`}
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={() => handleSubmit("save")}
              className={`px-4 py-2 rounded-lg ${
                isDarkMode
                  ? "bg-blue-600 text-white hover:bg-blue-700"
                  : "bg-blue-500 text-white hover:bg-blue-600"
              }`}
            >
              Save
            </button>
            <button
              type="button"
              onClick={() => handleSubmit("publish")}
              className={`px-4 py-2 rounded-lg ${
                isDarkMode
                  ? "bg-emerald-600 text-white hover:bg-emerald-700"
                  : "bg-emerald-500 text-white hover:bg-emerald-600"
              }`}
            >
              Publish
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddGiftCard;
