import { useState, useEffect, useRef } from 'react';
import { useTheme } from '../contexts/ThemeContext';
import { profileService } from '../services/profileService';
import businessService from '../services/businessService';

const Profile = () => {
  const { theme } = useTheme();
  const isDarkMode = theme === 'dark';

  const [businessInfo, setBusinessInfo] = useState({
    businessHandle: '@a1',
    businessCategory: 'Retail',
    businessName: 'A1 Business',
    businessWebsite: '',
    businessPhoneNumber: '+61 333333333',
    abn: '',
    gstStatus: 'Not Registered'
  });

  const [ownerInfo, setOwnerInfo] = useState({
    ownerName: 'A1 Owner',
    ownerEmail: 'anand4u94@gmail.com',
    ownerMemberId: 'B171236739084848',
    ownerReferralCode: 'BRMk7nlw',
    country: 'AU'
  });

  const [ownerBankAccountInfo, setOwnerBankAccountInfo] = useState({
    bankAccountName: 'unknown',
    bsbNumber: '',
    bankAccountNumber: '',
    bankName: '',
    branchName: ''
  });

  const [logoUrl, setLogoUrl] = useState('');
  const fileInputRef = useRef(null);

  // Add loading and error states
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch profile data on component mount
  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        setLoading(true);
        const businessResponse = await profileService.getBusinessInfo();
        const accountResponse = await profileService.getAccountInfo();

        // Update business and owner info
        setBusinessInfo({
          businessHandle: businessResponse.userName || '',
          businessCategory: businessResponse.category || '',
          businessName: businessResponse.name || '',
          businessWebsite: businessResponse.website || '',
          businessPhoneNumber: businessResponse.phoneNumber || '',
          abn: businessResponse.abn || '',
          gstStatus: businessResponse.isRegisteredForGST ? 'Registered' : 'Not Registered'
        });

        // Fetch logo URL from businessResponse
        setLogoUrl(businessResponse.logo || '');

        setOwnerInfo({
          ownerName: businessResponse.user.name || '',
          ownerEmail: businessResponse.user.email || '',
          ownerMemberId: businessResponse.user.memberId || '',
          ownerReferralCode: businessResponse.user.referralCode || '',
          country: businessResponse.user.country || 'AU'
        });

        // Update bank account info
        setOwnerBankAccountInfo({
          bankAccountName: businessResponse.bankAccount.name || '',
          bsbNumber: businessResponse.bankAccount.bsb || '',
          bankAccountNumber: businessResponse.bankAccount.number || '',
          bankName: businessResponse.bankAccount?.bankName || '',
          branchName: businessResponse.bankAccount?.branch || ''
        });

      } catch (err) {
        setError('Failed to load profile data');
      } finally {
        setLoading(false);
      }
    };

    fetchProfileData();
  }, []);

  const handleSaveBusinessInfo = async () => {
    try {
      await profileService.updateBusinessInfo(businessInfo);
    } catch (error) {
      console.error('Save Business Info error:', error);
    }
  };

  const handleSaveOwnerInfo = async () => {
    try {
      await profileService.updateOwnerInfo(ownerInfo);
    } catch (error) {
      console.error('Save Owner Info error:', error);
    }
  };

  const handleSaveAccountInfo = async () => {
    try {
      await profileService.updateAccountInfo(ownerBankAccountInfo);
    } catch (error) {
      console.error('Save Account Info error:', error);
    }
  };

  const handleLogoUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('logo', file);
      try {
        const response = await businessService.uploadLogo(formData);
        console.log('Upload Response:', response);
        if (response && response.logoContextURL) {
          setLogoUrl(`${response.logoContextURL}?t=${new Date().getTime()}`);
          console.log('New Logo URL:', response.logoContextURL);
        } else {
          console.error('Logo URL not found in response:', response);
        }
      } catch (error) {
        console.error('Error uploading logo:', error);
      }
    }
  };

  // Show loading state
  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  // Show error state
  if (error) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="text-red-500">{error}</div>
      </div>
    );
  }

  const capitalizeWords = (str) => {
    return str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  };

  console.log('Current Logo URL:', logoUrl);

  return (
    <div className={`container mx-auto px-4 py-6 ${isDarkMode ? 'bg-gray-900' : ''}`}>
      <div className="flex justify-between items-center mb-6">
        <h1 className={`text-2xl font-semibold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
          Profile
        </h1>
        <div className="relative">
          <img
            src={logoUrl || '/path/to/default/logo.png'}
            alt="Business Logo"
            className="w-12 h-12 rounded-full cursor-pointer"
            onClick={() => fileInputRef.current.click()}
          />
          <input
            type="file"
            accept="image/*"
            ref={fileInputRef}
            onChange={handleLogoUpload}
            className="hidden"
          />
        </div>
      </div>

      {/* Business Info */}
      <div className={`mb-6 rounded-lg border ${isDarkMode ? 'border-slate-700 bg-slate-800' : 'border-gray-200 bg-white'}`}>
        <div className="p-6">
          <h2 className={`text-lg font-semibold mb-4 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
            Business Info
          </h2>
          <div className="grid grid-cols-2 gap-4">
            {Object.entries(businessInfo).map(([key, value]) => (
              <div key={key} className="mb-4">
                <label htmlFor={key} className={`block text-sm font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                  {capitalizeWords(key.split(/(?=[A-Z])/).join(' '))}
                  {['businessHandle', 'businessCategory'].includes(key) && (
                    <span className="text-red-500">*</span>
                  )}
                </label>
                <input
                  type="text"
                  id={key}
                  name={key}
                  value={value}
                  onChange={(e) => setBusinessInfo({ ...businessInfo, [key]: e.target.value })}
                  className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm ${
                    isDarkMode ? 'bg-gray-700 text-white placeholder-gray-400' : ''
                  }`}
                  required={['businessHandle', 'businessCategory', 'businessName', 'businessPhoneNumber'].includes(key)}
                  readOnly={['businessHandle', 'businessCategory'].includes(key)}
                />
              </div>
            ))}
          </div>
          <button
            onClick={handleSaveBusinessInfo}
            className={`mt-4 px-4 py-2 rounded-md text-white ${
              isDarkMode ? 'bg-blue-600 hover:bg-blue-700' : 'bg-blue-500 hover:bg-blue-600'
            } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`}
          >
            Save Business Info
          </button>
        </div>
      </div>

      {/* Owner Info */}
      <div className={`mb-6 rounded-lg border ${isDarkMode ? 'border-slate-700 bg-slate-800' : 'border-gray-200 bg-white'}`}>
        <div className="p-6">
          <h2 className={`text-lg font-semibold mb-4 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
            Owner Info
          </h2>
          <div className="grid grid-cols-2 gap-4">
            {Object.entries(ownerInfo).map(([key, value]) => (
              <div key={key} className="mb-4">
                <label htmlFor={key} className={`block text-sm font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                  {capitalizeWords(key.split(/(?=[A-Z])/).join(' '))}
                  {['ownerName', 'ownerEmail', 'ownerMemberId', 'ownerReferralCode', 'country'].includes(key) && (
                    <span className="text-red-500">*</span>
                  )}
                </label>
                <input
                  type="text"
                  id={key}
                  name={key}
                  value={value}
                  onChange={(e) => setOwnerInfo({ ...ownerInfo, [key]: e.target.value })}
                  className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm ${
                    isDarkMode ? 'bg-gray-700 text-white placeholder-gray-400' : ''
                  }`}
                  required={['ownerName', 'ownerEmail', 'ownerMemberId', 'ownerReferralCode', 'country'].includes(key)}
                  readOnly={['ownerEmail', 'ownerMemberId', 'ownerReferralCode', 'country'].includes(key)}
                />
              </div>
            ))}
          </div>
          <button
            onClick={handleSaveOwnerInfo}
            className={`mt-4 px-4 py-2 rounded-md text-white ${
              isDarkMode ? 'bg-blue-600 hover:bg-blue-700' : 'bg-blue-500 hover:bg-blue-600'
            } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`}
          >
            Save Owner Info
          </button>
        </div>
      </div>

      {/* Owner Bank Account Info */}
      <div className={`rounded-lg border ${isDarkMode ? 'border-slate-700 bg-slate-800' : 'border-gray-200 bg-white'}`}>
        <div className="p-6">
          <h2 className={`text-lg font-semibold mb-4 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
            Owner Bank Account Info
          </h2>
          <div className="grid grid-cols-2 gap-4">
            {Object.entries(ownerBankAccountInfo).map(([key, value]) => (
              <div key={key} className="mb-4">
                <label htmlFor={key} className={`block text-sm font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                  {capitalizeWords(key.split(/(?=[A-Z])/).join(' '))}
                  {['bankAccountName', 'bsbNumber', 'bankAccountNumber'].includes(key) && (
                    <span className="text-red-500">*</span>
                  )}
                </label>
                <input
                  type="text"
                  id={key}
                  name={key}
                  value={value}
                  onChange={(e) => setOwnerBankAccountInfo({ ...ownerBankAccountInfo, [key]: e.target.value })}
                  className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm ${
                    isDarkMode ? 'bg-gray-700 text-white placeholder-gray-400' : ''
                  }`}
                  required={['bankAccountName', 'bsbNumber', 'bankAccountNumber'].includes(key)}
                />
              </div>
            ))}
          </div>
          <button
            onClick={handleSaveAccountInfo}
            className={`mt-4 px-4 py-2 rounded-md text-white ${
              isDarkMode ? 'bg-blue-600 hover:bg-blue-700' : 'bg-blue-500 hover:bg-blue-600'
            } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`}
          >
            Save Account Info
          </button>
        </div>
      </div>
    </div>
  );
};

export default Profile; 