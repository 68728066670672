import { useState, useEffect } from "react";
import { useTheme } from "../contexts/ThemeContext";
import {
  PencilSquareIcon,
  TrashIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  PlusCircleIcon,
  XMarkIcon,
  ArrowUpOnSquareIcon,
} from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import offerService from '../services/offerService';
import ImageCarousel from '../components/ImageCarousel';

const OfferDetailsModal = ({ offer, onClose, isDarkMode }) => {
  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex min-h-full items-center justify-center p-4">
        <div
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          onClick={onClose}
        />

        <div
          className={`relative max-w-4xl w-full ${
            isDarkMode ? "bg-slate-800" : "bg-white"
          } rounded-lg shadow-xl`}
        >
          {/* Modal Header */}
          <div
            className={`px-6 py-4 border-b flex items-center justify-between ${
              isDarkMode ? "border-slate-700" : "border-gray-200"
            }`}
          >
            <h3
              className={`text-lg font-semibold ${
                isDarkMode ? "text-white" : "text-gray-900"
              }`}
            >
              {offer.title}
            </h3>
            <button
              onClick={onClose}
              className={`p-1 rounded-lg ${
                isDarkMode
                  ? "text-slate-400 hover:text-slate-300 hover:bg-slate-700"
                  : "text-gray-400 hover:text-gray-600 hover:bg-gray-100"
              }`}
            >
              <XMarkIcon className="h-6 w-6" />
            </button>
          </div>

          {/* Modal Content */}
          <div className={`px-6 py-4 ${isDarkMode ? "bg-slate-700" : "bg-gray-100"} rounded-lg`}>
            <p
              className={`text-sm ${
                isDarkMode ? "text-slate-300" : "text-gray-900"
              } mb-2`}
            >
              {offer.description}
            </p>
            <p
              className={`text-sm font-medium ${
                isDarkMode ? "text-slate-300" : "text-gray-900"
              } mb-2`}
            >
              Valid till: {offer.expiryDate ? new Date(offer.expiryDate).toLocaleDateString() : '-'}
            </p>
            {offer.images && offer.images.length > 0 && (
              <ImageCarousel images={offer.images} isDarkMode={isDarkMode} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const Offers = () => {
  const { theme } = useTheme();
  const isDarkMode = theme === "dark";
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("active");
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const itemsPerPage = 10;
  const [offers, setOffers] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const fetchOffers = async () => {
    try {
      setLoading(true);
      const response = await offerService.listOffers(
        activePage - 1,
        itemsPerPage,
        activeTab === 'active' ? ['Draft', 'Published'] : ['Deleted']
      );
      
      // Handle the API response structure
      const offerList = response?.offerList || [];
      const total = response?.totalCount || 0;
      
      setOffers(offerList);
      setTotalCount(total);
      setTotalPages(Math.ceil(total / itemsPerPage));
    } catch (error) {
      console.error('Error fetching offers:', error);
      setOffers([]);
      setTotalCount(0);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOffers();
  }, [activePage, activeTab]);

  const handleDelete = async (id) => {
    try {
      await offerService.deleteOffer(id);
      fetchOffers();
    } catch (error) {
      console.error('Error deleting offer:', error);
    }
  };

  const handlePublish = async (id) => {
    try {
      await offerService.publishOffer(id);
      fetchOffers();
    } catch (error) {
      console.error('Error publishing offer:', error);
    }
  };

  const handleEdit = (offer) => {
    // Transform the offer data to match the expected format
    const editData = {
      id: offer.id,
      title: offer.title,
      description: offer.description,
      expiryDate: offer.expiryDate ? offer.expiryDate.split('T')[0] : '',
      selectedImages: offer.images ? offer.images.map(url => ({
        id: url, // Use URL as ID if no specific ID available
        url: url,
        title: url.split('/').pop() // Extract filename as title
      })) : []
    };

    // Check if the offer status is "Draft"
    if (offer.status === "Draft") {
      navigate('/offers/add', { state: { editData } });
    } else {
      // Call the add offer API instead of update
      navigate('/offers/add', { state: { editData, isNewOffer: true } });
    }
  };

  return (
    <div
      className={`container mx-auto px-4 py-6 sm:px-6 lg:px-8 ${
        isDarkMode ? "bg-gray-900" : ""
      }`}
    >
      {/* Header with Title and Add Button */}
      <div className="mb-6 flex flex-col sm:flex-row justify-between items-center">
        <h1
          className={`text-2xl font-semibold ${
            isDarkMode ? "text-white" : "text-gray-900"
          }`}
        >
          Offers
        </h1>
        <button
          onClick={() => navigate("/offers/add", { state: { isNewOffer: true } })}
          className={`p-2 rounded-lg transition-colors ${
            isDarkMode
              ? "bg-slate-700 text-slate-300 hover:text-white"
              : "bg-gray-100 text-gray-600 hover:text-gray-900"
          }`}
          aria-label="Add Offer"
        >
          <PlusCircleIcon className="h-7 w-7" />
        </button>
      </div>

      {/* Main Content Container */}
      <div
        className={`rounded-lg border w-100vw overflow-x-auto ${
          isDarkMode
            ? "bg-slate-800 border-slate-700"
            : "bg-white border-gray-200"
        }`}
      >
        {/* Tabs */}
        <div className="px-4 sm:px-6 border-b flex gap-4 sm:gap-8 overflow-x-auto">
          <button
            onClick={() => setActiveTab("active")}
            className={`py-4 relative ${
              activeTab === "active"
                ? isDarkMode
                  ? "text-white"
                  : "text-gray-900"
                : isDarkMode
                ? "text-slate-400"
                : "text-gray-500"
            }`}
          >
            Active Offers
            {activeTab === "active" && (
              <div className="absolute bottom-0 left-0 right-0 h-0.5 bg-emerald-500" />
            )}
          </button>
          <button
            onClick={() => setActiveTab("past")}
            className={`py-4 relative ${
              activeTab === "past"
                ? isDarkMode
                  ? "text-white"
                  : "text-gray-900"
                : isDarkMode
                ? "text-slate-400"
                : "text-gray-500"
            }`}
          >
            Past Offers
            {activeTab === "past" && (
              <div className="absolute bottom-0 left-0 right-0 h-0.5 bg-emerald-500" />
            )}
          </button>
        </div>

        {/* Table */}
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className={isDarkMode ? "bg-slate-800" : "bg-gray-50"}>
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Offer Title
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Description
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Valid Till
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Status
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Action
                </th>
              </tr>
            </thead>
            <tbody
              className={`divide-y ${
                isDarkMode ? "divide-slate-700" : "divide-gray-200"
              }`}
            >
              {loading ? (
                <tr>
                  <td colSpan="5" className="px-6 py-4 text-center">
                    <div className="flex justify-center">
                      <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-emerald-500"></div>
                    </div>
                  </td>
                </tr>
              ) : offers.length === 0 ? (
                <tr>
                  <td
                    colSpan="5"
                    className={`px-6 py-4 text-center ${
                      isDarkMode ? "text-slate-400" : "text-gray-500"
                    }`}
                  >
                    No offers found
                  </td>
                </tr>
              ) : (
                offers.map((offer) => (
                  <tr
                    key={offer.id}
                    className={isDarkMode ? "bg-slate-800" : "bg-white"}
                  >
                    <td
                      className={`px-6 py-4 text-sm ${
                        isDarkMode ? "text-slate-300" : "text-gray-900"
                      }`}
                    >
                      <button
                        onClick={() => setSelectedOffer(offer)}
                        className="underline"
                      >
                        {offer.title}
                      </button>
                    </td>
                    <td
                      className={`px-6 py-4 text-sm ${
                        isDarkMode ? "text-slate-300" : "text-gray-900"
                      }`}
                    >
                      {offer.description}
                    </td>
                    <td
                      className={`px-6 py-4 text-sm ${
                        isDarkMode ? "text-slate-300" : "text-gray-900"
                      }`}
                    >
                      {offer.expiryDate ? new Date(offer.expiryDate).toLocaleDateString() : '-'}
                    </td>
                    <td
                      className={`px-6 py-4 text-sm ${
                        isDarkMode ? "text-slate-300" : "text-gray-900"
                      }`}
                    >
                      <span
                        className={`px-2 py-1 text-xs font-medium rounded-full ${
                          offer.status === "Published" && new Date(offer.expiryDate).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)
                            ? "bg-red-100 text-red-800"
                            : offer.status === "Published"
                            ? "bg-green-100 text-green-800"
                            : "bg-blue-100 text-blue-800"
                        }`}
                      >
                        {offer.status === "Published" && new Date(offer.expiryDate).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)
                          ? "Expired"
                          : offer.status}
                      </span>
                    </td>
                    <td className="px-6 py-4 flex gap-2">
                      {offer.status === "Draft" ? (
                        <>
                          <button
                            onClick={() => handleDelete(offer.id)}
                            className={`p-1 rounded-lg ${
                              isDarkMode
                                ? "hover:bg-slate-700 text-slate-400 hover:text-slate-300"
                                : "hover:bg-gray-100 text-gray-500 hover:text-gray-700"
                            }`}
                            aria-label="Delete offer"
                          >
                            <TrashIcon className="h-5 w-5" />
                          </button>
                          <button
                            onClick={() => handlePublish(offer.id)}
                            className={`px-3 py-1 text-sm rounded-lg border ${
                              isDarkMode
                                ? "border-slate-600 text-slate-300 hover:bg-slate-700"
                                : "border-gray-300 text-gray-700 hover:bg-gray-50"
                            }`}
                            aria-label="Publish offer"
                          >
                            Publish
                          </button>
                          <button
                            onClick={() => handleEdit(offer)}
                            className={`p-1 rounded-lg ${
                              isDarkMode
                                ? "hover:bg-slate-700 text-slate-400 hover:text-slate-300"
                                : "hover:bg-gray-100 text-gray-500 hover:text-gray-700"
                            }`}
                            aria-label="Edit offer"
                          >
                            <PencilSquareIcon className="h-5 w-5" />
                          </button>
                        </>
                      ) : offer.status === "Published" ? (
                        <>
                          <button
                            onClick={() => handleDelete(offer.id)}
                            className={`p-1 rounded-lg ${
                              isDarkMode
                                ? "hover:bg-slate-700 text-slate-400 hover:text-slate-300"
                                : "hover:bg-gray-100 text-gray-500 hover:text-gray-700"
                            }`}
                            aria-label="Delete offer"
                          >
                            <TrashIcon className="h-5 w-5" />
                          </button>
                          {/* <button
                            onClick={() => handleEdit(offer)}
                            className={`p-1 rounded-lg ${
                              isDarkMode
                                ? "hover:bg-slate-700 text-slate-400 hover:text-slate-300"
                                : "hover:bg-gray-100 text-gray-500 hover:text-gray-700"
                            }`}
                            aria-label="Edit offer"
                          >
                            <PencilSquareIcon className="h-5 w-5" />
                          </button> */}
                        </>
                      ) : null}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>

        {/* Pagination */}
        <div
          className={`px-4 sm:px-6 py-3 flex flex-col sm:flex-row items-center justify-between sm:justify-center border-t ${
            isDarkMode ? "border-slate-700" : "border-gray-200"
          }`}
        >
          <div className="flex items-center gap-2 sm:gap-4">
            <div
              className={`text-sm ${
                isDarkMode ? "text-slate-400" : "text-gray-500"
              }`}
            >
              {totalCount === 0 
                ? "No offers" 
                : `${(activePage - 1) * itemsPerPage + 1}-${Math.min(activePage * itemsPerPage, totalCount)} of ${totalCount}`
              }
            </div>
            <div className="flex gap-2">
              <button
                onClick={() => setActivePage((prev) => Math.max(prev - 1, 1))}
                disabled={activePage === 1}
                className={`p-1 rounded-lg ${
                  isDarkMode
                    ? "text-slate-400 hover:text-slate-300 hover:bg-slate-700"
                    : "text-gray-500 hover:text-gray-700 hover:bg-gray-100"
                }`}
              >
                <ChevronLeftIcon className="h-5 w-5" />
              </button>
              <button
                onClick={() =>
                  setActivePage((prev) => Math.min(prev + 1, totalPages))
                }
                disabled={activePage === totalPages}
                className={`p-1 rounded-lg ${
                  isDarkMode
                    ? "text-slate-400 hover:text-slate-300 hover:bg-slate-700"
                    : "text-gray-500 hover:text-gray-700 hover:bg-gray-100"
                }`}
              >
                <ChevronRightIcon className="h-5 w-5" />
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Offer Details Modal */}
      {selectedOffer && (
        <OfferDetailsModal
          offer={selectedOffer}
          onClose={() => setSelectedOffer(null)}
          isDarkMode={isDarkMode}
        />
      )}
    </div>
  );
};

export default Offers;
