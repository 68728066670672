import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '../contexts/ThemeContext';
import { useAuth } from '../contexts/AuthContext';
import storeService from '../services/storeService';
import toast from 'react-hot-toast';

const StoreSelect = ({ onClose }) => {
  const { theme } = useTheme();
  const { logout, setStoreData } = useAuth();
  const navigate = useNavigate();
  const [stores, setStores] = useState([]);
  const [selectedStore, setSelectedStore] = useState('');
  const [selectedCounter, setSelectedCounter] = useState('');
  const [counters, setCounters] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const isDarkMode = theme === 'dark';

  useEffect(() => {
    fetchStores();
  }, []);

  useEffect(() => {
    if (selectedStore) {
      const store = stores.find(s => s.id === selectedStore);
      if (store) {
        setCounters(store.counters || []);
      }
    } else {
      setCounters([]);
    }
    setSelectedCounter('');
  }, [selectedStore, stores]);

  const fetchStores = async () => {
    try {
      console.log('Starting fetchStores');
      setLoading(true);
      setError('');
      
      const response = await storeService.listStores();
      console.log('Raw API Response:', response);
      
      if (!response?.totalItems?.length) {
        console.log('No stores found in response');
        setStores([]);
        return;
      }

      // Transform the API response data to match the component's expected format
      const transformedStores = response.totalItems.map(store => {
        console.log('Processing store:', store);
        return {
          id: store.id || store._id, // Handle both id formats
          name: store.name,
          counters: Array.isArray(store.counters) ? store.counters : [store.counters].filter(Boolean),
          phoneNumber: store.phoneNumber,
          website: store.website,
          isOperational: store.isOperational,
          address: {
            line1: store.address?.line1 || '',
            line2: store.address?.line2 || '',
            city: store.address?.city || '',
            state: store.address?.state || '',
            country: store.address?.country || '',
            pinCode: store.address?.pinCode || ''
          }
        };
      });
      
      console.log('Transformed stores:', transformedStores);
      setStores(transformedStores);
      
      // If there's only one store, pre-select it
      if (transformedStores.length === 1) {
        console.log('Auto-selecting single store:', transformedStores[0]);
        setSelectedStore(transformedStores[0].id);
      }
    } catch (err) {
      console.error('Error in fetchStores:', err);
      setError('Failed to load stores. Please try again.');
      setStores([]);
    } finally {
      setLoading(false);
    }
  };

  // Log when stores state changes
  useEffect(() => {
    console.log('Stores state updated:', stores);
  }, [stores]);

  // Log when selected store changes
  useEffect(() => {
    console.log('Selected store changed:', selectedStore);
    if (selectedStore) {
      const store = stores.find(s => s.id === selectedStore);
      console.log('Found store:', store);
      if (store) {
        console.log('Setting counters:', store.counters);
        setCounters(store.counters || []);
      }
    } else {
      setCounters([]);
    }
    setSelectedCounter('');
  }, [selectedStore, stores]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedStore || !selectedCounter) return;

    const store = stores.find(s => s.id === selectedStore);
    if (store) {
      // Set store data in AuthContext
      setStoreData({
        storeId: store.id,
        storeName: store.name,
        counter: selectedCounter,
        // Add any other needed store data
        isOperational: store.isOperational,
        address: store.address
      });
      navigate('/dashboard', { replace: true });
    }
  };

  const handleCancel = async () => {
    // Clear any stored data
    localStorage.removeItem('store');
    // Call the logout function
    await logout();
    // Close the modal
    onClose?.();
    // Reload base URL
    window.location.href = '/';
  };

  if (loading) {
    return (
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/50">
        <div className="w-8 h-8 border-4 border-blue-500 border-t-transparent rounded-full animate-spin" />
      </div>
    );
  }

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex min-h-screen items-center justify-center p-4">
        {/* Backdrop */}
        <div className="fixed inset-0 bg-black/50" />

        {/* Modal */}
        <div className={`relative w-full max-w-md rounded-lg p-6 shadow-xl ${
          isDarkMode ? 'bg-slate-800' : 'bg-white'
        }`}>
          <h2 className={`mb-6 text-xl font-semibold ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            Select Store & Counter
          </h2>

          <form onSubmit={handleSubmit} className="space-y-4">
            {/* Store Selection */}
            <select
              value={selectedStore}
              onChange={(e) => setSelectedStore(e.target.value)}
              required
              className={`w-full rounded-lg px-3 py-2 ${
                isDarkMode 
                  ? 'bg-slate-700 border-slate-600 text-white' 
                  : 'bg-white border-gray-300 text-gray-900'
              }`}
            >
              <option value="">Select a store</option>
              {stores.map(store => (
                <option key={store.id} value={store.id}>
                  {store.name}
                </option>
              ))}
            </select>

            {/* Counter Selection */}
            {selectedStore && (
              <select
                value={selectedCounter}
                onChange={(e) => setSelectedCounter(e.target.value)}
                required
                className={`w-full rounded-lg px-3 py-2 ${
                  isDarkMode 
                    ? 'bg-slate-700 border-slate-600 text-white' 
                    : 'bg-white border-gray-300 text-gray-900'
                }`}
              >
                <option value="">Select a counter</option>
                {counters.map(counter => (
                  <option key={counter} value={counter}>
                    {counter}
                  </option>
                ))}
              </select>
            )}

            {/* Button Group */}
            <div className="flex gap-4 pt-2">
              <button
                type="button"
                onClick={handleCancel}
                className={`flex-1 py-2 px-4 rounded-lg font-medium border ${
                  isDarkMode
                    ? 'border-slate-600 text-slate-300 hover:bg-slate-700'
                    : 'border-gray-300 text-gray-700 hover:bg-gray-50'
                }`}
              >
                Cancel
              </button>
              
              <button
                type="submit"
                disabled={!selectedStore || !selectedCounter}
                className={`flex-1 py-2 px-4 rounded-lg font-medium text-white ${
                  selectedStore && selectedCounter
                    ? 'bg-blue-500 hover:bg-blue-600'
                    : 'bg-blue-500/50 cursor-not-allowed'
                }`}
              >
                Continue
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

StoreSelect.displayName = 'StoreSelect';

export default StoreSelect; 