import React from "react";

const SuccessErrorModal = ({ message, onClose, theme }) => {
  return (
    <div className={`fixed inset-0 flex items-center justify-center z-50 ${theme === 'dark' ? 'bg-gray-800' : 'bg-white'}`}>
      <div className={`rounded-lg shadow-lg p-6 ${theme === 'dark' ? 'bg-gray-900 text-white' : 'bg-white text-black'}`}>
        <h2 className="text-lg font-semibold">Transaction Status</h2>
        <p className="mt-4">{message}</p>
        <div className="mt-6 flex justify-end">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-blue-500 text-white rounded-lg"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default SuccessErrorModal; 