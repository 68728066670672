import { useState, useEffect } from "react";
import { useTheme } from "../contexts/ThemeContext";
import {
  PlusCircleIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import storeService from '../services/storeService';
import { toast } from 'react-hot-toast';
import { Dialog } from "@headlessui/react";

const Stores = () => {
  const { theme } = useTheme();
  const isDarkMode = theme === "dark";
  const navigate = useNavigate();
  const [activePage, setActivePage] = useState(1);
  const itemsPerPage = 10;
  const [storesData, setStoresData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [totalPages, setTotalPages] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedStoreDetails, setSelectedStoreDetails] = useState(null);

  // Fetch stores data
  const fetchStores = async () => {
    try {
      setLoading(true);
      const response = await storeService.listStores(activePage, itemsPerPage);
      
      if (!response || !response.totalItems) {
        setStoresData([]);
        return;
      }

      // Transform the API response data to match the component's expected format
      const transformedData = response.totalItems.map(store => ({
        id: store.id,
        name: store.name,
        phone: store.phoneNumber || '',
        website: store.website || '',
        operational: store.isOperational ? 'Active' : 'Inactive',
        category: store.category || '',
        address: {
          line1: store.address?.line1 || '',
          line2: store.address?.line2 || '',
          city: store.address?.city || '',
          state: store.address?.state || '',
          country: store.address?.country || 'AU',
          pinCode: store.address?.pinCode || ''
        },
        location: {
          lat: store.location?.coordinates?.[0] || '',
          lng: store.location?.coordinates?.[1] || ''
        },
        counters: store.counters || []
      }));
      
      setStoresData(transformedData);
      setTotalPages(Math.ceil(response.totalCount / itemsPerPage));
    } catch (err) {
      console.error('Error fetching stores:', err);
      setError('Failed to load stores data');
      setStoresData([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchStores();
  }, [activePage, itemsPerPage]);

  // Calculate pagination
  const startIndex = (activePage - 1) * itemsPerPage;
  const currentStores = storesData.slice(startIndex, startIndex + itemsPerPage);

  const handleAddStore = () => {
    // Handle adding a new store
    console.log("Add Store clicked");
  };

  const handleEditStore = (store) => {
    // Navigate to AddStore page with store data
    navigate('/stores/add', { 
      state: { 
        editMode: true,
        storeData: {
          id: store.id,
          name: store.name,
          operational: store.operational === 'Active',
          phone: store.phone,
          website: store.website,
          address: {
            line1: store.address.line1,
            line2: store.address.line2,
            city: store.address.city,
            state: store.address.state,
            pincode: store.address.pinCode
          },
          location: {
            lat: store.location.lat,
            lng: store.location.lng
          },
          counters: store.counters.length
        }
      }
    });
  };

  const handleStoreClick = (store) => {
    setSelectedStoreDetails(store);
    setIsModalOpen(true);
  };

  return (
    <div
      className={`container mx-auto px-4 py-6 sm:px-6 lg:px-8 ${
        isDarkMode ? "bg-gray-900" : ""
      }`}
    >
      <div className="flex flex-col sm:flex-row justify-between items-center mb-6">
        <h1
          className={`text-2xl font-semibold ${
            isDarkMode ? "text-white" : "text-gray-900"
          }`}
        >
          Stores
        </h1>
        <button
          onClick={() => navigate("/stores/add")}
          className={`p-2 rounded-lg ${
            isDarkMode
              ? "bg-slate-700 text-slate-300 hover:text-white"
              : "bg-gray-100 text-gray-600 hover:text-gray-900"
          }`}
          aria-label="Add Store"
        >
          <PlusCircleIcon className="h-7 w-7" />
        </button>
      </div>

      <div
        className={`mt-4 overflow-x-auto rounded-lg border ${
          isDarkMode ? "border-slate-700" : "border-gray-200"
        }`}
      >
        {loading ? (
          <div className="flex justify-center items-center p-8">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-emerald-500"></div>
          </div>
        ) : (
          <>
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className={isDarkMode ? "bg-slate-800" : "bg-gray-50"}>
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Name
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Phone Number
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Website
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Operational
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Address
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Counters
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody
                  className={`divide-y ${
                    isDarkMode ? "divide-slate-700" : "divide-gray-200"
                  }`}
                >
                  {currentStores.map((store) => (
                    <tr
                      key={store.id}
                      className={isDarkMode ? "bg-slate-800" : "bg-white"}
                    >
                      <td
                        className={`px-6 py-4 text-sm ${
                          isDarkMode ? "text-slate-300" : "text-gray-900"
                        }`}
                      >
                        <button
                          onClick={() => handleStoreClick(store)}
                          className="text-blue-500 hover:underline"
                          aria-label={`View details for ${store.name}`}
                        >
                          {store.name}
                        </button>
                      </td>
                      <td
                        className={`px-6 py-4 text-sm ${
                          isDarkMode ? "text-slate-300" : "text-gray-900"
                        }`}
                      >
                        {store.phone}
                      </td>
                      <td
                        className={`px-6 py-4 text-sm ${
                          isDarkMode ? "text-slate-300" : "text-gray-900"
                        }`}
                      >
                        {store.website}
                      </td>
                      <td
                        className={`px-6 py-4 text-sm ${
                          isDarkMode ? "text-slate-300" : "text-gray-900"
                        }`}
                      >
                        {store.operational}
                      </td>
                      <td
                        className={`px-6 py-4 text-sm ${
                          isDarkMode ? "text-slate-300" : "text-gray-900"
                        }`}
                      >
                        {`${store.address.line1}${store.address.line2 ? `, ${store.address.line2}` : ''}, ${store.address.city}, ${store.address.state} - ${store.address.pinCode}`}
                      </td>
                      <td
                        className={`px-6 py-4 text-sm ${
                          isDarkMode ? "text-slate-300" : "text-gray-900"
                        }`}
                      >
                        {Array.isArray(store.counters) ? store.counters.length : 0}
                      </td>
                      <td className="px-6 py-4">
                        <button
                          onClick={() => handleEditStore(store)}
                          className={`p-2 rounded-lg ${
                            isDarkMode
                              ? "text-slate-400 hover:text-slate-300 hover:bg-slate-700"
                              : "text-gray-500 hover:text-gray-700 hover:bg-gray-100"
                          }`}
                          aria-label={`Edit Store ${store.name}`}
                        >
                          <PencilSquareIcon className="h-5 w-5" />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {/* Centered Pagination */}
            <div
              className={`px-4 sm:px-6 py-3 flex flex-col sm:flex-row items-center justify-between sm:justify-center border-t ${
                isDarkMode ? "bg-slate-800" : "bg-white"
              }`}
            >
              <div className="flex items-center gap-2 sm:gap-4">
                <div
                  className={`text-sm ${
                    isDarkMode ? "text-slate-400" : "text-gray-500"
                  }`}
                >
                  {activePage === 1
                    ? `1-${currentStores.length} of ${storesData.length}`
                    : `${startIndex + 1}-${startIndex + currentStores.length} of ${
                        storesData.length
                      }`}
                </div>
                <div className="flex gap-2">
                  <button
                    onClick={() => setActivePage((prev) => Math.max(prev - 1, 1))}
                    disabled={activePage === 1}
                    className={`p-1 rounded-lg ${
                      isDarkMode
                        ? "text-slate-400 hover:text-slate-300 hover:bg-slate-700"
                        : "text-gray-500 hover:text-gray-700 hover:bg-gray-100"
                    }`}
                  >
                    <ChevronLeftIcon className="h-5 w-5" />
                  </button>
                  <button
                    onClick={() =>
                      setActivePage((prev) => Math.min(prev + 1, totalPages))
                    }
                    disabled={activePage === totalPages}
                    className={`p-1 rounded-lg ${
                      isDarkMode
                        ? "text-slate-400 hover:text-slate-300 hover:bg-slate-700"
                        : "text-gray-500 hover:text-gray-700 hover:bg-gray-100"
                    }`}
                  >
                    <ChevronRightIcon className="h-5 w-5" />
                  </button>
                </div>
              </div>
              <div className="mt-4 sm:mt-0">
              </div>
            </div>

            {/* Store Details Modal */}
            <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)} className="fixed z-50 inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4">
                <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
                <div className={`relative w-full max-w-md p-6 rounded-lg shadow-xl ${isDarkMode ? "bg-slate-800" : "bg-white"}`}>
                  <Dialog.Title className={`text-lg font-medium mb-4 ${isDarkMode ? "text-white" : "text-gray-900"}`}>
                    Store Details
                  </Dialog.Title>
                  {selectedStoreDetails && (
                    <div className="space-y-4">
                      <p><strong>Name:</strong> {selectedStoreDetails.name}</p>
                      <p><strong>Address:</strong> {`${selectedStoreDetails.address.line1}, ${selectedStoreDetails.address.city}, ${selectedStoreDetails.address.state}, ${selectedStoreDetails.address.pinCode}`}</p>
                      <p><strong>Phone:</strong> {selectedStoreDetails.phoneNumber}</p>
                      <p><strong>Website:</strong> {selectedStoreDetails.website || "N/A"}</p>
                      <p><strong>Operational:</strong> {selectedStoreDetails.isOperational ? "Yes" : "No"}</p>
                      <p><strong>Category:</strong> {selectedStoreDetails.category}</p>
                      <p><strong>Counters:</strong> {selectedStoreDetails.counters.length}</p>
                      <p><strong>Latitude:</strong> {selectedStoreDetails.location.lat}</p>
                      <p><strong>Longitude:</strong> {selectedStoreDetails.location.lng}</p>
                    </div>
                  )}
                  <div className="flex justify-end mt-4">
                    <button
                      onClick={() => setIsModalOpen(false)}
                      className={`px-4 py-2 text-sm font-medium rounded-lg ${isDarkMode ? "text-slate-400 hover:text-slate-300 bg-slate-700 hover:bg-slate-600" : "text-gray-700 hover:text-gray-900 bg-gray-100 hover:bg-gray-200"}`}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </Dialog>
          </>
        )}
      </div>
    </div>
  );
};

export default Stores;
