import { useTheme } from "../contexts/ThemeContext";
import PropTypes from "prop-types";
import { useMediaQuery } from "react-responsive";
import { useEffect, useState } from "react";
import staffService from '../services/staffService';
import OfferDetailsModal from "../components/OfferDetailsModal";
import StaffOfferDetailsModal from "../components/StaffOfferDetailsModal";

const DashboardStaff = ({ onNavigate }) => {
  const { theme } = useTheme();
  const isDarkMode = theme === "dark";
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [gcAndOffers, setGcAndOffers] = useState({ giftCards: [], offers: [] });
  const [selectedOffer, setSelectedOffer] = useState(null);

  // Sample gift cards
  const giftCards = [
    { id: 1, amount: 25, discount: 5, validity: "2023-12-31" },
    { id: 2, amount: 50, discount: 10, validity: "2023-11-30" },
    { id: 3, amount: 100, discount: 15, validity: "2023-10-31" },
    { id: 4, amount: 75, discount: 12, validity: "2023-09-30" },
    { id: 5, amount: 150, discount: 20, validity: "2023-08-31" },
    { id: 6, amount: 200, discount: 25, validity: "2023-07-31" },
    { id: 7, amount: 300, discount: 30, validity: "2023-06-30" },
    { id: 8, amount: 400, discount: 35, validity: "2023-05-31" },
    { id: 9, amount: 500, discount: 40, validity: "2023-04-30" },
    { id: 10, amount: 1000, discount: 50, validity: "2023-03-31" },
  ];

  // Sample offers
  const offers = [
    {
      id: 1,
      title: "Summer Sale",
      image: "/path/to/image1.jpg",
      validity: "2023-08-31",
    },
    {
      id: 2,
      title: "Holiday Discount",
      image: "/path/to/image2.jpg",
      validity: "2023-12-25",
    },
    {
      id: 3,
      title: "New Year Offer",
      image: "/path/to/image3.jpg",
      validity: "2024-01-15",
    },
    {
      id: 4,
      title: "Spring Sale",
      image: "/path/to/image4.jpg",
      validity: "2023-05-31",
    },
    {
      id: 5,
      title: "Autumn Discount",
      image: "/path/to/image5.jpg",
      validity: "2023-11-30",
    },
    {
      id: 6,
      title: "Winter Offer",
      image: "/path/to/image6.jpg",
      validity: "2024-02-28",
    },
    {
      id: 7,
      title: "Back to School Sale",
      image: "/path/to/image7.jpg",
      validity: "2023-09-15",
    },
    {
      id: 8,
      title: "Black Friday Deal",
      image: "/path/to/image8.jpg",
      validity: "2023-11-26",
    },
    {
      id: 9,
      title: "Cyber Monday Offer",
      image: "/path/to/image9.jpg",
      validity: "2023-11-29",
    },
    {
      id: 10,
      title: "Valentine's Day Special",
      image: "/path/to/image10.jpg",
      validity: "2024-02-14",
    },
    // Add more offers...
  ];

  useEffect(() => {
    const fetchGCAndOffers = async () => {
      try {
        const data = await staffService.getGCAndOffersForDashboard();
        // Map the API response to the state
        // if(data.giftCardList.length > 0 && data.offersList.length > 0){
        setGcAndOffers({
          giftCards: data.giftCardList.map(card => ({
            id: card.id,
            amount: card.faceValue,
            discount: card.discount,
            validity: card.validity // Assuming validity is in days
          })),
          offers: data.offersList.map(offer => ({
            id: offer.id,
            title: offer.title,
            images: offer.images,
            description: offer.description, // Assuming the first image is used
            validity: new Date(offer.expiryDate).toLocaleDateString() // Format the date
          }))
          
        });
        // }
      } catch (error) {
        console.error('Error fetching GC and Offers:', error);
      }
    };

    fetchGCAndOffers();
  }, []); // Empty dependency array to run only on mount

  const handleOfferClick = (offer) => {
    console.log("Offer " ,offer);
    
    setSelectedOffer({
      ...offer,
      description: offer.description,
      images: offer.images || []
    });
  };

  const closeModal = () => {
    setSelectedOffer(null);
  };

  if (isMobile) {
    return (
      <div className="container mx-auto px-4 py-6">
        <h1
          className={`text-2xl font-semibold mb-4 ${
            isDarkMode ? "text-white" : "text-gray-800"
          }`}
        >
          Staff Dashboard
        </h1>
        <p className={`${isDarkMode ? "text-slate-400" : "text-gray-500"}`}>
          No data available on mobile devices.
        </p>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-6">
      {/* Gift Cards Section */}
      <div className="mb-8 overflow-x-auto">
        <h2
          className={`text-xl font-semibold mb-4 ${
            isDarkMode ? "text-white" : "text-gray-800"
          }`}
        >
          Current Gift Cards
        </h2>
        <div className="flex overflow-x-auto space-x-4">
          {gcAndOffers.giftCards.length > 0 ? (
            gcAndOffers.giftCards.map((card) => (
              <div
                key={card.id}
                className={`flex-shrink-0 w-64 rounded-lg shadow-md p-4 ${
                  isDarkMode ? "bg-slate-800" : "bg-white"
                }`}
              >
                <div className="flex items-center justify-between mb-4">
                  <span
                    className={`text-lg font-semibold ${
                      isDarkMode ? "text-white" : "text-gray-800"
                    }`}
                  >
                    ${card.amount}
                  </span>
                  <span
                    className={`text-sm ${
                      isDarkMode ? "text-slate-400" : "text-gray-500"
                    }`}
                  >
                    {card.discount}% Off
                  </span>
                </div>
                <div
                  className={`text-sm ${
                    isDarkMode ? "text-slate-400" : "text-gray-500"
                  }`}
                >
                  Valid until: {card.validity} days
                </div>
              </div>
            ))
          ) : (
            <div className="text-center text-gray-500">
              No gift cards available
            </div>
          )}
        </div>
      </div>

      {/* Offers Section */}
      <div>
        <h2
          className={`text-xl font-semibold mb-4 ${
            isDarkMode ? "text-white" : "text-gray-800"
          }`}
        >
          Current Offers
        </h2>
        <div className="flex flex-col overflow-y-auto max-h-96">
          {gcAndOffers.offers.length > 0 ? (
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
              {gcAndOffers.offers.map((offer) => (
                <div
                  key={offer.id}
                  className={`rounded-lg shadow-md transition duration-300 ease-in-out hover:bg-opacity-80 ${
                    isDarkMode ? "bg-slate-800" : "bg-white"
                  } cursor-pointer`}
                  onClick={() => handleOfferClick(offer)}
                >
                  <img
                    src={offer.images[0]}
                    alt={offer.title}
                    className="w-full h-40 object-cover rounded-t-lg"
                  />
                  <div className="p-4">
                    <h3
                      className={`text-lg font-semibold mb-2 ${
                        isDarkMode ? "text-white" : "text-gray-800"
                      }`}
                    >
                      {offer.title}
                    </h3>
                    <div
                      className={`text-sm ${
                        isDarkMode ? "text-slate-400" : "text-gray-500"
                      }`}
                    >
                      Valid until: {offer.validity}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="text-center text-gray-500">
              No offers available
            </div>
          )}
        </div>
      </div>

      {/* Render the modal if an offer is selected */}
      {selectedOffer && (
        <StaffOfferDetailsModal
          offer={selectedOffer}
          onClose={closeModal}
          isDarkMode={isDarkMode}
        />
      )}
    </div>
  );
};

DashboardStaff.propTypes = {
  onNavigate: PropTypes.func.isRequired,
};

export default DashboardStaff;
