import React from 'react';
import { XMarkIcon } from "@heroicons/react/24/outline";
import ImageCarousel from './ImageCarousel';

const StaffOfferDetailsModal = ({ offer, onClose, isDarkMode }) => {
  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex min-h-full items-center justify-center p-4">
        <div
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          onClick={onClose}
        />
        <div className={`relative max-w-4xl w-full ${isDarkMode ? "bg-slate-800" : "bg-white"} rounded-lg shadow-xl`}>
          <div className={`px-6 py-4 border-b flex items-center justify-between ${isDarkMode ? "border-slate-700" : "border-gray-200"}`}>
            <h3 className={`text-lg font-semibold ${isDarkMode ? "text-white" : "text-gray-900"}`}>
              {offer.title}
            </h3>
            <button onClick={onClose} className={`p-1 rounded-lg ${isDarkMode ? "text-slate-400 hover:text-slate-300 hover:bg-slate-700" : "text-gray-400 hover:text-gray-600 hover:bg-gray-100"}`}>
              <XMarkIcon className="h-6 w-6" />
            </button>
          </div>
          <div className={`px-6 py-4 ${isDarkMode ? "bg-slate-700" : "bg-gray-100"} rounded-lg`}>
            <p className={`text-sm ${isDarkMode ? "text-slate-300" : "text-gray-900"} mb-2`}>
              {offer.description}
            </p>
            {offer.images && offer.images.length > 0 && (
              <ImageCarousel images={offer.images} isDarkMode={isDarkMode} />
            )}
            <p className={`text-sm font-medium ${isDarkMode ? "text-slate-300" : "text-gray-900"} mb-2`}>
              Valid till: {offer.validity ? new Date(offer.validity).toLocaleDateString() : '-'}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StaffOfferDetailsModal; 