import { createContext, useContext, useState, useEffect } from 'react';
import { authService } from '../services/authService';
import toast from 'react-hot-toast';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [store, setStore] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(false);

  // Check for existing auth on mount
  useEffect(() => {
    const checkAuth = () => {
      const token = localStorage.getItem('token');
      const storedUser = localStorage.getItem('user');
      const storedStore = localStorage.getItem('store');

      if (token && storedUser) {
        try {
          const userData = JSON.parse(storedUser);
          if (!userData.userType) {
            throw new Error('Invalid user data');
          }
          setUser(userData);
          setIsAuthenticated(true);
        } catch (error) {
          console.error('Failed to parse stored user data:', error);
          // Clear invalid data
          localStorage.removeItem('token');
          localStorage.removeItem('user');
        }
      }

      if (storedStore) {
        try {
          const storeData = JSON.parse(storedStore);
          setStore(storeData);
        } catch (error) {
          console.error('Failed to parse stored store data:', error);
          // Clear invalid data
          localStorage.removeItem('store');
        }
      }

      setIsLoading(false);
    };

    checkAuth();
  }, []);

  const handleLogin = async (credentials) => {
    try {
      setLoading(true);
      
      const response = await authService.verifyOTP(credentials);
      
      if (response.err) {
        toast.error(response.err);
        return false;
      }

      const { user: userData, token } = response;
      
      // Store user data and token
      localStorage.setItem('token', token);
      localStorage.setItem('user', JSON.stringify(userData));
      
      setUser(userData);
      setIsAuthenticated(true);
      toast.success(response.message || 'Successfully logged in!');
      return true;
    } catch (error) {
      console.log(error);
      toast.error('An error occurred while logging in');
      return false;
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = async () => {
    let loadingToast;
    try {
      setLoading(true);
      
      const response = await authService.logout();
      
      if (response.err) {
        toast.error(response.err, { id: loadingToast });
        return;
      }

      setUser(null);
      setStore(null);
      setIsAuthenticated(false);
      // Clear all stored data
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      localStorage.removeItem('store');
      toast.success(response.message || 'Successfully logged out!', { id: loadingToast });
    } catch (error) {
      if (loadingToast) {
        toast.error('An error occurred while logging out', { id: loadingToast });
      } else {
        toast.error('An error occurred while logging out');
      }
    } finally {
      setLoading(false);
    }
  };

  const setStoreData = (storeData) => {
    setStore(storeData);
    localStorage.setItem('store', JSON.stringify(storeData));
  };

  // Show loading state
  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary-600"></div>
      </div>
    );
  }

  return (
    <AuthContext.Provider value={{
      user,
      store,
      isAuthenticated,
      loading,
      login: handleLogin,
      logout: handleLogout,
      setStoreData
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}; 