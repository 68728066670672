import { useState, useEffect } from "react";
import { useTheme } from "../contexts/ThemeContext";
import deviceService from '../services/deviceService';
import { toast } from 'react-hot-toast';
import { 
  TrashIcon, 
  ChevronLeftIcon, 
  ChevronRightIcon 
} from '@heroicons/react/24/outline';

const Devices = () => {
  const { theme } = useTheme();
  const isDarkMode = theme === "dark";
  const [devicesData, setDevicesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activePage, setActivePage] = useState(1);
  const itemsPerPage = 10;
  const [totalPages, setTotalPages] = useState(1);

  // Fetch devices data
  const fetchDevices = async () => {
    try {
      setLoading(true);
      const response = await deviceService.listDevices(activePage - 1, itemsPerPage);
      
      if (!response || response.length === 0) {
        setDevicesData([]);
        return;
      }

      // Set devices data directly from the response
      setDevicesData(response);
      setTotalPages(Math.ceil(response.length / itemsPerPage));
    } catch (err) {
      console.error('Error fetching devices:', err);
      setError('Failed to load devices data');
      setDevicesData([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDevices();
  }, [activePage]);

  const handleDeleteDevice = async (uuid) => {
    try {
      await deviceService.deleteDevice(uuid);
      // Refresh the device list after deletion
      const response = await deviceService.listDevices(activePage - 1, itemsPerPage);
      setDevicesData(response);
    } catch (error) {
      console.error('Error deleting device:', error);
    }
  };

  return (
    <div className={`container mx-auto px-4 py-6 ${isDarkMode ? "bg-gray-900" : ""}`}>
      <h1 className={`text-2xl font-semibold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
        Devices
      </h1>

      <div className={`mt-4 overflow-hidden rounded-lg border ${isDarkMode ? 'border-slate-700' : 'border-gray-200'}`}>
        <div className="overflow-x-auto">
          {/* Loading state for the table */}
          {loading ? (
            <div className="flex justify-center items-center p-8">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-emerald-500"></div>
            </div>
          ) : (
            <table className="min-w-full divide-y divide-gray-200">
              <thead className={isDarkMode ? 'bg-slate-800' : 'bg-gray-50'}>
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Device Name</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Platform</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                </tr>
              </thead>
              <tbody className={`divide-y ${isDarkMode ? 'divide-slate-700' : 'divide-gray-200'}`}>
                {devicesData.map((device) => (
                  <tr key={device.uuid} className={isDarkMode ? 'bg-slate-800' : 'bg-white'}>
                    <td className={`px-6 py-4 text-sm ${isDarkMode ? 'text-slate-300' : 'text-gray-900'}`}>{device.displayName || device.name}</td>
                    <td className={`px-6 py-4 text-sm ${isDarkMode ? 'text-slate-300' : 'text-gray-900'}`}>{device.platform}</td>
                    <td className={`px-6 py-4 text-sm ${isDarkMode ? 'text-slate-300' : 'text-gray-900'}`}>{device.isOwnerDevice ? 'Owner Device' : 'Regular Device'}</td>
                    <td className="px-6 py-4 flex gap-2">
                      <button 
                        onClick={() => handleDeleteDevice(device.uuid)}
                        className={`p-1 rounded-lg ${isDarkMode ? 'hover:bg-slate-700 text-slate-400 hover:text-slate-300' : 'hover:bg-gray-100 text-gray-500 hover:text-gray-700'}`}
                      >
                        <TrashIcon className="h-5 w-5" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
        {/* Centered Pagination */}
        <div className={`px-6 py-3 flex items-center justify-center border-t ${isDarkMode ? 'bg-slate-800' : 'bg-white'}`}>
          <div className="flex items-center gap-4">
            <div className={`text-sm ${isDarkMode ? 'text-slate-400' : 'text-gray-500'}`}>
              {activePage === 1 ? `1-${devicesData.length} of ${devicesData.length}` : `${(activePage - 1) * itemsPerPage + 1}-${activePage * itemsPerPage} of ${devicesData.length}`}
            </div>
            <div className="flex gap-2">
              <button 
                onClick={() => setActivePage((prev) => Math.max(prev - 1, 1))}
                disabled={activePage === 1}
                className={`p-1 rounded-lg ${isDarkMode ? 'text-slate-400 hover:text-slate-300 hover:bg-slate-700' : 'text-gray-500 hover:text-gray-700 hover:bg-gray-100'}`}
              >
                <ChevronLeftIcon className="h-5 w-5" />
              </button>
              <button 
                onClick={() => setActivePage((prev) => Math.min(prev + 1, totalPages))}
                disabled={activePage === totalPages}
                className={`p-1 rounded-lg ${isDarkMode ? 'text-slate-400 hover:text-slate-300 hover:bg-slate-700' : 'text-gray-500 hover:text-gray-700 hover:bg-gray-100'}`}
              >
                <ChevronRightIcon className="h-5 w-5" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Devices; 