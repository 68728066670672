import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Modal from "./Modal";
import galleryService from '../services/galleryService';

const GallerySelector = ({ isOpen, onClose, onSelect, isDarkMode }) => {
  const [images, setImages] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchImages = async () => {
    try {
      setLoading(true);
      const response = await galleryService.listImages();
      const galleryData = response?.data?.gallery || {};
      const imageList = galleryData.images || [];
      const basePath = galleryData.basePath || '';

      // Transform the data to include full URLs
      const transformedImages = imageList.map(image => ({
        id: image._id,
        title: image.name,
        url: `${basePath}${image.name}`
      }));
      
      setImages(transformedImages);
    } catch (error) {
      console.error('Error fetching images:', error);
      setImages([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      fetchImages();
    }
  }, [isOpen]);

  const handleImageToggle = (image) => {
    setSelectedImages((prev) =>
      prev.some((img) => img.id === image.id)
        ? prev.filter((img) => img.id !== image.id)
        : [...prev, image]
    );
  };

  const handleConfirm = () => {
    onSelect(selectedImages);
    setSelectedImages([]);
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Select Images"
      isDarkMode={isDarkMode}
    >
      <div className="space-y-4">
        {loading ? (
          <div className="flex justify-center items-center h-64">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
          </div>
        ) : (
          <div className="grid grid-cols-2 gap-4 max-h-96 overflow-y-auto">
            {images.map((image) => {
              const isSelected = selectedImages.some((img) => img.id === image.id);
              return (
                <button
                  key={image.id}
                  onClick={() => handleImageToggle(image)}
                  className={`relative rounded-lg overflow-hidden ${
                    isSelected ? "ring-2 ring-blue-500" : ""
                  }`}
                >
                  <img
                    src={image.url}
                    alt={image.title}
                    className="w-full h-32 object-cover"
                  />
                  <div className={`absolute inset-0 ${isSelected ? "bg-blue-500/20" : "hover:bg-black/10"}`} />
                </button>
              );
            })}
          </div>
        )}

        <div className="flex justify-end gap-2">
          <button
            type="button"
            onClick={onClose}
            className={`px-4 py-2 rounded-lg ${
              isDarkMode
                ? "bg-slate-700 text-slate-300 hover:bg-slate-600"
                : "bg-gray-100 text-gray-700 hover:bg-gray-200"
            }`}
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={handleConfirm}
            className={`px-4 py-2 rounded-lg ${
              isDarkMode
                ? "bg-blue-600 text-white hover:bg-blue-700"
                : "bg-blue-500 text-white hover:bg-blue-600"
            }`}
          >
            Select ({selectedImages.length})
          </button>
        </div>
      </div>
    </Modal>
  );
};

GallerySelector.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  isDarkMode: PropTypes.bool.isRequired,
};

export default GallerySelector;
