import { useState, useEffect } from "react";
import { useTheme } from "../../contexts/ThemeContext";
import { useNavigate, useLocation } from "react-router-dom";
import { XMarkIcon } from "@heroicons/react/24/outline";
import staffService from '../../services/staffService';
import { toast } from 'react-hot-toast';

const AddStaff = () => {
  const { theme } = useTheme();
  const isDarkMode = theme === "dark";
  const navigate = useNavigate();
  const location = useLocation();
  const isEditMode = location.state?.editMode;
  const editData = location.state?.staffData; // Get staff data if in edit mode
  // console.log("Edit data " ,editData);
  const initialState = {
    name: "",
    email: "",
    phone: "",
    chatAccess: false,
    status: true,
    
  };

  const [formData, setFormData] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  // Load edit data if in edit mode
  useEffect(() => {
    if (isEditMode && editData) {
      setFormData({
        name: editData.name,
        email: editData.email,
        phone: editData.phone,
        chatAccess: editData.chatAccess,
        status: editData.status,
      });
    }
  }, [isEditMode, editData]);

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name.trim()) {
      newErrors.name = "Name is required";
    }
    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Please enter a valid email address";
    }
    return newErrors;
  };

  const handleSubmit = async () => {
    const newErrors = validateForm();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    try {
      setLoading(true);
      const phoneNumber = formData.phone.startsWith('+61')
        ? formData.phone.replace('+61', '') // Remove +61 from the start
        : formData.phone;

      if (isEditMode) {
        await staffService.updateStaff({
          id: editData.id,
          name: formData.name,
          email: formData.email,
          phone: phoneNumber, // Use the modified phone number
          status: formData.status,
          chatAccess: formData.chatAccess
        });
      } else {
        await staffService.addStaff({
          ...formData,
          phone: phoneNumber // Use the modified phone number
        });
      }

      navigate('/staff'); // Redirect to the Staff page after successful operation
    } catch (error) {
      console.error('Error adding staff:', error);
      toast.error('Failed to add staff member');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mx-auto px-4 py-6">
      {/* Header */}
      <div className="flex justify-between items-center mb-6">
        <h1
          className={`text-2xl font-semibold ${
            isDarkMode ? "text-white" : "text-gray-900"
          }`}
        >
          Add Staff
        </h1>
        <button
          onClick={() => navigate("/staff")}
          className={`p-2 rounded-lg ${
            isDarkMode
              ? "text-slate-400 hover:text-slate-300 hover:bg-slate-700"
              : "text-gray-500 hover:text-gray-700 hover:bg-gray-100"
          }`}
        >
          <XMarkIcon className="h-6 w-6" />
        </button>
      </div>

      {/* Loading state for the form */}
      {loading ? (
        <div className="flex justify-center items-center p-8">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-emerald-500"></div>
        </div>
      ) : (
        <div
          className={`rounded-lg border p-6 ${
            isDarkMode
              ? "bg-slate-800 border-slate-700"
              : "bg-white border-gray-200"
          }`}
        >
          <form className="space-y-4">
            <div>
              <label
                className={`block mb-2 ${
                  isDarkMode ? "text-slate-300" : "text-gray-700"
                }`}
              >
                Name <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                value={formData.name}
                onChange={(e) =>
                  setFormData({ ...formData, name: e.target.value })
                }
                className={`w-full px-3 py-2 rounded-lg border ${
                  isDarkMode
                    ? "bg-slate-700 border-slate-600 text-white"
                    : "bg-white border-gray-300"
                }`}
                placeholder="Enter staff name"
              />
              {errors.name && (
                <p className="text-red-500 text-sm mt-1">{errors.name}</p>
              )}
            </div>

            <div>
              <label
                className={`block mb-2 ${
                  isDarkMode ? "text-slate-300" : "text-gray-700"
                }`}
              >
                Email <span className="text-red-500">*</span>
              </label>
              <input
                type="email"
                value={formData.email}
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
                className={`w-full px-3 py-2 rounded-lg border ${
                  isDarkMode
                    ? "bg-slate-700 border-slate-600 text-white"
                    : "bg-white border-gray-300"
                }`}
                placeholder="Enter email address"
              />
              {errors.email && (
                <p className="text-red-500 text-sm mt-1">{errors.email}</p>
              )}
            </div>

            <div>
              <label
                className={`block mb-2 ${
                  isDarkMode ? "text-slate-300" : "text-gray-700"
                }`}
              >
                Phone Number
              </label>
              <input
                type="tel"
                value={formData.phone}
                onChange={(e) =>
                  setFormData({ ...formData, phone: e.target.value })
                }
                className={`w-full px-3 py-2 rounded-lg border ${
                  isDarkMode
                    ? "bg-slate-700 border-slate-600 text-white"
                    : "bg-white border-gray-300"
                }`}
                placeholder="Enter phone number"
              />
            </div>

            <div className="flex items-center gap-6">
              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={formData.chatAccess}
                  onChange={(e) =>
                    setFormData({ ...formData, chatAccess: e.target.checked })
                  }
                  className="mr-2"
                />
                <span className={isDarkMode ? "text-slate-300" : "text-gray-700"}>
                  Chat Access
                </span>
              </label>

              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={formData.status}
                  onChange={(e) =>
                    setFormData({ ...formData, status: e.target.checked })
                  }
                  className="mr-2"
                />
                <span className={isDarkMode ? "text-slate-300" : "text-gray-700"}>
                  Active
                </span>
              </label>
            </div>

            <div className="flex justify-end gap-2 mt-6">
              <button
                type="button"
                onClick={() => navigate("/staff")}
                className={`px-4 py-2 rounded-lg ${
                  isDarkMode
                    ? "bg-slate-700 text-slate-300 hover:bg-slate-600"
                    : "bg-gray-100 text-gray-700 hover:bg-gray-200"
                }`}
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={handleSubmit}
                className={`px-4 py-2 rounded-lg ${
                  isDarkMode
                    ? "bg-blue-600 text-white hover:bg-blue-700"
                    : "bg-blue-500 text-white hover:bg-blue-600"
                }`}
              >
                Add Staff
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default AddStaff;
