import axios from 'axios';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

// Create axios instance with base configuration
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 15000,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    // Get token from localStorage
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    // Show loading toast unless specifically disabled
    if (!config.skipLoadingToast) {
      toast.loading('Processing...', { id: 'loadingToast' });
    }
    
    return config;
  },
  (error) => {
    toast.dismiss('loadingToast');
    return Promise.reject(error);
  }
);

// Response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    // Dismiss loading toast
    toast.dismiss('loadingToast');

    // Handle success responses (2xx)
    if ((response.data?.message || response.data?.msg) && !response.config?.skipSuccessToast) {
      toast.success(response.data.message || response.data.msg);
    }
    return response.data;
  },
  (error) => {
    // Dismiss loading toast
    toast.dismiss('loadingToast');

    // Only show toast if error doesn't have a custom handler flag
    if (!error.config?.skipErrorHandler) {
      const errorMessage = error.response?.data?.error || 'Something went wrong';

      switch (error.response?.status) {
        case 401:
          // Clear all localStorage items for 401
          Object.keys(localStorage).forEach(key => localStorage.removeItem(key));
          console.log("err " , error);
          toast.error(error.response?.data?.error || 'Session expired. Please login again.');

          // Check if the current path is not the login page
          if (window.location.pathname !== '/login') {
            // window.location.href = '/login';
          }
          // const navigate = useNavigate();
          // navigate('/login');
          break;
        case 502:
          toast.error('Server is currently unavailable. Please try again later.');
          break;
        case 400:
        case 403:
        case 404:
        case 422:
        case 500:
        default:
          toast.error(errorMessage);
          break;
      }
    }
    
    return Promise.reject(error);
  }
);

export default axiosInstance; 