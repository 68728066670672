import axiosInstance from './axiosConfig';

class DeviceService {
  async listDevices(offset = 0, limit = 10) {
    try {
      const response = await axiosInstance.post('/business/device/list', {
        offset,
        limit
      });
      return response;
    } catch (error) {
      console.error('Device Service Error:', error);
      throw error;
    }
  }

  async deleteDevice(uuid) {
    try {
      const response = await axiosInstance.post('/business/device/delete', {
        uuid
      });
      return response;
    } catch (error) {
      console.error('Device Service Delete Error:', error);
      throw error;
    }
  }
}

export default new DeviceService(); 