import { useState, useEffect } from "react";
import { useTheme } from "../../contexts/ThemeContext";
import { useNavigate, useLocation } from "react-router-dom";
import { XMarkIcon } from "@heroicons/react/24/outline";
import GallerySelector from "../../components/GallerySelector";
import offerService from '../../services/offerService';

const AddOffer = () => {
  const { theme } = useTheme();
  const isDarkMode = theme === "dark";
  const navigate = useNavigate();
  const location = useLocation();
  const { editData, isNewOffer } = location.state || {};
  const [showGallery, setShowGallery] = useState(false);

  const initialState = {
    title: "",
    description: "",
    expiryDate: "",
    selectedImages: [],
  };

  const [formData, setFormData] = useState(initialState);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (editData) {
      setFormData({
        title: editData.title || '',
        description: editData.description || '',
        expiryDate: editData.expiryDate || '',
        selectedImages: editData.selectedImages || []
      });
    } else {
      setFormData(initialState);
    }
  }, [editData]);

  const validateForm = () => {
    const newErrors = {};
    if (!formData.title.trim()) {
      newErrors.title = "Title is required";
    }
    if (!formData.description.trim()) {
      newErrors.description = "Description is required";
    }
    if (!formData.expiryDate) {
      newErrors.expiryDate = "Expiry date is required";
    } else {
      const selectedDate = new Date(formData.expiryDate);
      const today = new Date();
      if (selectedDate <= today) {
        newErrors.expiryDate = "Expiry date must be in the future";
      }
    }
    return newErrors;
  };

  const handleSubmit = async (action) => {
    const newErrors = validateForm();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    try {
      if (action === "publish") {
        // Always publish the offer
        if (isNewOffer) {
          // If it's a new offer, call the addAndPublishOffer API
          await offerService.addAndPublishOffer(formData);
        } else {
          // If it's an existing offer, call updateOffer and then the publishOffer function
          await offerService.updateOffer(editData.id, formData);
          await offerService.publishOffer(editData.id);
        }
      } else {
        // Handle saving the offer as a draft
        if (isNewOffer) {
          await offerService.addOffer(formData);
        } else {
          await offerService.updateOffer(editData.id, formData);
        }
      }
      navigate("/offers");
    } catch (error) {
      console.error("Error saving offer:", error);
    }
  };

  const handleImageSelect = (selectedImages) => {
    setFormData((prev) => ({
      ...prev,
      selectedImages: [...prev.selectedImages, ...selectedImages],
    }));
    setShowGallery(false);
  };

  const handleRemoveImage = (index) => {
    setFormData((prev) => ({
      ...prev,
      selectedImages: prev.selectedImages.filter((_, i) => i !== index),
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <div className="container mx-auto px-4 py-6">
      {/* Header */}
      <div className="flex justify-between items-center mb-6">
        <h1
          className={`text-2xl font-semibold ${
            isDarkMode ? "text-white" : "text-gray-900"
          }`}
        >
          Add Offer
        </h1>
        <button
          onClick={() => navigate("/offers")}
          className={`p-2 rounded-lg ${
            isDarkMode
              ? "text-slate-400 hover:text-slate-300 hover:bg-slate-700"
              : "text-gray-500 hover:text-gray-700 hover:bg-gray-100"
          }`}
        >
          <XMarkIcon className="h-6 w-6" />
        </button>
      </div>

      {/* Form */}
      <div
        className={`rounded-lg border p-6 ${
          isDarkMode
            ? "bg-slate-800 border-slate-700"
            : "bg-white border-gray-200"
        }`}
      >
        <form className="space-y-4">
          <div>
            <label
              className={`block mb-2 ${
                isDarkMode ? "text-slate-300" : "text-gray-700"
              }`}
            >
              Offer Title <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              name="title"
              value={formData.title}
              onChange={handleChange}
              className={`w-full px-3 py-2 rounded-lg border ${
                isDarkMode
                  ? "bg-slate-700 border-slate-600 text-white"
                  : "bg-white border-gray-300"
              }`}
              placeholder="Enter offer title"
            />
            {errors.title && (
              <p className="text-red-500 text-sm mt-1">{errors.title}</p>
            )}
          </div>

          <div>
            <label
              className={`block mb-2 ${
                isDarkMode ? "text-slate-300" : "text-gray-700"
              }`}
            >
              Description <span className="text-red-500">*</span>
            </label>
            <textarea
              name="description"
              value={formData.description}
              onChange={handleChange}
              className={`w-full px-3 py-2 rounded-lg border ${
                isDarkMode
                  ? "bg-slate-700 border-slate-600 text-white"
                  : "bg-white border-gray-300"
              }`}
              rows={4}
              placeholder="Enter offer description"
            />
            {errors.description && (
              <p className="text-red-500 text-sm mt-1">{errors.description}</p>
            )}
          </div>

          <div>
            <label
              className={`block mb-2 ${
                isDarkMode ? "text-slate-300" : "text-gray-700"
              }`}
            >
              Expires On <span className="text-red-500">*</span>
            </label>
            <input
              type="date"
              name="expiryDate"
              value={formData.expiryDate}
              onChange={handleChange}
              className={`w-full px-3 py-2 rounded-lg border ${
                isDarkMode
                  ? "bg-slate-700 border-slate-600 text-white"
                  : "bg-white border-gray-300"
              }`}
              min={new Date().toISOString().split("T")[0]}
            />
            {errors.expiryDate && (
              <p className="text-red-500 text-sm mt-1">{errors.expiryDate}</p>
            )}
          </div>

          <div>
            <label
              className={`block mb-2 ${
                isDarkMode ? "text-slate-300" : "text-gray-700"
              }`}
            >
              Images
            </label>
            <button
              type="button"
              onClick={() => setShowGallery(true)}
              className={`w-full px-4 py-2 rounded-lg border ${
                isDarkMode
                  ? "border-slate-600 text-slate-300 hover:bg-slate-700"
                  : "border-gray-300 text-gray-700 hover:bg-gray-50"
              }`}
            >
              Select Images from Gallery
            </button>

            {formData.selectedImages.length > 0 && (
              <div className="mt-4 grid grid-cols-2 gap-4">
                {formData.selectedImages.map((image, index) => (
                  <div key={index} className="relative">
                    <img
                      src={image.url}
                      alt={image.title}
                      className="w-full h-24 object-cover rounded-lg"
                    />
                    <button
                      type="button"
                      onClick={() => handleRemoveImage(index)}
                      className="absolute top-1 right-1 p-1 rounded-full bg-red-500 text-white hover:bg-red-600"
                    >
                      <XMarkIcon className="h-4 w-4" />
                    </button>
                  </div>
                ))}
              </div>
            )}
          </div>

          <div className="flex justify-end gap-2 mt-6">
            <button
              type="button"
              onClick={() => navigate("/offers")}
              className={`px-4 py-2 rounded-lg ${
                isDarkMode
                  ? "bg-slate-700 text-slate-300 hover:bg-slate-600"
                  : "bg-gray-100 text-gray-700 hover:bg-gray-200"
              }`}
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={() => handleSubmit("save")}
              className={`px-4 py-2 rounded-lg ${
                isDarkMode
                  ? "bg-blue-600 text-white hover:bg-blue-700"
                  : "bg-blue-500 text-white hover:bg-blue-600"
              }`}
            >
              Save
            </button>
            <button
              type="button"
              onClick={() => handleSubmit("publish")}
              className={`px-4 py-2 rounded-lg ${
                isDarkMode
                  ? "bg-emerald-600 text-white hover:bg-emerald-700"
                  : "bg-emerald-500 text-white hover:bg-emerald-600"
              }`}
            >
              Publish
            </button>
          </div>
        </form>
      </div>

      {/* Gallery Selection Modal */}
      {showGallery && (
        <GallerySelector
          isOpen={showGallery}
          onClose={() => setShowGallery(false)}
          onSelect={handleImageSelect}
          isDarkMode={isDarkMode}
        />
      )}
    </div>
  );
};

export default AddOffer;
