import { useState, useEffect } from 'react';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { useTheme } from '../contexts/ThemeContext';
import billingService from '../services/billingService';

const BillingPage = () => {
  const { theme } = useTheme();
  const isDarkMode = theme === 'dark';
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [planDetails, setPlanDetails] = useState([
    {
      title: 'Plan',
      value: 'FREE',
      tooltip: 'Your current subscription plan'
    },
    {
      title: 'Giftcard Commission Rate',
      value: '0 %',
      tooltip: 'Commission rate for giftcard sales'
    },
    {
      title: 'Gallery Size',
      value: '50 Images',
      tooltip: 'Maximum number of images you can upload'
    },
    {
      title: 'Published Offers',
      value: '3 Offers',
      tooltip: 'Number of offers you can publish simultaneously'
    },
    {
      title: 'Stores',
      value: '5 Stores',
      tooltip: 'Number of store locations you can manage'
    },
    {
      title: 'Published Giftcards',
      value: '5 Giftcards',
      tooltip: 'Number of giftcard types you can publish'
    }
  ]);

  useEffect(() => {
    const fetchBillingPlan = async () => {
      try {
        setLoading(true);
        const response = await billingService.getBillingPlanDetails();
        console.log('Billing Plan Data:', response);

        setPlanDetails(prev => prev.map(detail => {
          switch (detail.title) {
            case 'Plan':
              return { ...detail, value: response.planName || 'FREE' };
            case 'Giftcard Commission Rate':
              return { ...detail, value: `${response.giftcardCommissionRate || 0} %` };
            case 'Gallery Size':
              return { ...detail, value: `${response.gallerySize || 50} Images` };
            case 'Published Offers':
              return { ...detail, value: `${response.publishedOffers || 3} Offers` };
            case 'Stores':
              return { ...detail, value: `${response.stores || 5} Stores` };
            case 'Published Giftcards':
              return { ...detail, value: `${response.publishedGiftcards || 5} Giftcards` };
            default:
              return detail;
          }
        }));
      } catch (err) {
        console.error('Error fetching billing plan:', err);
        setError('Failed to load billing plan details');
      } finally {
        setLoading(false);
      }
    };

    fetchBillingPlan();
  }, []);

  if (loading) {
    return (
      <div className={`container mx-auto px-4 py-6 ${isDarkMode ? 'bg-gray-900' : ''}`}>
        <div className="animate-pulse">
          <div className={`h-8 w-48 mb-6 rounded ${isDarkMode ? 'bg-slate-700' : 'bg-gray-200'}`}></div>
          <div className={`h-64 rounded-lg ${isDarkMode ? 'bg-slate-800' : 'bg-gray-100'}`}></div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className={`container mx-auto px-4 py-6 ${isDarkMode ? 'bg-gray-900' : ''}`}>
        <div className="text-red-500">{error}</div>
      </div>
    );
  }

  return (
    <div className={`container mx-auto px-4 py-6 ${isDarkMode ? 'bg-gray-900' : ''}`}>
      {/* Page Title */}
      <h1 className={`text-2xl font-semibold text-center mb-6 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
        Billing & Subscription
      </h1>

      {/* Freemium Banner */}
      <div className={`mb-6 p-4 rounded-lg flex items-center justify-between ${isDarkMode ? 'bg-slate-800' : 'bg-white'} border ${isDarkMode ? 'border-slate-700' : 'border-gray-200'}`}>
        <p className={`text-lg ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
          You are on a freemium plan. Upgrade to access more features.
        </p>
        <button className="px-6 py-2 bg-emerald-500 text-white rounded-lg hover:bg-emerald-600 transition-colors">
          Upgrade
        </button>
      </div>

      {/* Plan Details */}
      <div className={`rounded-lg overflow-hidden border ${isDarkMode ? 'border-slate-700' : 'border-gray-200'}`}>
        {/* Header */}
        <div className={`px-6 py-4 border-b ${isDarkMode ? 'bg-slate-800 border-slate-700' : 'bg-gray-50 border-gray-200'}`}>
          <div className="grid grid-cols-2">
            <h2 className={`text-lg font-medium ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
              Title
            </h2>
            <h2 className={`text-lg font-medium text-right ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
              Details
            </h2>
          </div>
        </div>

        {/* Plan Details List */}
        <div className={isDarkMode ? 'bg-slate-800' : 'bg-white'}>
          {planDetails.map((detail, index) => (
            <div 
              key={detail.title}
              className={`px-6 py-4 grid grid-cols-2 items-center ${index !== planDetails.length - 1 ? `border-b ${isDarkMode ? 'border-slate-700' : 'border-gray-200'}` : ''}`}
            >
              <div className="flex items-center gap-2">
                <span className={isDarkMode ? 'text-white' : 'text-gray-900'}>
                  {detail.title}
                </span>
                <div className="group relative">
                  <InformationCircleIcon className={`h-5 w-5 ${isDarkMode ? 'text-slate-400' : 'text-gray-400'}`} />
                  <div className="absolute left-1/2 -translate-x-1/2 bottom-full mb-2 w-48 p-2 rounded bg-gray-900 text-white text-sm opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all">
                    {detail.tooltip}
                    <div className="absolute left-1/2 -translate-x-1/2 top-full border-4 border-transparent border-t-gray-900" />
                  </div>
                </div>
              </div>
              <div className={`text-right ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                {detail.value}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BillingPage; 