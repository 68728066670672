import { useState } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";

const ImageCarousel = ({ images = [], isDarkMode }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handlePrevious = () => {
    setActiveIndex((prev) => (prev > 0 ? prev - 1 : images.length - 1));
  };

  const handleNext = () => {
    setActiveIndex((prev) => (prev < images.length - 1 ? prev + 1 : 0));
  };

  if (images.length === 0) {
    return <p className={`text-sm ${isDarkMode ? "text-slate-400" : "text-gray-500"}`}>No images available</p>;
  }

  return (
    <div className="relative flex flex-col items-center justify-center">
      <img
        src={images[activeIndex]}
        alt={`Offer Image ${activeIndex + 1}`}
        className="w-full h-64 object-cover rounded-lg p-2 m-2"
      />
      <div className="absolute inset-0 flex items-center justify-between">
        <button onClick={handlePrevious} className="bg-gray-500 text-white p-2 rounded-full">
          <ChevronLeftIcon className="h-6 w-6" />
        </button>
        <button onClick={handleNext} className="bg-gray-500 text-white p-2 rounded-full">
          <ChevronRightIcon className="h-6 w-6" />
        </button>
      </div>
      <div className="text-center mt-2">
        <p className={`text-sm ${isDarkMode ? "text-slate-400" : "text-gray-500"}`}>
          {activeIndex + 1} of {images.length}
        </p>
      </div>
    </div>
  );
};

export default ImageCarousel; 