import axiosInstance from './axiosConfig';

class OfferService {
  async addOffer(data) {
    const response = await axiosInstance.post('/business/offer/add', {
      title: data.title,
      description: data.description,
      expiryDate: data.expiryDate,
      offerImages: data.selectedImages.map(img => img.url)
    });
    return response;
  }

  async addAndPublishOffer(data) {
    const response = await axiosInstance.post('/business/offer/addandpublish', {
      title: data.title,
      description: data.description,
      expiryDate: data.expiryDate,
      offerImages: data.selectedImages.map(img => img.url)
    });
    return response;
  }

  async updateOffer(id, data) {
    const response = await axiosInstance.post('/business/offer/update', {
      offerId: id,
      title: data.title,
      description: data.description,
      expiryDate: data.expiryDate,
      offerImages: data.selectedImages.map(img => img.url)
    });
    return response;
  }

  async publishOffer(id) {
    const response = await axiosInstance.post('/business/offer/publish', {
      offerId: id
    });
    return response;
  }

  async deleteOffer(id) {
    const response = await axiosInstance.post('/business/offer/delete', {
      offerId: id
    });
    return response;
  }

  async getOfferDetails(id) {
    const response = await axiosInstance.post('/business/offer/details', {
      offerId: id
    });
    return response;
  }

  async listOffers(activePage, limit = 10, status = ['Draft', 'Published']) {
    const response = await axiosInstance.post('/business/offer/list', {
      offset: activePage,
      limit,
      status
    });
    return response;
  }
}

export default new OfferService(); 