import { useState, useEffect } from "react";
import { useTheme } from "../contexts/ThemeContext";
import {
  PencilSquareIcon,
  TrashIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  PlusCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { Link, useNavigate } from "react-router-dom";
import GiftCardForm from "../components/forms/GiftCardForm";
import giftCardService from '../services/giftCardService';
import toast from 'react-hot-toast';

const GiftCardModal = ({ card, onClose, isDarkMode }) => {
  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex min-h-full items-center justify-center p-4 text-center">
        <div
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          onClick={onClose}
        />

        <div
          className={`relative transform overflow-hidden rounded-lg text-left shadow-xl transition-all w-full max-w-lg ${
            isDarkMode ? "bg-slate-800" : "bg-white"
          }`}
        >
          {/* Modal Header */}
          <div
            className={`px-6 py-4 border-b ${
              isDarkMode ? "border-slate-700" : "border-gray-200"
            }`}
          >
            <div className="flex items-center justify-between">
              <h3
                className={`text-lg font-medium ${
                  isDarkMode ? "text-white" : "text-gray-900"
                }`}
              >
                Gift Card Details
              </h3>
              <button
                onClick={onClose}
                className={`p-1 rounded-lg ${
                  isDarkMode
                    ? "text-slate-400 hover:text-slate-300 hover:bg-slate-700"
                    : "text-gray-400 hover:text-gray-600 hover:bg-gray-100"
                }`}
              >
                <XMarkIcon className="h-5 w-5" />
              </button>
            </div>
          </div>

          {/* Modal Content */}
          <div className="px-6 py-4">
            <dl className="space-y-4">
              {[
                { label: "ID", value: card.id },
                { label: "Face Value", value: `$${card.amount}` },
                { label: "Actual Value", value: `$${card.actualValue}` },
                { label: "Discount", value: card.discount },
                { label: "Use in Store", value: card.termsOfUse?.useInStore ? "Yes" : "No" },
                { label: "Use Online", value: card.termsOfUse?.useOnline ? "Yes" : "No" },
                { label: "Total Sold", value: card.sold },
                { label: "Validity", value: card.validity },
                { label: "Status", value: card.status },
                { label: "Created At", value: card.createdAt },
                { label: "Last Updated", value: card.updatedAt },
                { label: "Transferable", value: card.termsOfUse?.transferable ? "Yes" : "No" },
                { label: "Refundable", value: card.termsOfUse?.refundable ? "Yes" : "No" },
                { label: "One Shot Use", value: card.termsOfUse?.oneShotUse ? "Yes" : "No" }
              ].map((item) => (
                <div key={item.label} className="grid grid-cols-2 gap-4">
                  <dt className={`text-sm font-medium ${
                    isDarkMode ? "text-slate-400" : "text-gray-500"
                  }`}>
                    {item.label}
                  </dt>
                  <dd className={`text-sm ${
                    isDarkMode ? "text-white" : "text-gray-900"
                  }`}>
                    {item.value}
                  </dd>
                </div>
              ))}
            </dl>
          </div>

          {/* Modal Footer */}
          <div
            className={`px-6 py-4 border-t ${
              isDarkMode ? "border-slate-700" : "border-gray-200"
            }`}
          >
            <button
              onClick={onClose}
              className={`w-full rounded-lg px-4 py-2 text-sm font-medium ${
                isDarkMode
                  ? "bg-slate-700 text-white hover:bg-slate-600"
                  : "bg-gray-100 text-gray-900 hover:bg-gray-200"
              }`}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const GiftCards = () => {
  const { theme } = useTheme();
  const isDarkMode = theme === "dark";
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("active");
  const [selectedCard, setSelectedCard] = useState(null);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [giftCards, setGiftCards] = useState([]);
  const itemsPerPage = 10;

  const fetchGiftCards = async () => {
    console.log('Fetching gift cards...');
    try {
      setLoading(true);
      const response = await giftCardService.listGiftCards(
        currentPage,
        itemsPerPage,
        activeTab === 'active' ? ['Draft', 'Published'] : ['Deleted']
      );
      
      console.log('Gift cards fetched:', response);
      const giftCardData = response?.data?.totalItems || [];
      const totalCount = response?.data?.totalCount || 0;
      
      const transformedData = giftCardData.map(card => ({
        id: card.id,
        amount: card.faceValue,
        discount: `${card.discount}%`,
        sold: card.soldNumber,
        status: card.status,
        validity: card.validity ? `${card.validity} Days` : 'Never',
        deletedOn: card.deletedAt ? new Date(card.deletedAt).toLocaleString() : null,
        actualValue: card.actualValue,
        termsOfUse: card.termsOfUse,
        createdAt: new Date(card.createdAt).toLocaleString(),
        updatedAt: new Date(card.updatedAt).toLocaleString()
      }));

      setGiftCards(transformedData);
      setTotalPages(Math.ceil(totalCount / itemsPerPage));
    } catch (error) {
      console.error('Error fetching gift cards:', error);
      setGiftCards([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log('useEffect triggered');
    fetchGiftCards();
  }, [currentPage, activeTab]);

  const handlePublish = async (id) => {
    try {
      await giftCardService.publishGiftCard(id);
      fetchGiftCards();
    } catch (error) {
      console.error('Error publishing gift card:', error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await giftCardService.deleteGiftCard(id);
      fetchGiftCards();
    } catch (error) {
      console.error('Error deleting gift card:', error);
    }
  };

  const handleEdit = (card) => {
    // Convert the data to match the add form structure
    const editData = {
      amount: card.amount,
      discount: parseInt(card.discount), // Remove the % sign
      validity: card.validity === 'Never' ? 'never' : 'days',
      validityDays: card.validity === 'Never' ? '' : parseInt(card.validity),
      id: card.id // Add id for updating
    };

    // Navigate to add page with state
    navigate('/giftcards/add', { state: { editData } });
  };

  // Update pagination handlers
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(prev => prev + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(prev => prev - 1);
    }
  };

  return (
    <div
      className={`container mx-auto px-4 py-6 sm:px-6 lg:px-8 ${
        isDarkMode ? "bg-gray-900" : ""
      }`}
    >
      {/* Header with Title and Add Button */}
      <div className="mb-6 flex flex-col sm:flex-row justify-between items-center">
        <h1
          className={`text-2xl font-semibold ${
            isDarkMode ? "text-white" : "text-gray-900"
          }`}
        >
          Gift Cards
        </h1>
        <button
          onClick={() => navigate("/giftcards/add")}
          className={`p-2 rounded-lg transition-colors ${
            isDarkMode
              ? "bg-slate-700 text-slate-300 hover:text-white"
              : "bg-gray-100 text-gray-600 hover:text-gray-900"
          }`}
          aria-label="Add Gift Card"
        >
          <PlusCircleIcon className="h-7 w-7" />
        </button>
      </div>

      {/* Main Content Container */}
      <div
        className={`rounded-lg border overflow-x-auto ${
          isDarkMode
            ? "bg-slate-800 border-slate-700"
            : "bg-white border-gray-200"
        }`}
      >
        {/* Tabs */}
        <div className="px-4 sm:px-6 border-b flex gap-4 sm:gap-8 overflow-x-auto">
          <button
            onClick={() => setActiveTab("active")}
            className={`py-4 relative ${
              activeTab === "active"
                ? isDarkMode
                  ? "text-white"
                  : "text-gray-900"
                : isDarkMode
                ? "text-slate-400"
                : "text-gray-500"
            }`}
          >
            Active Giftcards
            {activeTab === "active" && (
              <div className="absolute bottom-0 left-0 right-0 h-0.5 bg-emerald-500" />
            )}
          </button>
          <button
            onClick={() => setActiveTab("past")}
            className={`py-4 relative ${
              activeTab === "past"
                ? isDarkMode
                  ? "text-white"
                  : "text-gray-900"
                : isDarkMode
                ? "text-slate-400"
                : "text-gray-500"
            }`}
          >
            Past Giftcards
            {activeTab === "past" && (
              <div className="absolute bottom-0 left-0 right-0 h-0.5 bg-emerald-500" />
            )}
          </button>
        </div>

        {/* Table */}
        {loading ? (
          <div className="flex justify-center items-center p-8">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-emerald-500"></div>
          </div>
        ) : giftCards.length === 0 ? (
          <div className={`p-8 text-center ${isDarkMode ? 'text-slate-400' : 'text-gray-500'}`}>
            No gift cards found
          </div>
        ) : (
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className={isDarkMode ? "bg-slate-800" : "bg-gray-50"}>
                <tr>
                  {activeTab === "active" ? (
                    // Active tab headers
                    <>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        GC ID
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Amount
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Discount
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Sold
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Status
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Validity
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Actions
                      </th>
                    </>
                  ) : (
                    // Updated Past tab headers
                    <>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        GC ID
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Amount
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Discount
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Sold
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Status
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Validity
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Deleted On
                      </th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody
                className={`divide-y ${
                  isDarkMode ? "divide-slate-700" : "divide-gray-200"
                }`}
              >
                {giftCards.map(
                  (card) => (
                    <tr
                      key={card.id}
                      className={isDarkMode ? "bg-slate-800" : "bg-white"}
                    >
                      <td
                        className={`px-6 py-4 text-sm ${
                          isDarkMode ? "text-slate-300" : "text-gray-900"
                        }`}
                      >
                        <button
                          onClick={() => setSelectedCard(card)}
                          className={`hover:underline focus:outline-none focus:underline ${
                            isDarkMode
                              ? "hover:text-white"
                              : "hover:text-gray-700"
                          }`}
                        >
                          {card.id}
                        </button>
                      </td>
                      <td
                        className={`px-6 py-4 text-sm ${
                          isDarkMode ? "text-slate-300" : "text-gray-900"
                        }`}
                      >
                        {card.amount}
                      </td>
                      <td
                        className={`px-6 py-4 text-sm ${
                          isDarkMode ? "text-slate-300" : "text-gray-900"
                        }`}
                      >
                        {card.discount}
                      </td>
                      <td
                        className={`px-6 py-4 text-sm ${
                          isDarkMode ? "text-slate-300" : "text-gray-900"
                        }`}
                      >
                        {card.sold}
                      </td>
                      {activeTab === "active" ? (
                        // Active card row cells
                        <>
                          <td className="px-6 py-4">
                            <span className={`px-2 py-1 text-xs font-medium rounded-full ${
                              card.status === 'Draft' 
                                ? 'bg-yellow-100 text-yellow-800'
                                : card.status === 'Published'
                                  ? 'bg-emerald-100 text-emerald-800'
                                  : 'bg-gray-100 text-gray-800'
                            }`}>
                              {card.status}
                            </span>
                          </td>
                          <td
                            className={`px-6 py-4 text-sm ${
                              isDarkMode ? "text-slate-300" : "text-gray-900"
                            }`}
                          >
                            {card.validity}
                          </td>
                          <td className="px-6 py-4 flex gap-2">
                            {card.status === "Draft" ? (
                              <>
                                <button
                                  className={`p-1 rounded-lg ${
                                    isDarkMode
                                      ? "hover:bg-slate-700 text-slate-400 hover:text-slate-300"
                                      : "hover:bg-gray-100 text-gray-500 hover:text-gray-700"
                                  }`}
                                  onClick={() => handleEdit(card)}
                                  aria-label="Edit gift card"
                                >
                                  <PencilSquareIcon className="h-5 w-5" />
                                </button>
                                <button
                                  className={`p-1 rounded-lg ${
                                    isDarkMode
                                      ? "hover:bg-slate-700 text-slate-400 hover:text-slate-300"
                                      : "hover:bg-gray-100 text-gray-500 hover:text-gray-700"
                                  }`}
                                  onClick={() => handleDelete(card.id)}
                                  aria-label="Delete gift card"
                                >
                                  <TrashIcon className="h-5 w-5" />
                                </button>
                                <button
                                  className={`px-3 py-1 text-sm rounded-lg border ${
                                    isDarkMode
                                      ? "border-slate-600 text-slate-300 hover:bg-slate-700"
                                      : "border-gray-300 text-gray-700 hover:bg-gray-50"
                                  }`}
                                  onClick={() => handlePublish(card.id)}
                                >
                                  Publish
                                </button>
                              </>
                            ) : (
                              <button
                                className={`p-1 rounded-lg ${
                                  isDarkMode
                                    ? "hover:bg-slate-700 text-slate-400 hover:text-slate-300"
                                    : "hover:bg-gray-100 text-gray-500 hover:text-gray-700"
                                }`}
                                onClick={() => handleDelete(card.id)}
                                aria-label="Delete gift card"
                              >
                                <TrashIcon className="h-5 w-5" />
                              </button>
                            )}
                          </td>
                        </>
                      ) : (
                        // Past card row cells
                        <>
                          <td className="px-6 py-4">
                            <span className={`px-2 py-1 text-xs font-medium rounded-full bg-gray-100 text-gray-800`}>
                              {card.status}
                            </span>
                          </td>
                          <td
                            className={`px-6 py-4 text-sm ${
                              isDarkMode ? "text-slate-300" : "text-gray-900"
                            }`}
                          >
                            {card.validity}
                          </td>
                          <td className={`px-6 py-4 text-sm ${
                            isDarkMode ? "text-slate-300" : "text-gray-900"
                          }`}>
                            {card.deletedOn}
                          </td>
                        </>
                      )}
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
        )}

        {/* Pagination */}
        <div className={`px-4 sm:px-6 py-3 flex items-center justify-center border-t ${
          isDarkMode ? "border-slate-700" : "border-gray-200"
        }`}>
          <div className="flex items-center gap-2">
            <div className={`text-sm ${
              isDarkMode ? "text-slate-400" : "text-gray-500"
            }`}>
              Page {currentPage} of {totalPages}
            </div>
          </div>
          <div className="flex gap-2">
            <button
              onClick={handlePrevPage}
              disabled={currentPage === 1}
              className={`p-1 rounded-lg ${
                isDarkMode
                  ? "text-slate-400 hover:text-slate-300 hover:bg-slate-700"
                  : "text-gray-500 hover:text-gray-700 hover:bg-gray-100"
              } ${currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""}`}
            >
              <ChevronLeftIcon className="h-5 w-5" />
            </button>
            <button
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
              className={`p-1 rounded-lg ${
                isDarkMode
                  ? "text-slate-400 hover:text-slate-300 hover:bg-slate-700"
                  : "text-gray-500 hover:text-gray-700 hover:bg-gray-100"
              } ${currentPage === totalPages ? "opacity-50 cursor-not-allowed" : ""}`}
            >
              <ChevronRightIcon className="h-5 w-5" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GiftCards;