import { useState, useEffect } from 'react';
import { useTheme } from '../contexts/ThemeContext';
import { useNavigate } from 'react-router-dom';
import { ChatBubbleLeftRightIcon, XMarkIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import customerService from '../services/customerService';
import { toast } from 'react-hot-toast';

const CustomerDetailsModal = ({ subscriberId, onClose, isDarkMode }) => {
  const [customer, setCustomer] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCustomerDetails = async () => {
      setIsLoading(true);
      try {
        console.log('Fetching details for customer:', subscriberId); // Debug log
        const response = await customerService.getSubscriptionDetails(subscriberId);
        console.log('Customer Details Response:', response); // Debug log
        
        // The customer data is directly in the response
        const customerData = response;
        
        // Transform the API response to match our UI needs
        setCustomer({
          name: customerData.customerName || 'N/A',
          email: customerData.email || 'N/A',
          pointsBalance: customerData.pointsBalance || 0,
          walletBalance: customerData.moneyBalance || 0,
          giftcardBalance: customerData.totalGCMoneyBalance || 0,
          totalPointsEarned: customerData.totalPointsEarnedTillDate || 0,
          totalMoneySpent: customerData.totalMoneySpentTillDate || 0,
          timesShopped: customerData.timesShopped || 0,
          timesRedeemed: customerData.timesRedeemed || 0,
          totalReferred: customerData.totalReferred || 0,
          subscribedOn: customerData.subscribedOn ? 
            new Date(customerData.subscribedOn).toLocaleString('en-US', {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
              hour: '2-digit',
              minute: '2-digit'
            }) : 'N/A'
        });
      } catch (error) {
        console.error('Error fetching customer details:', error);
        setError(error.message || 'Failed to load customer details');
      } finally {
        setIsLoading(false);
      }
    };

    if (subscriberId) {
      fetchCustomerDetails();
    }
  }, [subscriberId]);

  if (isLoading) {
    return (
      <div className="fixed inset-0 z-50 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          <div className={`relative p-6 rounded-lg shadow-xl ${isDarkMode ? 'bg-slate-800' : 'bg-white'}`}>
            <div className="flex items-center justify-center">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary-600"></div>
              <span className={`ml-2 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>Loading...</span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="fixed inset-0 z-50 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          <div className={`relative p-6 rounded-lg shadow-xl ${isDarkMode ? 'bg-slate-800' : 'bg-white'}`}>
            <div className="text-red-500">{error}</div>
            <button
              onClick={onClose}
              className={`mt-4 px-4 py-2 rounded ${isDarkMode ? 'bg-slate-700 hover:bg-slate-600' : 'bg-gray-200 hover:bg-gray-300'}`}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    );
  }

  if (!customer) {
    return <div>Customer not found.</div>;
  }

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex min-h-full items-center justify-center p-4">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" onClick={onClose} />
        
        <div className={`relative max-w-4xl w-full ${isDarkMode ? 'bg-slate-800' : 'bg-white'} rounded-lg shadow-xl`}>
          {/* Modal Header */}
          <div className={`px-6 py-4 border-b flex items-center justify-between ${isDarkMode ? 'border-slate-700' : 'border-gray-200'}`}>
            <h3 className={`text-lg font-medium ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
              Customer Details
            </h3>
            <button
              onClick={onClose}
              className={`p-1 rounded-lg ${isDarkMode ? 'text-slate-400 hover:text-slate-300 hover:bg-slate-700' : 'text-gray-400 hover:text-gray-600 hover:bg-gray-100'}`}
            >
              <XMarkIcon className="h-6 w-6" />
            </button>
          </div>

          {/* Modal Content */}
          <div className="px-6 py-4 space-y-4">
            <div className="grid grid-cols-2 gap-4">
              {/* Basic Information */}
              <div className={`p-4 rounded-lg ${isDarkMode ? 'bg-slate-700' : 'bg-gray-50'}`}>
                <h4 className={`font-medium mb-2 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>Basic Information</h4>
                <div className="space-y-2">
                  <p className={`text-sm ${isDarkMode ? 'text-slate-300' : 'text-gray-600'}`}>
                    <span className="font-medium">Name:</span> {customer.name}
                  </p>
                  <p className={`text-sm ${isDarkMode ? 'text-slate-300' : 'text-gray-600'}`}>
                    <span className="font-medium">Email:</span> {customer.email}
                  </p>
                  <p className={`text-sm ${isDarkMode ? 'text-slate-300' : 'text-gray-600'}`}>
                    <span className="font-medium">Subscribed On:</span> {customer.subscribedOn}
                  </p>
                </div>
              </div>

              {/* Balance Information */}
              <div className={`p-4 rounded-lg ${isDarkMode ? 'bg-slate-700' : 'bg-gray-50'}`}>
                <h4 className={`font-medium mb-2 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>Balance Information</h4>
                <div className="space-y-2">
                  <p className={`text-sm ${isDarkMode ? 'text-slate-300' : 'text-gray-600'}`}>
                    <span className="font-medium">Points Balance:</span> {customer.pointsBalance}
                  </p>
                  <p className={`text-sm ${isDarkMode ? 'text-slate-300' : 'text-gray-600'}`}>
                    <span className="font-medium">Wallet Balance:</span> ${customer.walletBalance}
                  </p>
                  <p className={`text-sm ${isDarkMode ? 'text-slate-300' : 'text-gray-600'}`}>
                    <span className="font-medium">Giftcard Balance:</span> ${customer.giftcardBalance}
                  </p>
                </div>
              </div>

              {/* Activity Statistics */}
              <div className={`p-4 rounded-lg ${isDarkMode ? 'bg-slate-700' : 'bg-gray-50'}`}>
                <h4 className={`font-medium mb-2 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>Activity Statistics</h4>
                <div className="space-y-2">
                  <p className={`text-sm ${isDarkMode ? 'text-slate-300' : 'text-gray-600'}`}>
                    <span className="font-medium">Total Points Earned:</span> {customer.totalPointsEarned}
                  </p>
                  <p className={`text-sm ${isDarkMode ? 'text-slate-300' : 'text-gray-600'}`}>
                    <span className="font-medium">Total Money Spent:</span> ${customer.totalMoneySpent}
                  </p>
                  <p className={`text-sm ${isDarkMode ? 'text-slate-300' : 'text-gray-600'}`}>
                    <span className="font-medium">Times Shopped:</span> {customer.timesShopped}
                  </p>
                </div>
              </div>

              {/* Additional Information */}
              <div className={`p-4 rounded-lg ${isDarkMode ? 'bg-slate-700' : 'bg-gray-50'}`}>
                <h4 className={`font-medium mb-2 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>Additional Information</h4>
                <div className="space-y-2">
                  <p className={`text-sm ${isDarkMode ? 'text-slate-300' : 'text-gray-600'}`}>
                    <span className="font-medium">Times Redeemed:</span> {customer.timesRedeemed}
                  </p>
                  <p className={`text-sm ${isDarkMode ? 'text-slate-300' : 'text-gray-600'}`}>
                    <span className="font-medium">Total Referred:</span> {customer.totalReferred}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Subscribers = () => {
  const { theme } = useTheme();
  const isDarkMode = theme === 'dark';
  const [selectedSubscriberId, setSelectedSubscriberId] = useState(null);
  const navigate = useNavigate();
  
  // Add new state variables
  const [subscribers, setSubscribers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 10;

  // Modify useEffect to fetch subscribers

    const fetchSubscribers = async () => {
      setLoading(true);
      try {
        const response = await customerService.listSubscriptions(currentPage, itemsPerPage);
        
        console.log('API Response:', response); // For debugging
        
        // Transform the API response to match the existing data structure
        const transformedSubscribers = response?.totalItems?.map(sub => ({
          id: sub.customerId,
          name: sub.customerName,
          giftcardBalance: sub.totalGCMoneyBalance ? `$${sub.totalGCMoneyBalance}` : '-',
          walletBalance: sub.moneyBalance ? `$${sub.moneyBalance}` : '-',
          pointsBalance: sub.pointsBalance || 0,
          subscribedOn: new Date(sub.subscribedOn).toLocaleString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            weekday: 'long',
            hour: '2-digit',
            minute: '2-digit'
          })
        })) || [];

        console.log('Transformed Subscribers:', transformedSubscribers); // For debugging
        const total = response?.totalCount || 0;
        setTotalCount(total);
        console.log("total" , total);
        setTotalPages(Math.ceil((response?.totalCount || 0 )/ itemsPerPage));
        setSubscribers(transformedSubscribers);
      } catch (err) {
        console.error('Error fetching subscribers:', err);
        setError('Failed to load subscribers');
        setSubscribers([]); // Reset subscribers on error
      } finally {
        setLoading(false);
      }
    };


 

  useEffect(() => {
    fetchSubscribers();
  }, [currentPage]);

  // Pagination Handlers
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(prev => prev + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(prev => prev - 1);
    }
  };

  const handleChatClick = (subscriberId) => {
    navigate(`/chat?subscriberId=${subscriberId}`);
  };

  const handleSubscriberClick = (subscriberId) => {
    setSelectedSubscriberId(subscriberId);
  };

  return (
    <div className={`container mx-auto px-4 py-6 sm:px-6 lg:px-8 ${isDarkMode ? 'bg-gray-900' : ''}`}>
      <h1 className={`text-2xl font-semibold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
        Subscribers
      </h1>

      {/* Loading state for the table */}
      {loading ? (
        <div className="flex justify-center items-center p-8">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-emerald-500"></div>
        </div>
      ) : (
        <>
        <div className={`mt-4 rounded-lg overflow-x-auto border ${isDarkMode ? 'border-slate-700' : 'border-gray-200'}`}>

            <table className="min-w-full divide-y divide-gray-200">
              <thead className={isDarkMode ? 'bg-slate-800' : 'bg-gray-50'}>
                <tr>
                  {/* <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Chat</th> */}
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Subscriber Name</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Giftcard Balance</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Wallet Balance</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Points Balance</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Subscribed On</th>
                </tr>
              </thead>
              <tbody className={` min-w-full divide-y ${isDarkMode ? 'divide-slate-700' : 'divide-gray-200'}`}>
                {error ? (
                  <tr>
                    <td colSpan="6" className="text-center py-4 text-red-500">{error}</td>
                  </tr>
                ) : subscribers.length === 0 ? (
                  <tr>
                    <td colSpan="6" className="text-center py-4">No subscribers found</td>
                  </tr>
                ) : (
                  subscribers.map((subscriber) => (
                    <tr key={subscriber.id} className={isDarkMode ? 'bg-slate-800' : 'bg-white'}>

                      {/* <td className="px-6 py-4">
                        <button
                          onClick={() => handleChatClick(subscriber.id)}
                          className={`p-2 rounded-full transition-colors ${
                            isDarkMode
                              ? 'text-slate-400 hover:text-slate-200 hover:bg-slate-700'
                              : 'text-gray-500 hover:text-gray-700 hover:bg-gray-100'
                          }`}
                          aria-label={`Chat with ${subscriber.name}`}
                        >
                          <ChatBubbleLeftRightIcon className="h-5 w-5" />
                        </button>
                      </td> */}
                      
                      <td className={`px-6 py-4 text-sm ${isDarkMode ? 'text-slate-300' : 'text-gray-900'}`}>
                        <button 
                          onClick={() => handleSubscriberClick(subscriber.id)}
                          className={`underline transition-colors ${
                            isDarkMode
                              ? 'text-slate-300 hover:text-slate-100'
                              : 'text-gray-900 hover:text-gray-700'
                          }`}
                        >
                          {subscriber.name}
                        </button>
                      </td>
                      <td className={`px-6 py-4 text-sm ${isDarkMode ? 'text-slate-300' : 'text-gray-900'}`}>{subscriber.giftcardBalance}</td>
                      <td className={`px-6 py-4 text-sm ${isDarkMode ? 'text-slate-300' : 'text-gray-900'}`}>{subscriber.walletBalance}</td>
                      <td className={`px-6 py-4 text-sm ${isDarkMode ? 'text-slate-300' : 'text-gray-900'}`}>{subscriber.pointsBalance}</td>
                      <td className={`px-6 py-4 text-sm ${isDarkMode ? 'text-slate-300' : 'text-gray-900'}`}>{subscriber.subscribedOn}</td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
            </div>
            {/* Pagination Controls */}
            <div
              className={`px-4 sm:px-6 py-3 flex flex-col sm:flex-row items-center justify-between sm:justify-center border-t ${
                isDarkMode ? " bg-slate-800" : "border-gray-200"
              }`}
            >
              <div className="flex items-center gap-2 sm:gap-4">
                <div
                  className={`text-sm ${
                    isDarkMode ? "text-slate-400" : "text-gray-500"
                  }`}
                >
                  {subscribers.length === 0 
                    ? "No subscribers" 
                    : `${(currentPage - 1) * itemsPerPage + 1}-${Math.min(currentPage * itemsPerPage, totalCount)} of ${totalCount}`
                  }
                </div>
                <div className="flex gap-2">
                  <button
                    onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                    disabled={currentPage === 1}
                    className={`p-1 rounded-lg ${
                      isDarkMode
                        ? "text-slate-400 hover:text-slate-300 hover:bg-slate-700"
                        : "text-gray-500 hover:text-gray-700 hover:bg-gray-100"
                    }`}
                  >
                    <ChevronLeftIcon className="h-5 w-5" />
                  </button>
                  {console.log("f" , totalPages)}
                  <button
                    onClick={() =>
                      setCurrentPage((prev) => Math.min(prev + 1, totalPages))
                    }
                    disabled={currentPage === totalPages}
                    className={`p-1 rounded-lg ${
                      isDarkMode
                        ? "text-slate-400 hover:text-slate-300 hover:bg-slate-700"
                        : "text-gray-500 hover:text-gray-700 hover:bg-gray-100"
                    }`}
                  >
                    <ChevronRightIcon className="h-5 w-5" />
                  </button>
                </div>
              </div>
            </div>
            </>
        
      )}

      {/* Customer Details Modal */}
      {selectedSubscriberId && (
        <CustomerDetailsModal
          subscriberId={selectedSubscriberId}
          onClose={() => setSelectedSubscriberId(null)}
          isDarkMode={isDarkMode}
        />
      )}
    </div>
  );
};

export default Subscribers; 