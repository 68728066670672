import { useState, useEffect } from "react";
import { useTheme } from "../../contexts/ThemeContext";
import { useNavigate, useLocation } from "react-router-dom";
import { XMarkIcon } from "@heroicons/react/24/outline";
import storeService from '../../services/storeService';

const AUSTRALIAN_STATES = [
  { code: "NSW", name: "New South Wales" },
  { code: "VIC", name: "Victoria" },
  { code: "QLD", name: "Queensland" },
  { code: "WA", name: "Western Australia" },
  { code: "SA", name: "South Australia" },
  { code: "TAS", name: "Tasmania" },
  { code: "ACT", name: "Australian Capital Territory" },
  { code: "NT", name: "Northern Territory" },
];

const AddStore = () => {
  const { theme } = useTheme();
  const isDarkMode = theme === "dark";
  const navigate = useNavigate();
  const location = useLocation();
  const isEditMode = location.state?.editMode;
  const editData = location.state?.storeData;

  const initialState = {
    id: '',
    name: "",
    address: {
      line1: "",
      line2: "",
      city: "",
      state: "",
      pincode: "",
    },
    operational: true,
    phone: "",
    website: "",
    location: {
      lat: "",
      lng: "",
    },
    counters: "",
  };

  const [formData, setFormData] = useState(initialState);
  const [errors, setErrors] = useState({});

  // Load edit data if in edit mode
  useEffect(() => {
    if (isEditMode && editData) {
      setFormData(editData);
    }
  }, [isEditMode, editData]);

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name.trim()) {
      newErrors.name = "Store name is required";
    }
    if (!formData.address.line1.trim()) {
      newErrors.line1 = "Address line 1 is required";
    }
    if (!formData.address.city.trim()) {
      newErrors.city = "City is required";
    }
    if (!formData.address.state) {
      newErrors.state = "State is required";
    }
    if (!formData.address.pincode && formData.address.pincode !== 0) {
      newErrors.pincode = "Pincode is required";
    }
    if (!formData.location.lat || !formData.location.lng) {
      newErrors.location = "Location coordinates are required";
    }
    return newErrors;
  };

  const handleSubmit = async () => {
    const newErrors = validateForm();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    try {
      const phoneNumber = formData.phone.startsWith('+61')
        ? formData.phone.replace('+61', '')
        : formData.phone;

      if (isEditMode) {
        await storeService.updateStore({
          ...formData,
          phone: phoneNumber
        });
      } else {
        await storeService.addStore({
          ...formData,
          phone: phoneNumber
        });
      }
      
      navigate('/stores');
    } catch (error) {
      console.error(isEditMode ? 'Error updating store:' : 'Error adding store:', error);
    }
  };

  const handleAddressChange = (field, value) => {
    setFormData((prev) => ({
      ...prev,
      address: {
        ...prev.address,
        [field]: field === 'pincode' ? value.toString() : value,
      },
    }));
  };

  const handleLocationChange = (field, value) => {
    setFormData((prev) => ({
      ...prev,
      location: {
        ...prev.location,
        [field]: value,
      },
    }));
  };

  return (
    <div className="container mx-auto px-4 py-6">
      {/* Header */}
      <div className="flex justify-between items-center mb-6">
        <h1
          className={`text-2xl font-semibold ${
            isDarkMode ? "text-white" : "text-gray-900"
          }`}
        >
          {isEditMode ? 'Update Store' : 'Add Store'}
        </h1>
        <button
          onClick={() => navigate("/stores")}
          className={`p-2 rounded-lg ${
            isDarkMode
              ? "text-slate-400 hover:text-slate-300 hover:bg-slate-700"
              : "text-gray-500 hover:text-gray-700 hover:bg-gray-100"
          }`}
        >
          <XMarkIcon className="h-6 w-6" />
        </button>
      </div>

      {/* Form */}
      <div
        className={`rounded-lg border p-6 ${
          isDarkMode
            ? "bg-slate-800 border-slate-700"
            : "bg-white border-gray-200"
        }`}
      >
        <form className="space-y-4">
          <div>
            <label
              className={`block mb-2 ${
                isDarkMode ? "text-slate-300" : "text-gray-700"
              }`}
            >
              Store Name <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              value={formData.name}
              onChange={(e) =>
                setFormData({ ...formData, name: e.target.value })
              }
              className={`w-full px-3 py-2 rounded-lg border ${
                isDarkMode
                  ? "bg-slate-700 border-slate-600 text-white"
                  : "bg-white border-gray-300"
              }`}
              placeholder="Enter store name"
            />
            {errors.name && (
              <p className="text-red-500 text-sm mt-1">{errors.name}</p>
            )}
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label
                className={`block mb-2 ${
                  isDarkMode ? "text-slate-300" : "text-gray-700"
                }`}
              >
                Address Line 1 <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                value={formData.address.line1}
                onChange={(e) => handleAddressChange("line1", e.target.value)}
                className={`w-full px-3 py-2 rounded-lg border ${
                  isDarkMode
                    ? "bg-slate-700 border-slate-600 text-white"
                    : "bg-white border-gray-300"
                }`}
                placeholder="Enter address line 1"
              />
              {errors.line1 && (
                <p className="text-red-500 text-sm mt-1">{errors.line1}</p>
              )}
            </div>

            <div>
              <label
                className={`block mb-2 ${
                  isDarkMode ? "text-slate-300" : "text-gray-700"
                }`}
              >
                Address Line 2
              </label>
              <input
                type="text"
                value={formData.address.line2}
                onChange={(e) => handleAddressChange("line2", e.target.value)}
                className={`w-full px-3 py-2 rounded-lg border ${
                  isDarkMode
                    ? "bg-slate-700 border-slate-600 text-white"
                    : "bg-white border-gray-300"
                }`}
                placeholder="Enter address line 2"
              />
            </div>

            <div>
              <label
                className={`block mb-2 ${
                  isDarkMode ? "text-slate-300" : "text-gray-700"
                }`}
              >
                City <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                value={formData.address.city}
                onChange={(e) => handleAddressChange("city", e.target.value)}
                className={`w-full px-3 py-2 rounded-lg border ${
                  isDarkMode
                    ? "bg-slate-700 border-slate-600 text-white"
                    : "bg-white border-gray-300"
                }`}
                placeholder="Enter city"
              />
              {errors.city && (
                <p className="text-red-500 text-sm mt-1">{errors.city}</p>
              )}
            </div>

            <div>
              <label
                className={`block mb-2 ${
                  isDarkMode ? "text-slate-300" : "text-gray-700"
                }`}
              >
                State <span className="text-red-500">*</span>
              </label>
              <select
                value={formData.address.state}
                onChange={(e) => handleAddressChange("state", e.target.value)}
                className={`w-full px-3 py-2 rounded-lg border ${
                  isDarkMode
                    ? "bg-slate-700 border-slate-600 text-white"
                    : "bg-white border-gray-300"
                }`}
              >
                <option value="">Select a state</option>
                {AUSTRALIAN_STATES.map((state) => (
                  <option key={state.code} value={state.code}>
                    {state.name}
                  </option>
                ))}
              </select>
              {errors.state && (
                <p className="text-red-500 text-sm mt-1">{errors.state}</p>
              )}
            </div>

            <div>
              <label
                className={`block mb-2 ${
                  isDarkMode ? "text-slate-300" : "text-gray-700"
                }`}
              >
                Pincode <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                value={formData.address.pincode}
                onChange={(e) => handleAddressChange("pincode", e.target.value)}
                className={`w-full px-3 py-2 rounded-lg border ${
                  isDarkMode
                    ? "bg-slate-700 border-slate-600 text-white"
                    : "bg-white border-gray-300"
                }`}
                placeholder="Enter pincode"
              />
              {errors.pincode && (
                <p className="text-red-500 text-sm mt-1">{errors.pincode}</p>
              )}
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label
                className={`block mb-2 ${
                  isDarkMode ? "text-slate-300" : "text-gray-700"
                }`}
              >
                Phone Number
              </label>
              <input
                type="tel"
                value={formData.phone}
                onChange={(e) =>
                  setFormData({ ...formData, phone: e.target.value })
                }
                className={`w-full px-3 py-2 rounded-lg border ${
                  isDarkMode
                    ? "bg-slate-700 border-slate-600 text-white"
                    : "bg-white border-gray-300"
                }`}
                placeholder="Enter phone number"
              />
            </div>

            <div>
              <label
                className={`block mb-2 ${
                  isDarkMode ? "text-slate-300" : "text-gray-700"
                }`}
              >
                Website
              </label>
              <input
                type="url"
                value={formData.website}
                onChange={(e) =>
                  setFormData({ ...formData, website: e.target.value })
                }
                className={`w-full px-3 py-2 rounded-lg border ${
                  isDarkMode
                    ? "bg-slate-700 border-slate-600 text-white"
                    : "bg-white border-gray-300"
                }`}
                placeholder="Enter website URL"
              />
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div>
              <label
                className={`block mb-2 ${
                  isDarkMode ? "text-slate-300" : "text-gray-700"
                }`}
              >
                Latitude <span className="text-red-500">*</span>
              </label>
              <input
                type="number"
                step="any"
                value={formData.location.lat}
                onChange={(e) => handleLocationChange("lat", e.target.value)}
                className={`w-full px-3 py-2 rounded-lg border ${
                  isDarkMode
                    ? "bg-slate-700 border-slate-600 text-white"
                    : "bg-white border-gray-300"
                } ${isEditMode ? 'opacity-50' : ''}`}
                placeholder="Enter latitude"
                disabled={isEditMode}
              />
            </div>

            <div>
              <label
                className={`block mb-2 ${
                  isDarkMode ? "text-slate-300" : "text-gray-700"
                }`}
              >
                Longitude <span className="text-red-500">*</span>
              </label>
              <input
                type="number"
                step="any"
                value={formData.location.lng}
                onChange={(e) => handleLocationChange("lng", e.target.value)}
                className={`w-full px-3 py-2 rounded-lg border ${
                  isDarkMode
                    ? "bg-slate-700 border-slate-600 text-white"
                    : "bg-white border-gray-300"
                } ${isEditMode ? 'opacity-50' : ''}`}
                placeholder="Enter longitude"
                disabled={isEditMode}
              />
            </div>

            <div>
              <label
                className={`block mb-2 ${
                  isDarkMode ? "text-slate-300" : "text-gray-700"
                }`}
              >
                Number of Counters
              </label>
              <input
                type="number"
                value={formData.counters}
                className={`w-full px-3 py-2 rounded-lg border ${
                  isDarkMode
                    ? "bg-slate-700 border-slate-600 text-white"
                    : "bg-white border-gray-300"
                }`}
                placeholder="Number of counters"
                // disabled={isEditMode}
              />
            </div>
          </div>
          {errors.location && (
            <p className="text-red-500 text-sm mt-1">{errors.location}</p>
          )}

          <div className="flex items-center">
            <input
              type="checkbox"
              checked={formData.operational}
              onChange={(e) =>
                setFormData({ ...formData, operational: e.target.checked })
              }
              className="mr-2"
            />
            <label className={isDarkMode ? "text-slate-300" : "text-gray-700"}>
              Operational
            </label>
          </div>

          <div className="flex justify-end gap-2 mt-6">
            <button
              type="button"
              onClick={() => navigate("/stores")}
              className={`px-4 py-2 rounded-lg ${
                isDarkMode
                  ? "bg-slate-700 text-slate-300 hover:bg-slate-600"
                  : "bg-gray-100 text-gray-700 hover:bg-gray-200"
              }`}
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={handleSubmit}
              className={`px-4 py-2 rounded-lg ${
                isDarkMode
                  ? "bg-blue-600 text-white hover:bg-blue-700"
                  : "bg-blue-500 text-white hover:bg-blue-600"
              }`}
            >
              {isEditMode ? 'Update Store' : 'Add Store'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddStore;
