import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AuthProvider } from "./contexts/AuthContext";
import { ThemeProvider, useTheme } from "./contexts/ThemeContext";
import DashboardLayout from "./layouts/DashboardLayout";
import Login from "./pages/Login";
import NotFound from "./pages/NotFound";
import PrivateRoute from "./components/PrivateRoute";
import { Toaster } from "react-hot-toast";
import StoreSelect from "./components/StoreSelect";
import BusinessRegistration from "./pages/BusinessRegistration";

// Separate component for Toaster to access theme context
const ToasterComponent = () => {
  const { theme } = useTheme();
  
  return (
    <Toaster
      position="top-right"
      toastOptions={{
        duration: 4000,
        style: {
          background: theme === 'dark' ? '#1e293b' : '#ffffff',
          color: theme === 'dark' ? '#ffffff' : '#000000',
          border: theme === 'dark' ? '1px solid #334155' : '1px solid #e2e8f0',
        },
        success: {
          duration: 3000,
          theme: {
            primary: '#4aed88',
          },
        },
        error: {
          duration: 4000,
          theme: {
            primary: '#ff4b4b',
          },
        },
      }}
    />
  );
};

const App = () => {
  return (
    <Router>
      <ThemeProvider>
        <AuthProvider>
          <ToasterComponent />
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route element={<PrivateRoute />}>
              <Route path="/*" element={<DashboardLayout />} />
            </Route>
            <Route path="/register" element={<BusinessRegistration />} />
          </Routes>
        </AuthProvider>
      </ThemeProvider>
    </Router>
  );
};

export default App;
