import axiosInstance from './axiosConfig';

class GalleryService {
  async uploadImage(formData) {
    // const formData = new FormData();
    // formData.append('image', formData.imageFile);
    // formData.append('name', formData.name);

    const response = await axiosInstance.post('/business/gallary/image/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response;
  }

  async listImages(page = 1, limit = 10) {
    const response = await axiosInstance.post('/business/gallary/image/list', {
      offset: (page - 1) * limit,
      limit,
    });
    return response;
  }
}

export default new GalleryService(); 