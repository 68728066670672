import { Link } from 'react-router-dom';
import { useTheme } from '../contexts/ThemeContext';

const NotFound = () => {
  const { theme } = useTheme();
  
  return (
    <div className={`min-h-screen flex flex-col justify-center py-12 sm:px-6 lg:px-8 ${
      theme === 'dark' ? 'bg-[rgb(var(--bg-primary))]' : 'bg-[rgb(var(--bg-primary))]'
    }`}>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className={`py-8 px-4 shadow sm:rounded-lg sm:px-10 ${
          theme === 'dark' ? 'bg-[rgb(var(--bg-secondary))]' : 'bg-white'
        }`}>
          <div className="text-center">
            <h1 className={`text-6xl font-bold ${
              theme === 'dark' ? 'text-white' : 'text-[rgb(var(--text-primary))]'
            }`}>404</h1>
            <h2 className={`mt-4 text-2xl font-medium ${
              theme === 'dark' ? 'text-slate-300' : 'text-[rgb(var(--text-secondary))]'
            }`}>Page Not Found</h2>
            <p className={`mt-2 text-base ${
              theme === 'dark' ? 'text-slate-400' : 'text-[rgb(var(--text-secondary))]'
            }`}>
              The page you're looking for doesn't exist or has been moved.
            </p>
            <div className="mt-6">
              <Link
                to="/"
                className={`inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white ${
                  theme === 'dark' 
                    ? 'bg-primary-600 hover:bg-primary-700' 
                    : 'bg-primary-500 hover:bg-primary-600'
                }`}
              >
                Go back home
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound; 