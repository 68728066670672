import { useState, useEffect } from "react";
import { useTheme } from "../contexts/ThemeContext";
import {
  PlusCircleIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import staffService from '../services/staffService';
import { toast } from 'react-hot-toast';

const Staff = () => {
  const { theme } = useTheme();
  const isDarkMode = theme === "dark";
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [activePage, setActivePage] = useState(1);
  const itemsPerPage = 10;
  const [staffData, setStaffData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [totalPages, setTotalPages] = useState(1);
  const [staffList, setStaffList] = useState([]);

  // Fetch staff data
  const fetchStaff = async () => {
    try {
      setLoading(true);
      const response = await staffService.listStaff(activePage, itemsPerPage);
      
      console.log('Staff API Response:', response); // Log the response

      if (!response || !response.totalItems) {
        setStaffData([]);
        setTotalPages(1); // Reset total pages if no items
        return;
      }

      // Transform the API response data to match the component's expected format
      const transformedData = response.totalItems.map(staff => ({
        id: staff.id,
        name: staff.name,
        email: staff.email,
        phone: staff.phoneNumber,
        status: staff.isActive ? 'Active' : 'Inactive',
        chatAccess: staff.chatAccess ? 'Yes' : 'No'
      }));
      
      setStaffData(transformedData);
      setTotalPages(Math.ceil(response.totalCount / itemsPerPage));
    } catch (err) {
      console.error('Error fetching staff:', err);
      setError('Failed to load staff data');
      setStaffData([]);
    } finally {
      setLoading(false);
    }
  };

  // // Function to fetch staff members
  // const fetchStaffList = async () => {
  //   try {
  //     const response = await staffService.listStaff(); // Assuming you have a method to fetch staff
  //     setStaffList(response.totalItems); // Set the staff list from the response
  //   } catch (err) {
  //     console.error('Error fetching staff list:', err);
  //     setError('Failed to load staff list');
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  useEffect(() => {
    fetchStaff();
    // fetchStaffList(); // Call the fetch function on mount
  }, [activePage, itemsPerPage]);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleAddStaff = () => {
    // Handle adding a new staff member
    console.log("Add Staff clicked");
  };

  const handleEditStaff = (staffMember) => {
    // Navigate to AddStaff page with staff data
    navigate('/staff/add', { 
      state: { 
        editMode: true,
        staffData: {
          id: staffMember.id,
          name: staffMember.name,
          email: staffMember.email,
          phone: staffMember.phone,
          status: staffMember.status === 'Active',
          chatAccess: staffMember.chatAccess === 'Yes'
        }
      }
    });
  };

  if (error) return <div>{error}</div>;

  return (
    <div
      className={`container mx-auto px-4 py-6 sm:px-6 lg:px-8 ${
        isDarkMode ? "bg-gray-900" : ""
      }`}
    >
      <div className="flex flex-col sm:flex-row justify-between items-center mb-6">
        <h1
          className={`text-2xl font-semibold ${
            isDarkMode ? "text-white" : "text-gray-900"
          }`}
        >
          Staff
        </h1>
        <button
          onClick={() => navigate("/staff/add")}
          className={`p-2 rounded-lg ${
            isDarkMode
              ? "bg-slate-700 text-slate-300 hover:text-white"
              : "bg-gray-100 text-gray-600 hover:text-gray-900"
          }`}
          aria-label="Add Staff"
        >
          <PlusCircleIcon className="h-7 w-7" />
        </button>
      </div>

      <div
        className={`mt-4 overflow-x-auto rounded-lg border ${
          isDarkMode ? "border-slate-700" : "border-gray-200"
        }`}
      >
        <div className="overflow-x-auto">
          {/* Loading state for the table */}
          {loading ? (
            <div className="flex justify-center items-center p-8">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-emerald-500"></div>
            </div>
          ) : (
            <table className="min-w-full divide-y divide-gray-200">
              <thead className={isDarkMode ? "bg-slate-800" : "bg-gray-50"}>
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Name
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Phone Number
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Email
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Status
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Chat Access
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody
                className={`divide-y ${
                  isDarkMode ? "divide-slate-700" : "divide-gray-200"
                }`}
              >
                {staffData.map((staff) => (
                  <tr
                    key={staff.id}
                    className={isDarkMode ? "bg-slate-800" : "bg-white"}
                  >
                    <td
                      className={`px-6 py-4 text-sm ${
                        isDarkMode ? "text-slate-300" : "text-gray-900"
                      }`}
                    >
                      {staff.name}
                    </td>
                    <td
                      className={`px-6 py-4 text-sm ${
                        isDarkMode ? "text-slate-300" : "text-gray-900"
                      }`}
                    >
                      {staff.phone}
                    </td>
                    <td
                      className={`px-6 py-4 text-sm ${
                        isDarkMode ? "text-slate-300" : "text-gray-900"
                      }`}
                    >
                      {staff.email}
                    </td>
                    <td
                      className={`px-6 py-4 text-sm ${
                        isDarkMode ? "text-slate-300" : "text-gray-900"
                      }`}
                    >
                      {staff.status}
                    </td>
                    <td
                      className={`px-6 py-4 text-sm ${
                        isDarkMode ? "text-slate-300" : "text-gray-900"
                      }`}
                    >
                      {staff.chatAccess}
                    </td>
                    <td className="px-6 py-4">
                      <button
                        onClick={() => handleEditStaff(staff)}
                        className={`p-2 rounded-lg ${
                          isDarkMode
                            ? "text-slate-400 hover:text-slate-300 hover:bg-slate-700"
                            : "text-gray-500 hover:text-gray-700 hover:bg-gray-100"
                        }`}
                        aria-label={`Edit Staff ${staff.name}`}
                      >
                        <PencilSquareIcon className="h-5 w-5" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>

        {/* Centered Pagination */}
        <div
          className={`px-4 sm:px-6 py-3 flex flex-col sm:flex-row items-center justify-between sm:justify-center border-t ${
            isDarkMode ? "bg-slate-800" : "bg-white"
          }`}
        >
          <div className="flex items-center gap-2 sm:gap-4">
            <div className={`text-sm ${isDarkMode ? "text-slate-400" : "text-gray-500"}`}>
              {`${(activePage - 1) * itemsPerPage + 1}-${Math.min(activePage * itemsPerPage, staffData.length)} of ${staffData.length}`}
            </div>
            <div className="flex gap-2">
              <button
                onClick={() => setActivePage((prev) => Math.max(prev - 1, 1))}
                disabled={activePage === 1}
                className={`p-1 rounded-lg ${
                  isDarkMode
                    ? "text-slate-400 hover:text-slate-300 hover:bg-slate-700"
                    : "text-gray-500 hover:text-gray-700 hover:bg-gray-100"
                }`}
              >
                <ChevronLeftIcon className="h-5 w-5" />
              </button>
              <button
                onClick={() => setActivePage((prev) => Math.min(prev + 1, totalPages))}
                disabled={activePage === totalPages}
                className={`p-1 rounded-lg ${
                  isDarkMode
                    ? "text-slate-400 hover:text-slate-300 hover:bg-slate-700"
                    : "text-gray-500 hover:text-gray-700 hover:bg-gray-100"
                }`}
              >
                <ChevronRightIcon className="h-5 w-5" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Staff;
