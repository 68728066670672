import React from 'react';

const EditRewardPlanModal = ({ isOpen, onClose, onSubmit, editableFields, setEditableFields, isDarkMode }) => {
  if (!isOpen) return null;

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Prevent setting negative or zero values
    if (name === 'pointsPerDollarSpent' || name === 'minimumPointsRedeemTarget' || name === 'minimumPointsTargetValueCents') {
      if (value < 1) return; // Prevent negative or zero values
    }

    setEditableFields((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="fixed inset-0 bg-black opacity-50" onClick={onClose}></div>
      <div className={`rounded-lg p-6 z-10 ${isDarkMode ? 'bg-slate-800' : 'bg-white'}`}>
        <h2 className={`text-lg font-semibold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>Edit Reward Plan</h2>
        <div className="mt-4">
          <label className={`block mb-2 ${isDarkMode ? 'text-slate-300' : 'text-gray-700'}`}>Points Per Dollar Spent</label>
          <input
            type="number"
            name="pointsPerDollarSpent"
            value={editableFields.pointsPerDollarSpent}
            onChange={handleChange}
            min="1"
            className={`border rounded p-2 w-full ${isDarkMode ? 'bg-slate-700 text-white' : 'bg-white text-gray-900'}`}
          />
        </div>
        <div className="mt-4">
          <label className={`block mb-2 ${isDarkMode ? 'text-slate-300' : 'text-gray-700'}`}>Minimum Points Redeem Target</label>
          <input
            type="number"
            name="minimumPointsRedeemTarget"
            value={editableFields.minimumPointsRedeemTarget}
            onChange={handleChange}
            min="1"
            className={`border rounded p-2 w-full ${isDarkMode ? 'bg-slate-700 text-white' : 'bg-white text-gray-900'}`}
          />
        </div>
        <div className="mt-4">
          <label className={`block mb-2 ${isDarkMode ? 'text-slate-300' : 'text-gray-700'}`}>Minimum Points Target Value ($)</label>
          <input
            type="number"
            name="minimumPointsTargetValueCents"
            value={editableFields.minimumPointsTargetValueCents}
            onChange={handleChange}
            min="1"
            className={`border rounded p-2 w-full ${isDarkMode ? 'bg-slate-700 text-white' : 'bg-white text-gray-900'}`}
          />
        </div>
        <div className="mt-4 flex justify-end">
          <button onClick={onClose} className="mr-2 bg-gray-300 rounded px-4 py-2">Cancel</button>
          <button onClick={onSubmit} className="bg-blue-500 text-white rounded px-4 py-2">Update</button>
        </div>
      </div>
    </div>
  );
};

export default EditRewardPlanModal; 