import { useEffect, useState } from 'react';
import { InformationCircleIcon, PencilSquareIcon } from '@heroicons/react/24/outline';
import { useTheme } from '../contexts/ThemeContext';
import { useAuth } from '../contexts/AuthContext';
import RewardPlanService from '../services/rewardPlanService';
import EditRewardPlanModal from '../components/EditRewardPlanModal';

const RewardPlan = () => {
  const { theme } = useTheme();
  const { user } = useAuth();
  const isDarkMode = theme === 'dark';
  const isStaff = user?.role === 'staff';

  const [rewardDetails, setRewardDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [editableFields, setEditableFields] = useState({
    pointsPerDollarSpent: 1,
    minimumPointsRedeemTarget: 1000,
    minimumPointsTargetValueCents: 10,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Function to fetch reward plan details
  const fetchRewardPlanDetails = async () => {
    try {
      setLoading(true);
      const response = await RewardPlanService.getRewardPlanDetails();
      setRewardDetails(response.data);
      setEditableFields({
        pointsPerDollarSpent: response.data.pointsPerDollarSpent.value,
        minimumPointsRedeemTarget: response.data.minimumPointsRedeemTarget.value,
        minimumPointsTargetValueCents: response.data.minimumPointsTargetValueCents.value,
      });
    } catch (err) {
      console.error('Error fetching reward plan details:', err);
      setError('Failed to load reward plan details');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRewardPlanDetails(); // Call the fetch function on mount
  }, []);

  const handleUpdate = async () => {
    try {
      // Construct the complete payload
      const payload = {
        type: {
          title: rewardDetails.type.title,
          value: rewardDetails.type.value,
          info: rewardDetails.type.info,
        },
        pointsForNewSubscription: {
          title: 'Points for New Subscription',
          value: rewardDetails.pointsForNewSubscription.value,
          info: rewardDetails.pointsForNewSubscription.info,
        },
        pointsBonusForFirstShopping: {
          title: 'Bonus Points for First Shopping',
          value: rewardDetails.pointsBonusForFirstShopping.value,
          info: rewardDetails.pointsBonusForFirstShopping.info,
        },
        referralBonus: {
          title: 'Referral Bonus',
          value: rewardDetails.referralBonus.value,
          info: rewardDetails.referralBonus.info,
        },
        pointsPerDollarSpent: {
          title: 'Points Per Dollar Spent',
          value: editableFields.pointsPerDollarSpent,
          info: rewardDetails.pointsPerDollarSpent.info,
        },
        minimumPointsRedeemTarget: {
          title: 'Minimum Points Redeem Target',
          value: editableFields.minimumPointsRedeemTarget,
          info: rewardDetails.minimumPointsRedeemTarget.info,
        },
        minimumPointsTargetValueCents: {
          title: 'Minimum Points Target Value',
          value: editableFields.minimumPointsTargetValueCents,
          info: rewardDetails.minimumPointsTargetValueCents.info,
        },
      };

      await RewardPlanService.updateRewardPlan(payload);
      setIsModalOpen(false);

      // Refetch the reward plan details after updating
      fetchRewardPlanDetails(); // Call the function to refetch details
    } catch (error) {
      console.error('Error updating reward plan:', error);
    }
  };

  if (loading) return (
    <div className="flex justify-center items-center p-8">
      <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-emerald-500"></div>
    </div>
  );
  if (error) return <div>{error}</div>;

  return (
    <div className="container mx-auto px-4 py-6">
      {/* Header with Title and Edit Button */}
      <div className="mb-6 flex justify-between items-center">
        <h1 className={`text-2xl font-semibold ${
          isDarkMode ? 'text-white' : 'text-gray-900'
        }`}>
          Reward Plan
        </h1>
        {!isStaff && (
          <button
            onClick={() => setIsModalOpen(true)}
            className={`p-2 rounded-lg transition-colors ${
              isDarkMode 
                ? 'bg-slate-700 text-slate-300 hover:text-white'
                : 'bg-gray-100 text-gray-600 hover:text-gray-900'
            }`}
            aria-label="Edit reward plan"
          >
            <PencilSquareIcon className="h-7 w-7" />
          </button>
        )}
      </div>

      {/* Main Content Container */}
      <div className={`rounded-lg border ${
        isDarkMode ? 'bg-slate-800 border-slate-700' : 'bg-white border-gray-200'
      }`}>
        <div className="overflow-x-auto">
          {/* Column Headers */}
          <div className={`px-6 py-4 border-b grid grid-cols-2 ${
            isDarkMode ? 'border-slate-700 bg-slate-800' : 'border-gray-200 bg-gray-50'
          }`}>
            <h2 className={`text-lg font-medium ${
              isDarkMode ? 'text-white' : 'text-gray-900'
            }`}>
              Title
            </h2>
            <h2 className={`text-lg font-medium text-right ${
              isDarkMode ? 'text-white' : 'text-gray-900'
            }`}>
              Details
            </h2>
          </div>

          {/* Plan Details List */}
          <div>
            {Object.entries(rewardDetails).map(([key, detail], index) => (
              <div 
                key={key}
                className={`px-6 py-4 grid grid-cols-2 items-center ${
                  index !== Object.entries(rewardDetails).length - 1 ? `border-b ${
                    isDarkMode ? 'border-slate-700' : 'border-gray-200'
                  }` : ''
                }`}
              >
                <div className="flex items-center gap-2">
                  <span className={isDarkMode ? 'text-white' : 'text-gray-900'}>
                    {detail.title}
                  </span>
                  <div className="group relative">
                    <InformationCircleIcon className={`h-5 w-5 ${
                      isDarkMode ? 'text-slate-400' : 'text-gray-400'
                    }`} />
                    <div className="absolute left-1/2 -translate-x-1/2 bottom-full mb-2 w-48 p-2 rounded bg-gray-900 text-white text-sm opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all">
                      {detail.info}
                      <div className="absolute left-1/2 -translate-x-1/2 top-full border-4 border-transparent border-t-gray-900" />
                    </div>
                  </div>
                </div>
                <div className={`text-right ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                  {detail.value}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Edit Reward Plan Modal */}
      <EditRewardPlanModal 
        isOpen={isModalOpen} 
        onClose={() => setIsModalOpen(false)} 
        onSubmit={handleUpdate} 
        editableFields={editableFields} 
        setEditableFields={setEditableFields} 
        isDarkMode={isDarkMode}
      />
    </div>
  );
};

export default RewardPlan; 