import axiosInstance from './axiosConfig';

class StoreService {
  async addStore(data) {
    const response = await axiosInstance.post('/business/store/add', {
      name: data.name,
      isOperational: data.operational,
      phoneNumber: data.phone,
      website: data.website,
      address: {
        line1: data.address.line1,
        line2: data.address.line2,
        city: data.address.city,
        state: data.address.state,
        country: 'AU',
        pinCode: parseInt(data.address.pincode) || 0
      },
      latitude: data.location.lat,
      longitude: data.location.lng,
      counters: data.counters
    });
    return response;
  }

  async listStores(page = 1, limit = 10) {
    try {
      const userType = JSON.parse(localStorage.getItem('user'))?.userType;
      const url = userType === 'Staff' ? '/staff/store/list' : '/business/store/list';
      console.log('Calling listStores with:', { page, limit });
      const response = await axiosInstance.post(url, {
        offset: page - 1,
        limit
      });
      console.log('Store API Response:', response);
      return response;
    } catch (error) {
      console.error('Store Service Error:', error);
      throw error;
    }
  }

  async updateStore(data) {
    try {
      const response = await axiosInstance.post('/business/store/update', {
        id: data.id,
        name: data.name,
        isOperational: data.operational,
        phoneNumber: data.phone,
        website: data.website,
        address: {
          line1: data.address.line1,
          line2: data.address.line2,
          city: data.address.city,
          state: data.address.state,
          country: 'AU',
          pinCode: parseInt(data.address.pincode) || 0
        },
        latitude: data.location.lat,
        longitude: data.location.lng,
        counters: data.counters
      });
      return response;
    } catch (error) {
      console.error('Store Service Update Error:', error);
      throw error;
    }
  }

  async registerUser(data) {
    try {
      const response = await axiosInstance.post('/user/add', data);
      return response;
    } catch (error) {
      console.error('User Registration Error:', error);
      throw error;
    }
  }
}

export default new StoreService(); 