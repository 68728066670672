import axiosInstance from './axiosConfig';

class RewardPlanService {
  async getRewardPlanDetails() {
    try {
      const userType = JSON.parse(localStorage.getItem('user'))?.userType;
      const url = userType === 'Staff' ? '/staff/rewardplan/details' : '/business/rewardplan/details'; // Conditional URL
      const response = await axiosInstance.get(url);
      return response;
    } catch (error) {
      console.error('Reward Plan Service Error:', error);
      throw error;
    }
  }

  async updateRewardPlan(data) {
    try {
      const response = await axiosInstance.post('/business/rewardplan/update', {
        pointsPerDollarSpent: data.pointsPerDollarSpent,
        minimumPointsRedeemTarget: data.minimumPointsRedeemTarget,
        minimumPointsTargetValueCents: data.minimumPointsTargetValueCents,
      });
      return response;
    } catch (error) {
      console.error('Reward Plan Service Update Error:', error);
      throw error;
    }
  }
}

export default new RewardPlanService(); 