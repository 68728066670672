import axiosInstance from './axiosConfig';

class BusinessService {
  // Existing methods...

  async getBusinessStats() {
    const response = await axiosInstance.get('/business/stats');
    return response; // Assuming the response structure matches your needs
  }

  async uploadLogo(formData) {
    const response = await axiosInstance.post('/business/logo/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response; // Return the response data
  }
}

export default new BusinessService(); 