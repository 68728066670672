import axiosInstance from './axiosConfig';

class CustomerService {
  async searchCustomers(query) {
    try {
      const userType = JSON.parse(localStorage.getItem('user'))?.userType;
      const url = userType === 'Staff' ? '/staff/customer/search' : '/business/customer/search';
      const response = await axiosInstance.post(url, {
        query: query
      });
      return response;
    } catch (error) {
      console.error('Customer Search Error:', error);
      throw error;
    }
  }

  async getPublishedGiftcards() {
    try {
      const storeData = JSON.parse(localStorage.getItem('selectedStore'));
      const storeId = storeData?.id;
      const userType = JSON.parse(localStorage.getItem('user'))?.userType;
      const url = userType === 'Staff' ? '/staff/giftcard/list' : '/business/giftcard/list';
      const response = await axiosInstance.post(url, {
        offset: 0,
        limit: 10,
        status: ["Published"],
        storeId: storeId
      });
      console.log('Giftcard API Response:', response);
      return response.data?.totalItems || [];
    } catch (error) {
      console.error('Giftcard List Error:', error);
      throw error;
    }
  }

  async sellGiftcard(customerId, giftcardId) {
    try {
      const userType = JSON.parse(localStorage.getItem('user'))?.userType;
      const url = userType === 'Staff' ? '/staff/giftcard/sell' : '/business/giftcard/sell';
      const store = localStorage.getItem('store');
      if (!store) {
        throw new Error('Store data not found');
      }

      const storeData = JSON.parse(store);
      
      const payload = {
        cartItems: [giftcardId],
        customerId: customerId,
        storeId: storeData.storeId
      };

      console.log('Sell Giftcard Payload:', payload);
      
      const response = await axiosInstance.post(url, payload);
      return response.data;
    } catch (error) {
      console.error('Giftcard Sell Error:', error);
      throw error;
    }
  }

  async refundCustomer(customerId, refundAmount) {
    try {
      const userType = JSON.parse(localStorage.getItem('user'))?.userType;
      const url = userType === 'Staff' ? '/staff/customer/refund' : '/business/customer/refund';
      const storeData = JSON.parse(localStorage.getItem('store'));
      if (!storeData?.storeId) {
        throw new Error('Store data not found');
      }

      const response = await axiosInstance.post(url, {
        customerId,
        refundAmount: parseFloat(refundAmount),
        storeId: storeData.storeId
      });
      return response.data;
    } catch (error) {
      console.error('Customer Refund Error:', error);
      throw error;
    }
  }

  async rewardCustomer(customerId, amount) {
    try {
      const userType = JSON.parse(localStorage.getItem('user'))?.userType;
      const url = userType === 'Staff' ? '/staff/subscription/reward' : '/business/subscription/reward';
      const response = await axiosInstance.post(url, {
        customerId,
        billingAmount: parseFloat(amount).toFixed(2)
      });
      return response.data;
    } catch (error) {
      console.error('Customer Reward Error:', error);
      throw error;
    }
  }

  async getSubscriptionDetails(customerId) {
    try {
      const response = await axiosInstance.post('/business/subscription/details', {
        customerId
      });
      
      console.log('Subscription Details Raw Response:', response);
      return response;
    } catch (error) {
      console.error('Subscription Details Error:', error);
      throw error;
    }
  }

  async listSubscriptions(page = 1, limit = 10) {
    try {
      const response = await axiosInstance.post('/business/subscription/list', {
        offset: page - 1,
        limit
      });
      
      console.log('Raw API Response:', response);
      return response;
    } catch (error) {
      console.error('Subscription List Error:', error);
      throw error;
    }
  }

  async createRewardTransaction(data) {
    try {
      const userType = JSON.parse(localStorage.getItem('user'))?.userType;
      const url = userType === 'Staff' ? '/staff/rewardtransaction/add' : '/business/rewardtransaction/add';
      const storeData = JSON.parse(localStorage.getItem('store'));
      const storeId = storeData?.storeId; // Extract storeId
      
      const response = await axiosInstance.post(url, {
        ...data,
        storeId: storeId // Add storeId to the request body
      });
      return response.data;
    } catch (error) {
      console.error('Create Reward Transaction Error:', error);
      throw error;
    }
  }

  async getRewardTransactionStatus(data) {
    try {
      const userType = JSON.parse(localStorage.getItem('user'))?.userType;
      const url = userType === 'Staff' ? '/staff/rewardtransaction/status' : '/business/rewardtransaction/status';
      const response = await axiosInstance.post(url, data);
      return response;
    } catch (error) {
      console.error('Get Reward Transaction Status Error:', error);
      throw error;
    }
  }

  async rewardCustomerWithRedeem(data) {
    try {
      const userType = JSON.parse(localStorage.getItem('user'))?.userType;
      const url = userType === 'Staff' ? '/staff/rewardtransaction/reward' : '/business/rewardtransaction/reward';
      
      // Retrieve store ID from local storage
      const storeData = JSON.parse(localStorage.getItem('store'));
      const storeId = storeData?.storeId; // Extract storeId
      
      // Include storeId in the request body
      const requestBody = {
        ...data,
        storeId: storeId, // Add storeId to the request body
      };

      const response = await axiosInstance.post(url, requestBody);
      return response.data;
    } catch (error) {
      console.error('Reward Customer with Redeem Error:', error);
      throw error;
    }
  }

  async listRewardTransactions(data) {
    try {
      const userType = JSON.parse(localStorage.getItem('user'))?.userType;
      const url = userType === 'Staff' ? '/staff/rewardtransaction/list' : '/business/rewardtransaction/list';
      const response = await axiosInstance.post(url, data);
      return response.data;
    } catch (error) {
      console.error('List Reward Transactions Error:', error);
      throw error;
    }
  }

  async listWalletTransactions(data) {
    try {
      const response = await axiosInstance.post('/business/transaction/wallet/list', data);
      return response; // Assuming response contains the data you need
    } catch (error) {
      console.error('List Wallet Transactions Error:', error);
      throw error;
    }
  }

  async listGiftcardTransactions(data) {
    try {
      const response = await axiosInstance.post('/business/transaction/giftcard/list', data);
      return response; // Assuming response contains the data you need
    } catch (error) {
      console.error('List Giftcard Transactions Error:', error);
      throw error;
    }
  }
}

export default new CustomerService(); 