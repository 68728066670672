import { Dialog } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import PropTypes from "prop-types";

const Modal = ({ isOpen, onClose, title, children, isDarkMode }) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      className="fixed z-50 inset-0 overflow-y-auto"
    >
      <div className="flex min-h-screen items-center justify-center p-4">
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

        <div
          className={`relative w-full max-w-md p-6 rounded-lg shadow-xl ${
            isDarkMode ? "bg-slate-800" : "bg-white"
          }`}
        >
          <div className="flex justify-between items-center mb-4">
            <Dialog.Title
              className={`text-lg font-medium ${
                isDarkMode ? "text-white" : "text-gray-900"
              }`}
            >
              {title}
            </Dialog.Title>
            <button
              onClick={onClose}
              className={`p-1 rounded-lg ${
                isDarkMode
                  ? "text-slate-400 hover:text-slate-300"
                  : "text-gray-500 hover:text-gray-700"
              }`}
            >
              <XMarkIcon className="h-6 w-6" />
            </button>
          </div>
          {children}
        </div>
      </div>
    </Dialog>
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  isDarkMode: PropTypes.bool.isRequired,
};

export default Modal;
