import axiosInstance from './axiosConfig';

class StaffService {
  async addStaff(data) {
    const response = await axiosInstance.post('/business/staff/add', {
      name: data.name,
      email: data.email,
      phoneNumber: data.phone,
      activeStaff: data.status,
      chatAccess: data.chatAccess
    });
    return response;
  }

  async listStaff(page = 1, limit = 10) {
    try {
      const response = await axiosInstance.post('/business/staff/list', {
        offset: (page - 1) * limit,
        limit
      });
      return response; // Assuming the response structure matches your needs
    } catch (error) {
      console.error('Staff Service Error:', error);
      throw error;
    }
  }

  async updateStaff(data) {
    // console.log("Staff id " ,data.id);
    const response = await axiosInstance.post('/business/staff/update', {
      // id: data.id,
      name: data.name,
      email: data.email,
      phoneNumber: data.phone,
      activeStaff: data.status,
      chatAccess: data.chatAccess
    });
    return response;
  }

  async getGCAndOffersForDashboard() {
    const response = await axiosInstance.get('/staff/getGCAndOffersForDashboard');
    return response.data; // Assuming the response structure matches your needs
  }
}

export default new StaffService(); 