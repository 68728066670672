import axiosInstance from './axiosConfig';

class BillingService {
  async getBillingPlanDetails() {
    try {
      const userType = JSON.parse(localStorage.getItem('user'))?.userType;
      const url = userType === 'Staff' ? '/staff/billingplan/details' : '/business/billingplan/details'; // Conditional URL
      const response = await axiosInstance.get(url);
      console.log('Billing Plan Response:', response); // For debugging
      return response;
    } catch (error) {
      console.error('Billing Plan Service Error:', error);
      throw error;
    }
  }
}

export default new BillingService(); 