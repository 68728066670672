import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import ThemeToggle from "../components/ThemeToggle";
import { useTheme } from "../contexts/ThemeContext";
import { authService } from "../services/authService";
import StoreSelect from "../components/StoreSelect";
import { toast } from "react-hot-toast";

const Login = () => {
  const navigate = useNavigate();
  const { login, isAuthenticated, logout, loading: authLoading } = useAuth();
  const { theme } = useTheme();

  // Form States
  const [formData, setFormData] = useState({
    userType: "Owner",
    email: "",
    businessHandle: "",
    otp: "",
  });
  const [emailError, setEmailError] = useState("");
  const [error, setError] = useState("");
  const [showStoreSelect, setShowStoreSelect] = useState(false);

  // OTP States
  const [showOTP, setShowOTP] = useState(false);
  const [resendTimer, setResendTimer] = useState(0);
  const [otpError, setOtpError] = useState("");

  // Redirect if already authenticated and store is selected
  useEffect(() => {
    if (isAuthenticated) {
      const store = localStorage.getItem("store");
      if (store) {
        navigate("/dashboard", { replace: true });
      }
    }
  }, [isAuthenticated, navigate]);

  // Timer Effect
  useEffect(() => {
    if (resendTimer > 0) {
      const timer = setTimeout(() => setResendTimer((prev) => prev - 1), 1000);
      return () => clearTimeout(timer);
    }
  }, [resendTimer]);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) {
      return "Email is required";
    }
    if (!emailRegex.test(email)) {
      return "Please enter a valid email address";
    }
    return "";
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

    // Clear email error when user starts typing
    if (name === "email") {
      setEmailError("");
    }
  };

  const handleUserTypeChange = (type) => {
    setFormData((prev) => ({
      ...prev,
      userType: type,
      businessHandle: type === "Owner" ? "" : prev.businessHandle,
    }));
  };

  const handleSendOTP = async (e) => {
    e.preventDefault();

    const emailValidationError = validateEmail(formData.email);
    if (emailValidationError) {
      setEmailError(emailValidationError);
      return;
    }

    try {
      setIsLoading(true);
      
      // Call real API service
      const response = await authService.sendOTP({
        email: formData.email,
        userType: formData.userType,
        businessHandle: formData.businessHandle,
      });

      if (response.err) {
        toast.error(response.err);
        return;
      }

      // Auto-populate OTP field (remove in production)
      if (response.otp) {
        setFormData(prev => ({
          ...prev,
          otp: response.otp,
        }));
      }

      setShowOTP(true);
      setResendTimer(30);
    } catch (err) {
      // toast.error(err.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleVerifyOTP = async (e) => {
    e.preventDefault();

    try {
      const success = await login({
        email: formData.email,
        otp: formData.otp,
        userType: formData.userType,
        businessHandle: formData.businessHandle,
      });

      if (success) {
        setShowStoreSelect(true);
      }
    } catch (error) {
      console.error('Login error:', error);
    }
  };

  const handleResendOTP = async () => {
    if (resendTimer > 0) return;

    try {
      setError("");
      setIsLoading(true);

      // Call real API service with complete data
      const response = await authService.resendOTP({
        email: formData.email,
        userType: formData.userType,
        businessHandle: formData.businessHandle,
      });

      if (response.err) {
        toast.error(response.err);
        return;
      }

      // Auto-populate OTP field (remove in production)
      if (response.otp) {
        setFormData((prev) => ({
          ...prev,
          otp: response.otp,
        }));
      }

      setResendTimer(30);
      toast.success(response.message || 'OTP resent successfully!');
    } catch (err) {
      toast.error(err.response?.data?.message || "Failed to resend OTP. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };


  const [isLoading, setIsLoading] = useState(false);

  // After successful OTP verification and store selection
  const handleStoreSelection = (selectedStore, selectedCounter) => {
    // Store the entire store object along with the selected counter
    const storeData = {
      ...selectedStore,
      selectedCounter: selectedCounter
    };
    
    // Save the full store object to localStorage
    localStorage.setItem('selectedStore', JSON.stringify(storeData));
    
    // You can still save individual fields if needed for quick access
    localStorage.setItem('storeName', selectedStore.name);
    localStorage.setItem('counterName', selectedCounter);
    
    // Navigate to the next page
    navigate('/dashboard');
  };

  return (
    <div
      className={`min-h-screen flex flex-col relative ${
        theme === "dark"
          ? "bg-gradient-to-br from-slate-900 to-slate-800"
          : "bg-[rgb(var(--bg-primary))]"
      }`}
    >
      <div className="absolute top-4 right-4">
        <ThemeToggle />
      </div>

      <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-16 w-auto"
            src="/images/logo.png"
            alt="Loyali App"
          />
          <h2
            className={`mt-6 text-center text-3xl font-extrabold ${
              theme === "dark" ? "text-white" : "text-gray-900"
            }`}
          >
            Welcome to LoyaliApp
          </h2>
          <p
            className={`mt-2 text-center text-sm ${
              theme === "dark" ? "text-slate-300" : "text-gray-600"
            }`}
          >
            Sign in to your business account
          </p>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div
            className={`py-8 px-4 shadow-lg rounded-xl sm:px-10 ${
              theme === "dark"
                ? "bg-white/10 backdrop-blur-lg border border-white/20"
                : "bg-white border border-gray-200"
            }`}
          >
            <form
              className="space-y-6"
              onSubmit={showOTP ? handleVerifyOTP : handleSendOTP}
            >
              {error && (
                <div className="rounded-md bg-red-500/10 p-4">
                  <div className="text-sm text-red-400">{error}</div>
                </div>
              )}

              <div>
                <label
                  className={`block text-sm font-medium ${
                    theme === "dark" ? "text-slate-200" : "text-gray-700"
                  }`}
                >
                  User Type <span className="text-red-500">*</span>
                </label>
                <div className="flex rounded-lg bg-slate-800/50 p-1">
                  <button
                    type="button"
                    onClick={() => handleUserTypeChange("Owner")}
                    className={`
                      flex-1 px-4 py-2 text-sm font-medium rounded-md transition-all
                      focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-1 focus:ring-offset-slate-800
                      ${
                        formData.userType === "Owner"
                          ? "bg-blue-500 text-white shadow-lg"
                          : "text-slate-300 hover:text-white hover:bg-slate-700/50"
                      }
                    `}
                  >
                    Owner
                  </button>
                  <button
                    type="button"
                    onClick={() => handleUserTypeChange("Staff")}
                    className={`
                      flex-1 px-4 py-2 text-sm font-medium rounded-md transition-all
                      focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-1 focus:ring-offset-slate-800
                      ${
                        formData.userType === "Staff"
                          ? "bg-blue-500 text-white shadow-lg"
                          : "text-slate-300 hover:text-white hover:bg-slate-700/50"
                      }
                    `}
                  >
                    Staff
                  </button>
                </div>
              </div>

              {formData.userType === "Staff" && (
                <div>
                  <label
                    className={`block text-sm font-medium ${
                      theme === "dark" ? "text-slate-200" : "text-gray-700"
                    }`}
                  >
                    Business Handle <span className="text-red-500">*</span>
                  </label>
                  <div className="mt-1">
                    <input
                      id="businessHandle"
                      name="businessHandle"
                      type="text"
                      required
                      className={`mt-1 block w-full px-3 py-2 rounded-lg ${
                        theme === "dark"
                          ? "bg-slate-800/50 border-slate-600 text-white"
                          : "bg-gray-50 border-gray-300 text-gray-900 focus:ring-primary-500 focus:border-primary-500"
                      }`}
                      placeholder="Enter business handle"
                      value={formData.businessHandle}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              )}

              <div>
                <label
                  className={`block text-sm font-medium ${
                    theme === "dark" ? "text-slate-200" : "text-gray-700"
                  }`}
                >
                  Email address <span className="text-red-500">*</span>
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className={`mt-1 block w-full px-3 py-2 rounded-lg ${
                      theme === "dark"
                        ? "bg-slate-800/50 border-slate-600 text-white"
                        : "bg-gray-50 border-gray-300 text-gray-900 focus:ring-primary-500 focus:border-primary-500"
                    } ${
                      emailError
                        ? "border-red-500 focus:ring-red-500 focus:border-red-500"
                        : ""
                    }`}
                    placeholder="Enter your email"
                    value={formData.email}
                    onChange={handleChange}
                    onBlur={() => setEmailError(validateEmail(formData.email))}
                    aria-invalid={!!emailError}
                    aria-describedby={emailError ? "email-error" : undefined}
                  />
                  {emailError && (
                    <p
                      className={`mt-2 text-sm ${
                        theme === "dark" ? "text-red-400" : "text-red-600"
                      }`}
                      id="email-error"
                    >
                      {emailError}
                    </p>
                  )}
                </div>
              </div>

              {showOTP ? (
                <div>
                  <label
                    className={`block text-sm font-medium ${
                      theme === "dark" ? "text-slate-200" : "text-gray-700"
                    }`}
                  >
                    Enter OTP <span className="text-red-500">*</span>
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="otp"
                      maxLength="6"
                      required
                      className={`mt-1 block w-full px-3 py-2 rounded-lg ${
                        theme === "dark"
                          ? "bg-slate-800/50 border-slate-600 text-white"
                          : "bg-gray-50 border-gray-300 text-gray-900 focus:ring-primary-500 focus:border-primary-500"
                      } ${
                        otpError
                          ? "border-red-500 focus:ring-red-500 focus:border-red-500"
                          : ""
                      }`}
                      placeholder="Enter 6-digit OTP"
                      value={formData.otp}
                      onChange={handleChange}
                    />
                    {otpError && (
                      <p
                        className={`mt-2 text-sm ${
                          theme === "dark" ? "text-red-400" : "text-red-600"
                        }`}
                      >
                        {otpError}
                      </p>
                    )}
                  </div>

                  <div className="mt-4 flex items-center justify-between">
                    <button
                      type="button"
                      onClick={handleResendOTP}
                      disabled={resendTimer > 0}
                      className={`text-sm ${
                        theme === "dark"
                          ? "text-blue-400 hover:text-blue-300"
                          : "text-primary-600 hover:text-primary-700"
                      } ${
                        resendTimer > 0 ? "opacity-50 cursor-not-allowed" : ""
                      }`}
                    >
                      {resendTimer > 0
                        ? `Resend OTP in ${resendTimer}s`
                        : "Resend OTP"}
                    </button>
                  </div>
                </div>
              ) : null}

              <button
                type="submit"
                disabled={isLoading || authLoading}
                className={`                  group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white
                  ${(isLoading || authLoading)
                    ? 'bg-indigo-400 cursor-not-allowed' 
                    : 'bg-indigo-600 hover:bg-indigo-700'
                  }
                `}
              >
                {(isLoading || authLoading) ? (
                  <span className="flex items-center">
                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    {showOTP ? 'Verifying...' : 'Sending OTP...'}
                  </span>
                ) : (
                  showOTP ? 'Verify OTP' : 'Send OTP'
                )}
              </button>

              <div className="mt-6">
                <div className="relative">
                  <div className="absolute inset-0 flex items-center">
                    <div
                      className={`w-full border-t ${
                        theme === "dark"
                          ? "border-slate-600"
                          : "border-gray-200"
                      }`}
                    />
                  </div>
                  <div className="relative flex justify-center text-sm">
                    <span
                      className={`px-2 ${
                        theme === "dark"
                          ? "bg-slate-800/50 text-slate-300"
                          : "bg-white text-gray-500"
                      }`}
                    >
                      New to Loyali App?
                    </span>
                  </div>
                </div>

                <div className="mt-6 text-center">
                  <Link
                    to="/register"
                    className={`font-medium ${
                      theme === "dark"
                        ? "text-blue-400 hover:text-blue-300"
                        : "text-primary-600 hover:text-primary-700"
                    }`}
                  >
                    Register your business
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div
        className={`py-4 text-center text-sm ${
          theme === "dark" ? "text-slate-300" : "text-gray-500"
        }`}
      >
        <p>© Loyali App. All Rights Reserved.</p>
        <div className="mt-2 space-x-4">
          <a
            href="https://loyaliapp.com/privacy"
            className={
              theme === "dark" ? "hover:text-white" : "hover:text-gray-900"
            }
          >
            Privacy
          </a>
          <a
            href="https://loyaliapp.com/terms"
            className={
              theme === "dark" ? "hover:text-white" : "hover:text-gray-900"
            }
          >
            Terms
          </a>
          <a
            href="https://loyaliapp.com/faq"
            className={
              theme === "dark" ? "hover:text-white" : "hover:text-gray-900"
            }
          >
            FAQ
          </a>
        </div>
      </div>

      {showStoreSelect && (
        <StoreSelect
          onClose={() => {
            setShowStoreSelect(false);
            logout();
          }}
        />
      )}
    </div>
  );
};

export default Login;

