import React, { useState, useEffect } from 'react';
import { useTheme } from '../contexts/ThemeContext'; // Import the theme context
import customerService from '../services/customerService'; // Import the customer service
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline'; // Import icons for pagination

const Transactions = () => {
  const { theme } = useTheme(); // Get the current theme
  const isDarkMode = theme === 'dark'; // Determine if dark mode is active
  const [activeTab, setActiveTab] = useState('reward');
  const [rewardTransactions, setRewardTransactions] = useState([]); // State for reward transactions
  const [walletTransactions, setWalletTransactions] = useState([]); // State for wallet transactions (refunds)
  const [giftcardTransactions, setGiftcardTransactions] = useState([]); // State for giftcard transactions
  const [loading, setLoading] = useState(false); // Loading state for transactions
  const [activePage, setActivePage] = useState(1); // Current page for pagination
  const [totalPages, setTotalPages] = useState(1); // Total pages for pagination
  const itemsPerPage = 5; // Set items per page
  const [totalCount, setTotalCount] = useState(0);

  const soldGiftcardTransactions = [
    { transactionId: 1, date: '2023-10-03', customerId: 'C003', amount: 100 },
    { transactionId: 2, date: '2023-10-04', customerId: 'C004', amount: 150 },
  ];

  const refundTransactions = [
    { transactionId: 1, date: '2023-10-05', customerId: 'C005', amount: 30 },
    { transactionId: 2, date: '2023-10-06', customerId: 'C006', amount: 20 },
  ];

  // Function to fetch reward transactions
  const fetchRewardTransactions = async () => {
    setLoading(true);
    try {
      const response = await customerService.listRewardTransactions({ offset: activePage - 1, limit: itemsPerPage });
      const transactions = response.rewardTransactions.map(transaction => ({
        transactionId: transaction.id,
        date: new Date(transaction.createdAt).toLocaleDateString(),
        customerId: transaction.customerId,
        amount: transaction.totalBillingAmount,
        initiator: transaction.initiator,
        status: transaction.status,
      }));
      setRewardTransactions(transactions);
      setTotalPages(Math.ceil(response.rewardTransactions?.length / itemsPerPage)); // Assuming response contains totalCount
    } catch (error) {
      console.error("Error fetching reward transactions:", error);
      setTotalPages(0);
    } finally {
      setLoading(false);
    }
  };

  // Function to fetch wallet transactions (refunds)
  const fetchWalletTransactions = async () => {
    setLoading(true);
    try {
      const response = await customerService.listWalletTransactions({ offset: activePage - 1, limit: itemsPerPage });
      const transactions = response.transactions.map(transaction => ({
        transactionId: transaction.transactionId,
        date: new Date(transaction.createdAt).toLocaleDateString(),
        customerId: transaction.toId, // Include customer name in brackets
        amount: transaction.billingAmount,
        type: transaction.type,
        customerName: transaction.fromName
      }));
      setWalletTransactions(transactions);
      setTotalPages(Math.ceil(response.totalCount / itemsPerPage)); // Assuming response contains totalCount
    } catch (error) {
      console.error("Error fetching wallet transactions:", error);
    } finally {
      setLoading(false);
    }
  };

  // Function to fetch giftcard transactions
  const fetchGiftcardTransactions = async () => {
    setLoading(true);
    try {
      const response = await customerService.listGiftcardTransactions({ offset: activePage - 1, limit: itemsPerPage });
      const transactions = response.transactions.map(transaction => ({
        transactionId: transaction.transactionId,
        date: new Date(transaction.createdAt).toLocaleDateString(),
        customerId: transaction.fromId, // Include customer name in brackets
        amount: transaction.amount,
        customerName: transaction.fromName,
      }));
      setGiftcardTransactions(transactions);
      setTotalPages(Math.ceil(response.totalCount / itemsPerPage)); // Assuming response contains totalCount
    } catch (error) {
      console.error("Error fetching giftcard transactions:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Reset to the first page when the active tab changes
    setActivePage(1);
    if (activeTab === 'reward') {
      fetchRewardTransactions();
    } else if (activeTab === 'wallet') {
      fetchWalletTransactions();
    } else if (activeTab === 'redeemGiftcard') {
      fetchGiftcardTransactions();
    }
  }, [activeTab]); // Only depend on activeTab

  useEffect(() => {
    // Fetch data whenever the active page changes
    if (activeTab === 'reward') {
      fetchRewardTransactions();
    } else if (activeTab === 'wallet') {
      fetchWalletTransactions();
    } else if (activeTab === 'redeemGiftcard') {
      fetchGiftcardTransactions();
    }
  }, [activePage]); // Only depend on activePage

  return (
    <div className={`container mx-auto px-4 py-6 sm:px-6 lg:px-8 ${isDarkMode ? 'bg-gray-900' : ''}`}>
      <h1 className={`text-2xl font-semibold mb-4 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>Transactions</h1>
      <div className="mb-4 border-b flex gap-4 sm:gap-8 overflow-x-auto">
        <button
          onClick={() => setActiveTab('reward')}
          className={`py-4 relative ${activeTab === 'reward' ? (isDarkMode ? 'text-white' : 'text-gray-900') : (isDarkMode ? 'text-slate-400' : 'text-gray-500')}`}
        >
          Reward
          {activeTab === 'reward' && <div className="absolute bottom-0 left-0 right-0 h-0.5 bg-emerald-500" />}
        </button>
        <button
          onClick={() => setActiveTab('wallet')}
          className={`py-4 relative ${activeTab === 'wallet' ? (isDarkMode ? 'text-white' : 'text-gray-900') : (isDarkMode ? 'text-slate-400' : 'text-gray-500')}`}
        >
          Wallet
          {activeTab === 'wallet' && <div className="absolute bottom-0 left-0 right-0 h-0.5 bg-emerald-500" />}
        </button>
        <button
          onClick={() => setActiveTab('redeemGiftcard')}
          className={`py-4 relative ${activeTab === 'redeemGiftcard' ? (isDarkMode ? 'text-white' : 'text-gray-900') : (isDarkMode ? 'text-slate-400' : 'text-gray-500')}`}
        >
          Redeem Giftcards
          {activeTab === 'redeemGiftcard' && <div className="absolute bottom-0 left-0 right-0 h-0.5 bg-emerald-500" />}
        </button>
      </div>
      {loading ? (
        <div className="flex justify-center items-center p-8">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-emerald-500"></div>
        </div>
      ) : (
        <>
      <div className={`rounded-lg border overflow-x-auto ${isDarkMode ? 'bg-slate-800 border-slate-700' : 'bg-white border-gray-200'}`}>
        <table className="min-w-full divide-y divide-gray-200">
          <thead className={isDarkMode ? 'bg-slate-800' : 'bg-gray-50'}>
            {activeTab === 'reward' && (
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Transaction ID</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Customer ID</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Amount</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Initiator</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
              </tr>
            )}
            {activeTab === 'wallet' && (
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Transaction ID</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Customer ID</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Customer Name</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Amount</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Type</th>
              </tr>
            )}
            {activeTab === 'redeemGiftcard' && (
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Transaction ID</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Customer ID</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Customer Name</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Amount</th>
              </tr>
            )}
          </thead>
          <tbody className="divide-y divide-gray-200">
            {activeTab === 'reward' ? (
                rewardTransactions.length === 0 ? (
                <tr>
                  <td colSpan="6" className="text-center py-4">No Transactions found</td>
                </tr>
              ) : (
                rewardTransactions.map(transaction => (
                  <tr key={transaction.transactionId} className={isDarkMode ? 'bg-slate-800' : 'bg-white'}>
                    <td className={`px-6 py-4 text-sm ${isDarkMode ? 'text-slate-300' : 'text-gray-900'}`}>{transaction.transactionId}</td>
                    <td className={`px-6 py-4 text-sm ${isDarkMode ? 'text-slate-300' : 'text-gray-900'}`}>{transaction.date}</td>
                    <td className={`px-6 py-4 text-sm ${isDarkMode ? 'text-slate-300' : 'text-gray-900'}`}>{transaction.customerId}</td>
                    <td className={`px-6 py-4 text-sm ${isDarkMode ? 'text-slate-300' : 'text-gray-900'}`}>${transaction.amount}</td>
                    <td className={`px-6 py-4 text-sm ${isDarkMode ? 'text-slate-300' : 'text-gray-900'}`}>{transaction.initiator}</td>
                    <td className={`px-6 py-4 text-sm ${isDarkMode ? 'text-slate-300' : 'text-gray-900'}`}>{transaction.status}</td>
                  </tr>
                ))
              )
            ) : activeTab === 'wallet' ? (
              walletTransactions.length === 0 ? (
                <tr>
                  <td colSpan="6" className="text-center py-4">No Transactions found</td>
                </tr>
              ) : (
                walletTransactions.map(transaction => (
                  <tr key={transaction.transactionId} className={isDarkMode ? 'bg-slate-800' : 'bg-white'}>
                    <td className={`px-6 py-4 text-sm ${isDarkMode ? 'text-slate-300' : 'text-gray-900'}`}>{transaction.transactionId}</td>
                    <td className={`px-6 py-4 text-sm ${isDarkMode ? 'text-slate-300' : 'text-gray-900'}`}>{transaction.date}</td>
                    <td className={`px-6 py-4 text-sm ${isDarkMode ? 'text-slate-300' : 'text-gray-900'}`}>{transaction.customerId}</td>
                    <td className={`px-6 py-4 text-sm ${isDarkMode ? 'text-slate-300' : 'text-gray-900'}`}>{transaction.customerName}</td>
                    <td className={`px-6 py-4 text-sm ${isDarkMode ? 'text-slate-300' : 'text-gray-900'}`}>
                      {transaction.type === 'Reward' ? ` ${transaction.amount} pts` : `$${transaction.amount}`}
                    </td>
                    <td className={`px-6 py-4 text-sm ${isDarkMode ? 'text-slate-300' : 'text-gray-900'}`}>{transaction.type}</td>
                  </tr>
                ))
              )
            ) : (
              
              giftcardTransactions.map(transaction => (
                <tr key={transaction.transactionId} className={isDarkMode ? 'bg-slate-800' : 'bg-white'}>
                  <td className={`px-6 py-4 text-sm ${isDarkMode ? 'text-slate-300' : 'text-gray-900'}`}>{transaction.transactionId}</td>
                  <td className={`px-6 py-4 text-sm ${isDarkMode ? 'text-slate-300' : 'text-gray-900'}`}>{transaction.date}</td>
                  <td className={`px-6 py-4 text-sm ${isDarkMode ? 'text-slate-300' : 'text-gray-900'}`}>{transaction.customerId}</td>
                  <td className={`px-6 py-4 text-sm ${isDarkMode ? 'text-slate-300' : 'text-gray-900'}`}>{transaction.customerName}</td>
                  <td className={`px-6 py-4 text-sm ${isDarkMode ? 'text-slate-300' : 'text-gray-900'}`}>${transaction.amount}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        </div>

        {/* Pagination */}
        <div className={`px-4 sm:px-6 py-3 flex flex-col sm:flex-row items-center justify-between sm:justify-center border-t ${isDarkMode ? 'border-slate-700 bg-slate-800' : 'border-gray-200'}`}>
          <div className="flex items-center gap-2 sm:gap-4">
            <div className={`text-sm ${isDarkMode ? 'text-slate-400' : 'text-gray-500'}`}>
              {rewardTransactions.length === 0 
                ? "No transactions" 
                : `${(activePage - 1) * itemsPerPage + 1}-${Math.min(activePage * itemsPerPage, totalPages * itemsPerPage)} of ${totalPages * itemsPerPage}`
              }
            </div>
            <div className="flex gap-2">
              <button
                onClick={() => setActivePage(prev => Math.max(prev - 1, 1))}
                disabled={activePage === 1}
                className={`p-1 rounded-lg ${isDarkMode ? 'text-slate-400 hover:text-slate-300 hover:bg-slate-700' : 'text-gray-500 hover:text-gray-700 hover:bg-gray-100'}`}
              >
                <ChevronLeftIcon className="h-5 w-5" />
              </button>
              <button
                onClick={() => setActivePage(prev => Math.min(prev + 1, totalPages))}
                disabled={activePage === totalPages}
                className={`p-1 rounded-lg ${isDarkMode ? 'text-slate-400 hover:text-slate-300 hover:bg-slate-700' : 'text-gray-500 hover:text-gray-700 hover:bg-gray-100'}`}
              >
                <ChevronRightIcon className="h-5 w-5" />
              </button>
            </div>
          </div>
        </div>
        </>
      )}
    </div>
  );
};

export default Transactions; 