import { useState } from "react";
import { useTheme } from "../contexts/ThemeContext";
import { useNavigate } from "react-router-dom";
import storeService from '../services/storeService';

const AUSTRALIAN_STATES = [
  { code: "NSW", name: "New South Wales" },
  { code: "VIC", name: "Victoria" },
  { code: "QLD", name: "Queensland" },
  { code: "WA", name: "Western Australia" },
  { code: "SA", name: "South Australia" },
  { code: "TAS", name: "Tasmania" },
  { code: "ACT", name: "Australian Capital Territory" },
  { code: "NT", name: "Northern Territory" },
];

const BusinessRegistration = () => {
  const { theme } = useTheme();
  const isDarkMode = theme === "dark";
  const navigate = useNavigate();

  const initialState = {
    business: {
      name: "",
      username: "",
      website: "",
    },
    owner: {
      name: "",
      email: "",
      phone: "",
    },
    store: {
      name: "",
      phone: "",
      counters: 1,
      address: {
        line1: "",
        line2: "",
        city: "",
        state: "",
        pincode: "",
      },
      location: {
        lat: "",
        lng: "",
      },
    },
    acceptTerms: false,
  };

  const [formData, setFormData] = useState(initialState);
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};

    // Business Details validation
    if (!formData.business.name.trim()) {
      newErrors.businessName = "Business name is required";
    }
    if (!formData.business.username.trim()) {
      newErrors.username = "Business handle is required";
    } else if (!formData.business.username.startsWith("@")) {
      newErrors.username = "Business handle must start with @";
    }

    // Owner Details validation
    if (!formData.owner.name.trim()) {
      newErrors.ownerName = "Owner name is required";
    }
    if (!formData.owner.email.trim()) {
      newErrors.ownerEmail = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.owner.email)) {
      newErrors.ownerEmail = "Please enter a valid email address";
    }
    if (!formData.owner.phone.trim()) {
      newErrors.ownerPhone = "Contact number is required";
    }

    // Store Details validation
    if (!formData.store.name.trim()) {
      newErrors.storeName = "Store name is required";
    }
    if (!formData.store.counters || formData.store.counters < 1) {
      newErrors.counters = "Number of counters must be at least 1";
    }
    if (!formData.store.address.line1.trim()) {
      newErrors.addressLine1 = "Address line 1 is required";
    }
    if (!formData.store.address.city.trim()) {
      newErrors.city = "City is required";
    }
    if (!formData.store.address.state) {
      newErrors.state = "State is required";
    }
    if (!formData.store.address.pincode.trim()) {
      newErrors.pincode = "Pincode is required";
    }
    if (!formData.store.location.lat || !formData.store.location.lng) {
      newErrors.location = "Location coordinates are required";
    }

    // Terms & Conditions validation
    if (!formData.acceptTerms) {
      newErrors.terms = "You must accept the Terms & Conditions";
    }

    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = validateForm();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    try {
      // Construct the request body for user registration
      const requestBody = {
        userType: "B",
        user: {
          email: formData.owner.email,
          name: formData.owner.name,
          country: "AU", // Assuming Australia as the country
        },
        userName: formData.business.username,
        name: formData.owner.name,
        website: formData.business.website,
        phoneNumber: formData.owner.phone,
        stores: [
          {
            name: formData.store.name,
            isOperational: "true",
            isPrimary: "true",
            phoneNumber: formData.store.phone,
            website: formData.business.website,
            address: {
              line1: formData.store.address.line1,
              line2: formData.store.address.line2,
              city: formData.store.address.city,
              state: formData.store.address.state,
              country: "Australia", // Assuming Australia as the country
              pinCode: formData.store.address.pincode,
            },
            longitude: formData.store.location.lng,
            latitude: formData.store.location.lat,
            counters: formData.store.counters.toString(), // Ensure it's a string
          },
        ],
      };

      // Call the registerUser method
      await storeService.registerUser(requestBody);
      navigate("/login"); // Redirect to login on success
    } catch (error) {
      console.error("Error registering business:", error);
    }
  };

  const handleBusinessChange = (field, value) => {
    setFormData((prev) => ({
      ...prev,
      business: {
        ...prev.business,
        [field]: value,
      },
    }));
  };

  const handleOwnerChange = (field, value) => {
    setFormData((prev) => ({
      ...prev,
      owner: {
        ...prev.owner,
        [field]: value,
      },
    }));
  };

  const handleStoreChange = (field, value) => {
    setFormData((prev) => ({
      ...prev,
      store: {
        ...prev.store,
        [field]: value,
      },
    }));
  };

  const handleAddressChange = (field, value) => {
    setFormData((prev) => ({
      ...prev,
      store: {
        ...prev.store,
        address: {
          ...prev.store.address,
          [field]: value,
        },
      },
    }));
  };

  const handleLocationChange = (field, value) => {
    setFormData((prev) => ({
      ...prev,
      store: {
        ...prev.store,
        location: {
          ...prev.store.location,
          [field]: value,
        },
      },
    }));
  };

  return (
    <div
      className={`min-h-screen py-12 ${
        isDarkMode ? "bg-slate-900" : "bg-gray-50"
      }`}
    >
      <div className="container mx-auto px-4 sm:px-6 lg:px-8 max-w-3xl">
        <h1
          className={`text-3xl font-bold mb-8 text-center ${
            isDarkMode ? "text-white" : "text-gray-900"
          }`}
        >
          Register Your Business
        </h1>

        <form onSubmit={handleSubmit} className="space-y-8">
          {/* Business Details Section */}
          <div
            className={`p-6 rounded-lg ${
              isDarkMode ? "bg-slate-800" : "bg-white"
            } shadow`}
          >
            <h2
              className={`text-xl font-semibold mb-4 ${
                isDarkMode ? "text-white" : "text-gray-900"
              }`}
            >
              Business Details
            </h2>
            <div className="space-y-4">
              <div>
                <label
                  className={`block mb-1 ${
                    isDarkMode ? "text-slate-300" : "text-gray-700"
                  }`}
                >
                  Business Name <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  value={formData.business.name}
                  onChange={(e) => handleBusinessChange("name", e.target.value)}
                  className={`w-full px-3 py-2 rounded-lg border ${
                    isDarkMode
                      ? "bg-slate-700 border-slate-600 text-white"
                      : "bg-white border-gray-300"
                  }`}
                />
                {errors.businessName && (
                  <p className="text-red-500 text-sm mt-1">
                    {errors.businessName}
                  </p>
                )}
              </div>

              <div>
                <label
                  className={`block mb-1 ${
                    isDarkMode ? "text-slate-300" : "text-gray-700"
                  }`}
                >
                  Business Handle <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  value={formData.business.username}
                  onChange={(e) =>
                    handleBusinessChange("username", e.target.value)
                  }
                  placeholder="@yourbusiness"
                  className={`w-full px-3 py-2 rounded-lg border ${
                    isDarkMode
                      ? "bg-slate-700 border-slate-600 text-white"
                      : "bg-white border-gray-300"
                  }`}
                />
                {errors.username && (
                  <p className="text-red-500 text-sm mt-1">{errors.username}</p>
                )}
              </div>

              <div>
                <label
                  className={`block mb-1 ${
                    isDarkMode ? "text-slate-300" : "text-gray-700"
                  }`}
                >
                  Website
                </label>
                <input
                  type="url"
                  value={formData.business.website}
                  onChange={(e) =>
                    handleBusinessChange("website", e.target.value)
                  }
                  className={`w-full px-3 py-2 rounded-lg border ${
                    isDarkMode
                      ? "bg-slate-700 border-slate-600 text-white"
                      : "bg-white border-gray-300"
                  }`}
                />
              </div>
            </div>
          </div>

          {/* Owner Details Section */}
          <div
            className={`p-6 rounded-lg ${
              isDarkMode ? "bg-slate-800" : "bg-white"
            } shadow`}
          >
            <h2
              className={`text-xl font-semibold mb-4 ${
                isDarkMode ? "text-white" : "text-gray-900"
              }`}
            >
              Owner Details
            </h2>
            <div className="space-y-4">
              <div>
                <label
                  className={`block mb-1 ${
                    isDarkMode ? "text-slate-300" : "text-gray-700"
                  }`}
                >
                  Owner Name <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  value={formData.owner.name}
                  onChange={(e) => handleOwnerChange("name", e.target.value)}
                  className={`w-full px-3 py-2 rounded-lg border ${
                    isDarkMode
                      ? "bg-slate-700 border-slate-600 text-white"
                      : "bg-white border-gray-300"
                  }`}
                />
                {errors.ownerName && (
                  <p className="text-red-500 text-sm mt-1">
                    {errors.ownerName}
                  </p>
                )}
              </div>

              <div>
                <label
                  className={`block mb-1 ${
                    isDarkMode ? "text-slate-300" : "text-gray-700"
                  }`}
                >
                  Email <span className="text-red-500">*</span>
                </label>
                <input
                  type="email"
                  value={formData.owner.email}
                  onChange={(e) => handleOwnerChange("email", e.target.value)}
                  className={`w-full px-3 py-2 rounded-lg border ${
                    isDarkMode
                      ? "bg-slate-700 border-slate-600 text-white"
                      : "bg-white border-gray-300"
                  }`}
                />
                {errors.ownerEmail && (
                  <p className="text-red-500 text-sm mt-1">
                    {errors.ownerEmail}
                  </p>
                )}
              </div>

              <div>
                <label
                  className={`block mb-1 ${
                    isDarkMode ? "text-slate-300" : "text-gray-700"
                  }`}
                >
                  Contact Number <span className="text-red-500">*</span>
                </label>
                <input
                  type="tel"
                  value={formData.owner.phone}
                  onChange={(e) => handleOwnerChange("phone", e.target.value)}
                  className={`w-full px-3 py-2 rounded-lg border ${
                    isDarkMode
                      ? "bg-slate-700 border-slate-600 text-white"
                      : "bg-white border-gray-300"
                  }`}
                />
                {errors.ownerPhone && (
                  <p className="text-red-500 text-sm mt-1">
                    {errors.ownerPhone}
                  </p>
                )}
              </div>
            </div>
          </div>

          {/* Store Details Section */}
          <div
            className={`p-6 rounded-lg ${
              isDarkMode ? "bg-slate-800" : "bg-white"
            } shadow`}
          >
            <h2
              className={`text-xl font-semibold mb-4 ${
                isDarkMode ? "text-white" : "text-gray-900"
              }`}
            >
              Store Details
            </h2>
            <div className="space-y-4">
              <div>
                <label
                  className={`block mb-1 ${
                    isDarkMode ? "text-slate-300" : "text-gray-700"
                  }`}
                >
                  Store Name <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  value={formData.store.name}
                  onChange={(e) => handleStoreChange("name", e.target.value)}
                  className={`w-full px-3 py-2 rounded-lg border ${
                    isDarkMode
                      ? "bg-slate-700 border-slate-600 text-white"
                      : "bg-white border-gray-300"
                  }`}
                />
                {errors.storeName && (
                  <p className="text-red-500 text-sm mt-1">
                    {errors.storeName}
                  </p>
                )}
              </div>

              <div>
                <label
                  className={`block mb-1 ${
                    isDarkMode ? "text-slate-300" : "text-gray-700"
                  }`}
                >
                  Store Contact Number
                </label>
                <input
                  type="tel"
                  value={formData.store.phone}
                  onChange={(e) => handleStoreChange("phone", e.target.value)}
                  className={`w-full px-3 py-2 rounded-lg border ${
                    isDarkMode
                      ? "bg-slate-700 border-slate-600 text-white"
                      : "bg-white border-gray-300"
                  }`}
                />
              </div>

              <div>
                <label
                  className={`block mb-1 ${
                    isDarkMode ? "text-slate-300" : "text-gray-700"
                  }`}
                >
                  Number of Counters <span className="text-red-500">*</span>
                </label>
                <input
                  type="number"
                  min="1"
                  step="1"
                  value={formData.store.counters}
                  onChange={(e) => {
                    const value = Math.max(
                      1,
                      Math.floor(Number(e.target.value))
                    );
                    setFormData((prev) => ({
                      ...prev,
                      store: {
                        ...prev.store,
                        counters: value,
                      },
                    }));
                  }}
                  className={`w-full px-3 py-2 rounded-lg border ${
                    isDarkMode
                      ? "bg-slate-700 border-slate-600 text-white"
                      : "bg-white border-gray-300"
                  }`}
                />
                {errors.counters && (
                  <p className="text-red-500 text-sm mt-1">{errors.counters}</p>
                )}
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label
                    className={`block mb-1 ${
                      isDarkMode ? "text-slate-300" : "text-gray-700"
                    }`}
                  >
                    Address Line 1 <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    value={formData.store.address.line1}
                    onChange={(e) =>
                      handleAddressChange("line1", e.target.value)
                    }
                    className={`w-full px-3 py-2 rounded-lg border ${
                      isDarkMode
                        ? "bg-slate-700 border-slate-600 text-white"
                        : "bg-white border-gray-300"
                    }`}
                  />
                  {errors.addressLine1 && (
                    <p className="text-red-500 text-sm mt-1">
                      {errors.addressLine1}
                    </p>
                  )}
                </div>

                <div>
                  <label
                    className={`block mb-1 ${
                      isDarkMode ? "text-slate-300" : "text-gray-700"
                    }`}
                  >
                    Address Line 2
                  </label>
                  <input
                    type="text"
                    value={formData.store.address.line2}
                    onChange={(e) =>
                      handleAddressChange("line2", e.target.value)
                    }
                    className={`w-full px-3 py-2 rounded-lg border ${
                      isDarkMode
                        ? "bg-slate-700 border-slate-600 text-white"
                        : "bg-white border-gray-300"
                    }`}
                  />
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div>
                  <label
                    className={`block mb-1 ${
                      isDarkMode ? "text-slate-300" : "text-gray-700"
                    }`}
                  >
                    City <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    value={formData.store.address.city}
                    onChange={(e) =>
                      handleAddressChange("city", e.target.value)
                    }
                    className={`w-full px-3 py-2 rounded-lg border ${
                      isDarkMode
                        ? "bg-slate-700 border-slate-600 text-white"
                        : "bg-white border-gray-300"
                    }`}
                  />
                  {errors.city && (
                    <p className="text-red-500 text-sm mt-1">{errors.city}</p>
                  )}
                </div>

                <div>
                  <label
                    className={`block mb-1 ${
                      isDarkMode ? "text-slate-300" : "text-gray-700"
                    }`}
                  >
                    State <span className="text-red-500">*</span>
                  </label>
                  <select
                    value={formData.store.address.state}
                    onChange={(e) =>
                      handleAddressChange("state", e.target.value)
                    }
                    className={`w-full px-3 py-2 rounded-lg border ${
                      isDarkMode
                        ? "bg-slate-700 border-slate-600 text-white"
                        : "bg-white border-gray-300"
                    }`}
                  >
                    <option value="">Select a state</option>
                    {AUSTRALIAN_STATES.map((state) => (
                      <option key={state.code} value={state.code}>
                        {state.name}
                      </option>
                    ))}
                  </select>
                  {errors.state && (
                    <p className="text-red-500 text-sm mt-1">{errors.state}</p>
                  )}
                </div>

                <div>
                  <label
                    className={`block mb-1 ${
                      isDarkMode ? "text-slate-300" : "text-gray-700"
                    }`}
                  >
                    Pincode <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    value={formData.store.address.pincode}
                    onChange={(e) =>
                      handleAddressChange("pincode", e.target.value)
                    }
                    className={`w-full px-3 py-2 rounded-lg border ${
                      isDarkMode
                        ? "bg-slate-700 border-slate-600 text-white"
                        : "bg-white border-gray-300"
                    }`}
                  />
                  {errors.pincode && (
                    <p className="text-red-500 text-sm mt-1">
                      {errors.pincode}
                    </p>
                  )}
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label
                    className={`block mb-1 ${
                      isDarkMode ? "text-slate-300" : "text-gray-700"
                    }`}
                  >
                    Latitude <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    value={formData.store.location.lat}
                    onChange={(e) =>
                      handleLocationChange("lat", e.target.value)
                    }
                    className={`w-full px-3 py-2 rounded-lg border ${
                      isDarkMode
                        ? "bg-slate-700 border-slate-600 text-white"
                        : "bg-white border-gray-300"
                    }`}
                  />
                </div>

                <div>
                  <label
                    className={`block mb-1 ${
                      isDarkMode ? "text-slate-300" : "text-gray-700"
                    }`}
                  >
                    Longitude <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    value={formData.store.location.lng}
                    onChange={(e) =>
                      handleLocationChange("lng", e.target.value)
                    }
                    className={`w-full px-3 py-2 rounded-lg border ${
                      isDarkMode
                        ? "bg-slate-700 border-slate-600 text-white"
                        : "bg-white border-gray-300"
                    }`}
                  />
                </div>
              </div>
              {errors.location && (
                <p className="text-red-500 text-sm">{errors.location}</p>
              )}
            </div>
          </div>

          {/* Terms & Conditions */}
          <div className="flex items-center">
            <input
              type="checkbox"
              checked={formData.acceptTerms}
              onChange={(e) =>
                setFormData((prev) => ({
                  ...prev,
                  acceptTerms: e.target.checked,
                }))
              }
              className="mr-2"
            />
            <label className={isDarkMode ? "text-slate-300" : "text-gray-700"}>
              I accept the{" "}
              <a
                href="https://loyaliapp.com/tnc.html"
                target="_blank"
                rel="noopener noreferrer"
                className={`underline ${
                  isDarkMode
                    ? "text-blue-400 hover:text-blue-300"
                    : "text-blue-600 hover:text-blue-500"
                }`}
              >
                Terms & Conditions
              </a>{" "}
              <span className="text-red-500">*</span>
            </label>
          </div>
          {errors.terms && (
            <p className="text-red-500 text-sm">{errors.terms}</p>
          )}

          {/* Action Buttons */}
          <div className="flex justify-end gap-4">
            <button
              type="button"
              onClick={() => navigate("/login")}
              className={`px-6 py-2 rounded-lg ${
                isDarkMode
                  ? "bg-slate-700 text-slate-300 hover:bg-slate-600"
                  : "bg-gray-100 text-gray-700 hover:bg-gray-200"
              }`}
            >
              Cancel
            </button>
            <button
              type="submit"
              className={`px-6 py-2 rounded-lg ${
                isDarkMode
                  ? "bg-blue-600 text-white hover:bg-blue-700"
                  : "bg-blue-500 text-white hover:bg-blue-600"
              }`}
            >
              Register
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default BusinessRegistration;
