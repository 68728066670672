import { useState, useEffect } from "react";
import { useLocation, useNavigate, Routes, Route } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { useTheme } from "../contexts/ThemeContext";
import { Bars3Icon } from "@heroicons/react/24/outline";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import DashboardOwner from "../pages/DashboardOwner";
import DashboardStaff from "../pages/DashboardStaff";
import GiftCards from "../pages/GiftCards";
import Gallery from "../pages/Gallery";
import RewardPlan from "../pages/RewardPlan";
import Devices from "../pages/Devices";
import Profile from "../pages/Profile";
import Billing from "../pages/Billing";
import Transactions from "../pages/Transactions";
import Offers from "../pages/Offers";
import Subscribers from "../pages/Subscribers";
import Stores from "../pages/Stores";
import Staff from "../pages/Staff";
import Chat from "../pages/Chat";
import CustomerDetails from "../pages/CustomerDetails";
import NotFound from "../pages/NotFound";
import AddGiftCard from "../pages/forms/AddGiftCard";
import AddOffer from "../pages/forms/AddOffer";
import AddStaff from "../pages/forms/AddStaff";
import AddStore from "../pages/forms/AddStore";

const DashboardLayout = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [activeView, setActiveView] = useState("dashboard");
  const [isMobile, setIsMobile] = useState(false);
  const { theme, toggleTheme } = useTheme();
  const { logout, user, store } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  // Import all view components
  const views = {
    dashboard: user.userType === "Owner" ? DashboardOwner : DashboardStaff,
    giftcards: GiftCards,
    gallery: Gallery,
    rewardplan: RewardPlan,
    devices: Devices,
    profile: Profile,
    billing: Billing,
    transactions: Transactions,
    offers: Offers,
    subscribers: Subscribers,
    stores: Stores,
    staff: Staff,
    chat: Chat,
  };

  // Handle screen resize and initial setup
  useEffect(() => {
    const handleResize = () => {
      const isSmallScreen = window.innerWidth < 1024;
      setIsMobile(isSmallScreen);
      if (isSmallScreen) {
        setSidebarOpen(false);
      } else {
        setSidebarOpen(true);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Update handleNavigation to handle all routes
  const handleNavigation = (view) => {
    // Handle both direct paths and nested paths
    const basePath = view.split("/")[0]; // Get the base path

    // Don't update activeView for customer-details
    if (basePath !== "customer-details") {
      setActiveView(basePath);
    }

    // Navigate to the full path
    navigate(view);

    if (isMobile) {
      setSidebarOpen(false);
    }
  };

  // Update URL-based view selection
  useEffect(() => {
    const path = location.pathname.substring(1).split("/")[0] || "dashboard";
    if (views[path] && path !== "customer-details") {
      setActiveView(path);
    }
  }, [location]);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  // Get the component for current view
  const ViewComponent = views[activeView] || DashboardOwner;

  return (
    <div className="h-screen flex overflow-hidden bg-gray-100 dark:bg-slate-900">
      {/* Mobile sidebar overlay - handles click outside */}
      {sidebarOpen && (
        <div
          className="fixed inset-0 z-30 bg-gray-600/50 lg:hidden"
          onClick={() => setSidebarOpen(false)}
          aria-hidden="true"
        />
      )}

      {/* Mobile sidebar */}
      <Sidebar
        className={`fixed z-40 lg:hidden ${
          sidebarOpen ? "translate-x-0" : "-translate-x-full"
        } transition-transform duration-300 ease-in-out`}
        isOpen={true}
        onClose={() => setSidebarOpen(false)}
        onToggle={toggleSidebar}
        onNavigate={handleNavigation}
        showCollapseButton={false}
        onLogout={logout}
        onThemeToggle={toggleTheme}
        isDarkMode={theme === "dark"}
        activeView={activeView}
      />

      {/* Desktop sidebar */}
      <Sidebar
        className={`hidden lg:flex transition-all duration-300 ${
          sidebarOpen ? "lg:w-64" : "lg:w-20"
        }`}
        isOpen={sidebarOpen}
        isDesktop
        onToggle={toggleSidebar}
        onNavigate={handleNavigation}
        showCollapseButton={true}
        onLogout={logout}
        onThemeToggle={toggleTheme}
        isDarkMode={theme === "dark"}
        activeView={activeView}
      />

      {/* Main content wrapper */}
      <div className="flex-1 flex flex-col min-h-0">
        <Header
          onMenuClick={toggleSidebar}
          onLogout={logout}
          onThemeToggle={toggleTheme}
          user={user}
          store={store}
          onNavigate={handleNavigation}
        />

        <main className="flex overflow-y-auto container w-screen lg:w-full">
          <Routes>
            <Route
              index
              element={<ViewComponent onNavigate={handleNavigation} />}
            />
            <Route
              path="dashboard"
              element={<ViewComponent onNavigate={handleNavigation} />}
            />
            <Route path="giftcards" element={<GiftCards />} />
            <Route path="gallery" element={<Gallery />} />
            <Route path="rewardplan" element={<RewardPlan />} />
            <Route path="devices" element={<Devices />} />
            <Route path="profile" element={<Profile />} />
            <Route path="billing" element={<Billing />} />
            <Route path="transactions" element={<Transactions />} />
            <Route path="offers" element={<Offers />} />
            <Route path="subscribers" element={<Subscribers />} />
            <Route path="stores" element={<Stores />} />
            <Route path="staff" element={<Staff />} />
            <Route path="chat" element={<Chat />} />
            <Route path="customer-details" element={<CustomerDetails />} />
            <Route path="/giftcards/add" element={<AddGiftCard />} />
            <Route path="/offers/add" element={<AddOffer />} />
            <Route path="/staff/add" element={<AddStaff />} />
            <Route path="/stores/add" element={<AddStore />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </main>
      </div>
    </div>
  );
};

export default DashboardLayout;
