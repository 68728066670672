import axiosInstance from './axiosConfig';

class GiftCardService {
  async addGiftCard(data) {
    const response = await axiosInstance.post('/business/giftcard/add', {
      amount: Number(data.amount),
      discount: Number(data.discount),
      actualValue: Number(data.amount) * (1 - Number(data.discount) / 100),
      validity: data.validity === 'never' ? null : Number(data.validityDays),
      termsOfUse: {
        useInStore: true,
        useOnline: true
      }
    });
    return response;
  }

  async addAndPublishGiftCard(data) {
    const response = await axiosInstance.post('/business/giftcard/addandpublish', {
      amount: Number(data.amount),
      discount: Number(data.discount),
      actualValue: Number(data.amount) * (1 - Number(data.discount) / 100),
      validity: data.validity === 'never' ? null : Number(data.validityDays),
      termsOfUse: {
        useInStore: true,
        useOnline: true
      }
    });
    return response;
  }

  async updateGiftCard(id, data) {
    const response = await axiosInstance.post('/business/giftcard/update', {
      id,
      amount: Number(data.amount),
      discount: Number(data.discount),
      actualValue: Number(data.amount) * (1 - Number(data.discount) / 100),
      validity: data.validity === 'never' ? null : Number(data.validityDays),
      termsOfUse: {
        useInStore: true,
        useOnline: true
      }
    });
    return response;
  }

  async publishGiftCard(id) {
    const response = await axiosInstance.post('/business/giftcard/publish', {
      id
    });
    return response;
  }

  async deleteGiftCard(id) {
    const response = await axiosInstance.post('/business/giftcard/delete', {
      id
    });
    return response;
  }

  async listGiftCards(page = 1, limit = 10, status = ['Draft', 'Published']) {
    const userType = JSON.parse(localStorage.getItem('user'))?.userType;
    const url = userType === 'Staff' ? '/staff/giftcard/list' : '/business/giftcard/list';
    const response = await axiosInstance.post(url, {
      offset: page - 1,
      limit,
      status
    });
    return response;
  }
}

export default new GiftCardService(); 