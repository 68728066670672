import { SunIcon, MoonIcon } from '@heroicons/react/24/outline';
import { useTheme } from '../contexts/ThemeContext';

const ThemeToggle = () => {
  const { theme, toggleTheme } = useTheme();
  const isDark = theme === 'dark';

  return (
    <div className="flex items-center gap-2">
      <button
        onClick={toggleTheme}
        className={`flex items-center gap-2 px-3 py-2 rounded-lg transition-colors ${
          isDark 
            ? 'bg-slate-800/50 hover:bg-slate-700/50 text-slate-300' 
            : 'bg-white shadow-sm border border-gray-200 text-gray-700 hover:bg-gray-50'
        }`}
        aria-label={`Switch to ${isDark ? 'light' : 'dark'} mode`}
        role="switch"
        aria-checked={isDark}
      >
        {isDark ? (
          <>
            <SunIcon className="h-5 w-5" />
            <span className="text-sm font-medium">Light Mode</span>
          </>
        ) : (
          <>
            <MoonIcon className="h-5 w-5" />
            <span className="text-sm font-medium">Dark Mode</span>
          </>
        )}
      </button>
    </div>
  );
};

export default ThemeToggle; 