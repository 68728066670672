import axiosInstance from './axiosConfig';

class ProfileService {
  async getBusinessInfo() {
    const response = await axiosInstance.get('/business/business-info/details');
    return response;
  }

  async getAccountInfo() {
    const response = await axiosInstance.get('/business/account-info/details');
    return response;
  }

  async updateBusinessInfo(data) {
    const response = await axiosInstance.post('/business/business-info/update', {
      user: {
        name: data.businessName
      }
    });
    return response;
  }

  async updateOwnerInfo(data) {
    const response = await axiosInstance.post('/business/owner-info/update', {
      name: data.ownerName
    });
    return response;
  }

  async updateAccountInfo(data) {
    const response = await axiosInstance.post('/business/account-info/update', {
      abn: data.abn,
      bankName: data.bankName,
      branch: data.branchName,
      bsb: data.bsbNumber,
      isRegisteredForGST: data.gstStatus === 'Registered',
      name: data.bankAccountName,
      number: data.bankAccountNumber
    });
    return response;
  }

  async getLogo() {
    const response = await axiosInstance.post('/business/logo'); // Adjust the endpoint as necessary
    return response; // Assuming the logo URL is in the response data
  }
}

export const profileService = new ProfileService(); 