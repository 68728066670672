import { useState, useEffect, useCallback, useRef } from "react";
import { useTheme } from "../contexts/ThemeContext";
import {
  PlusCircleIcon,
  MagnifyingGlassIcon,
  XMarkIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/outline";
import { Dialog } from "@headlessui/react";
import galleryService from '../services/galleryService';

// Generate sample images
const generateSampleImages = (count) => {
  const categories = ["nature", "food", "tech", "abstract", "business"];
  const titles = [
    "Sunset view",
    "Mountain peaks",
    "Beach waves",
    "Forest path",
    "Delicious pasta",
    "Fresh fruits",
    "Gourmet dish",
    "Coffee art",
    "Modern laptop",
    "Smart device",
    "Digital world",
    "Tech innovation",
    "Color splash",
    "Geometric shapes",
    "Modern art",
    "Creative design",
    "Office meeting",
    "Workspace",
    "Business growth",
    "Professional team",
  ];

  return Array.from({ length: count }, (_, i) => ({
    id: `img-${i + 1}`,
    title: titles[i % titles.length],
    url: `https://source.unsplash.com/random/400x300?${
      categories[Math.floor(i / 4)]
    }`,
    category: categories[Math.floor(i / 4)],
  }));
};

const ITEMS_PER_PAGE = 12;
const TOTAL_IMAGES = 36;

const ImageModal = ({ images, currentIndex, onClose, isDarkMode }) => {
  const [activeIndex, setActiveIndex] = useState(currentIndex);
  const currentImage = images[activeIndex];

  const handlePrevious = () => {
    setActiveIndex((prev) => (prev > 0 ? prev - 1 : images.length - 1));
  };

  const handleNext = () => {
    setActiveIndex((prev) => (prev < images.length - 1 ? prev + 1 : 0));
  };

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex min-h-full items-center justify-center p-4">
        <div
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          onClick={onClose}
        />

        <div
          className={`relative w-[600px] h-[400px] ${
            isDarkMode ? "bg-slate-800" : "bg-white"
          } rounded-lg shadow-xl`}
        >
          {/* Modal Header */}
          <div
            className={`px-6 py-4 border-b flex items-center justify-between ${
              isDarkMode ? "border-slate-700" : "border-gray-200"
            }`}
          >
            <h3
              className={`text-lg font-medium ${
                isDarkMode ? "text-white" : "text-gray-900"
              }`}
            >
              {currentImage.title}
            </h3>
            <button
              onClick={onClose}
              className={`p-1 rounded-lg ${
                isDarkMode
                  ? "text-slate-400 hover:text-slate-300 hover:bg-slate-700"
                  : "text-gray-400 hover:text-gray-600 hover:bg-gray-100"
              }`}
            >
              <XMarkIcon className="h-6 w-6" />
            </button>
          </div>

          {/* Modal Content */}
          <div className="relative h-full">
            <img
              src={currentImage.url}
              alt={currentImage.title}
              className="w-full h-full object-cover"
            />

            {/* Navigation Buttons */}
            <button
              onClick={handlePrevious}
              className={`absolute left-4 top-1/2 -translate-y-1/2 p-2 rounded-full ${
                isDarkMode
                  ? "bg-slate-800/80 text-white hover:bg-slate-700"
                  : "bg-white/80 text-gray-800 hover:bg-gray-100"
              }`}
            >
              <ChevronLeftIcon className="h-6 w-6" />
            </button>
            <button
              onClick={handleNext}
              className={`absolute right-4 top-1/2 -translate-y-1/2 p-2 rounded-full ${
                isDarkMode
                  ? "bg-slate-800/80 text-white hover:bg-slate-700"
                  : "bg-white/80 text-gray-800 hover:bg-gray-100"
              }`}
            >
              <ChevronRightIcon className="h-6 w-6" />
            </button>
          </div>

          {/* Pagination Info */}
          <div
            className={`px-6 py-4 border-t ${
              isDarkMode ? "border-slate-700" : "border-gray-200"
            }`}
          >
            <p
              className={`text-sm text-center ${
                isDarkMode ? "text-slate-400" : "text-gray-500"
              }`}
            >
              {activeIndex + 1} of {images.length}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const Gallery = () => {
  const { theme } = useTheme();
  const isDarkMode = theme === "dark";
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [loading, setLoading] = useState(false);
  const [allImages, setAllImages] = useState([]);
  const [displayedImages, setDisplayedImages] = useState([]);
  const [galleryLimit, setGalleryLimit] = useState(50);
  const scrollContainerRef = useRef(null);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [imageTitle, setImageTitle] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [error, setError] = useState("");

  // Fetch images from the server
  const fetchImages = useCallback(async () => {
    try {
      setLoading(true);
      const response = await galleryService.listImages(1, 10); // Fetch the first page
      const galleryData = response.data.gallery || {};
      const images = galleryData.images || [];
      const basePath = galleryData.basePath || '';

      // Transform the data to include full URLs
      const transformedImages = images.map(image => ({
        id: image._id,
        title: image.title || image.name,
        url: `${basePath}${image.name}`,
        category: image.category,
      }));

      setAllImages(transformedImages);
      setDisplayedImages(transformedImages); // Set displayed images to all images initially
    } catch (error) {
      console.error('Error fetching images:', error);
      setAllImages([]);
      setDisplayedImages([]);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchImages(); // Fetch images when the component mounts
  }, [fetchImages]);

  // Filter images based on search query
  useEffect(() => {
    if (searchQuery.trim()) {
      const searchTerm = searchQuery.toLowerCase().trim();
      const filtered = allImages.filter((img) => {
        const fileName = img.title.toLowerCase();
        return fileName.includes(searchTerm); // Check if title includes the search term
      });
      setDisplayedImages(filtered);
    } else {
      setDisplayedImages(allImages); // Reset to all images if search query is empty
    }
  }, [searchQuery, allImages]);

  const handleUpload = async (e) => {
    e.preventDefault();
    if (!imageTitle.trim()) {
      setError("Please enter an image title");
      return;
    }
    if (!selectedFile) {
      setError("Please select an image");
      return;
    }

    try {
      const formData = new FormData();
      formData.append("image", selectedFile);
      formData.append("imageName", imageTitle); // Include the title in the request

      await galleryService.uploadImage(formData); // Upload the image
      setIsUploadModalOpen(false); // Close the modal
      fetchImages(); // Refresh the gallery after successful upload
    } catch (error) {
      console.error('Error uploading image:', error);
    }

    // Reset form
    setImageTitle("");
    setSelectedFile(null);
    setPreviewUrl(null);
    setError("");
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.type.startsWith("image/")) {
        setSelectedFile(file);
        setPreviewUrl(URL.createObjectURL(file));
        setError("");
      } else {
        setError("Please select an image file");
      }
    }
  };

  return (
    <div className="container mx-auto px-4 py-6 h-screen flex flex-col">
      {/* Fixed Header */}
      <div className="mb-6 flex-shrink-0">
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-2">
            <h1
              className={`text-2xl font-semibold ${
                isDarkMode ? "text-white" : "text-gray-900"
              }`}
            >
              Gallery
            </h1>
            <span
              className={`text-sm ${
                isDarkMode ? "text-slate-400" : "text-gray-500"
              }`}
            >
              ({allImages.length}/50)
            </span>
          </div>
          <div className="flex items-center gap-4">
            <div className="relative">
              <input
                type="text"
                placeholder="Search image by title..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className={`pl-10 pr-4 py-2 rounded-lg border ${
                  isDarkMode
                    ? "bg-slate-800 border-slate-700 text-white placeholder-slate-400"
                    : "bg-white border-gray-300 text-gray-900 placeholder-gray-400"
                }`}
              />
              <MagnifyingGlassIcon
                className={`absolute left-3 top-1/2 -translate-y-1/2 h-5 w-5 ${
                  isDarkMode ? "text-slate-400" : "text-gray-400"
                }`}
              />
            </div>
            <button
              onClick={() => setIsUploadModalOpen(true)} // Open the modal
              className={`p-2 rounded-lg transition-colors ${
                isDarkMode
                  ? "bg-slate-700 text-slate-300 hover:text-white"
                  : "bg-gray-100 text-gray-600 hover:text-gray-900"
              }`}
              aria-label="Add Images"
            >
              <PlusCircleIcon className="h-7 w-7" />
            </button>
          </div>
        </div>
      </div>

      {/* Scrollable Content Area */}
      <div className="flex-1 overflow-y-auto min-h-0 border rounded-lg">
        {/* Image Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 p-6">
          {loading ? (
            <div className="flex justify-center items-center h-64">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
            </div>
          ) : displayedImages.length === 0 ? (
            <div className={`col-span-3 text-center py-8 ${isDarkMode ? "text-slate-400" : "text-gray-500"}`}>
              No images found
            </div>
          ) : (
            displayedImages.map((image, index) => (
              <button
                key={image.id}
                onClick={() => setSelectedImageIndex(index)}
                className={`group relative rounded-lg overflow-hidden shadow-sm hover:shadow-md transition-shadow text-left ${
                  isDarkMode ? "bg-slate-800" : "bg-white"
                }`}
              >
                <div className="relative pb-[75%]">
                  <img
                    src={image.url}
                    alt={image.title}
                    className="absolute inset-0 w-full h-full object-cover"
                    loading="lazy"
                  />
                </div>
                <div className={`absolute bottom-0 inset-x-0 p-3 ${isDarkMode ? "bg-slate-800/90 text-white" : "bg-white/90 text-gray-900"}`}>
                  <h3 className="text-sm font-medium truncate">{image.title}</h3>
                </div>
              </button>
            ))
          )}
        </div>

        {/* Loading indicator */}
        {loading && (
          <div
            className={`text-center py-4 mt-4 ${
              isDarkMode ? "text-slate-400" : "text-gray-500"
            }`}
          >
            Loading more images...
          </div>
        )}

        {/* Add "End of Gallery" message when all images are loaded */}
        {!loading && displayedImages.length >= 50 && (
          <div
            className={`text-center py-4 mt-4 ${
              isDarkMode ? "text-slate-400" : "text-gray-500"
            }`}
          >
            End of Gallery
          </div>
        )}
      </div>

      {/* Image Modal */}
      {selectedImageIndex !== null && (
        <ImageModal
          images={displayedImages}
          currentIndex={selectedImageIndex}
          onClose={() => setSelectedImageIndex(null)}
          isDarkMode={isDarkMode}
        />
      )}

      {/* Upload Modal */}
      <Dialog open={isUploadModalOpen} onClose={() => setIsUploadModalOpen(false)} className="fixed z-50 inset-0 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4">
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
          <div className={`relative w-full max-w-md p-6 rounded-lg shadow-xl ${isDarkMode ? "bg-slate-800" : "bg-white"}`}>
            <Dialog.Title className={`text-lg font-medium mb-4 ${isDarkMode ? "text-white" : "text-gray-900"}`}>
              Upload Image
            </Dialog.Title>
            <form onSubmit={handleUpload}>
              {/* Image Preview */}
              <div className={`mb-4 rounded-lg border-2 border-dashed ${isDarkMode ? "border-slate-600 bg-slate-700" : "border-gray-300 bg-gray-50"} p-4`}>
                {previewUrl ? (
                  <img src={previewUrl} alt="Preview" className="w-full h-auto object-contain rounded" />
                ) : (
                  <div className={`text-center py-8 ${isDarkMode ? "text-slate-400" : "text-gray-500"}`}>
                    No image selected
                  </div>
                )}
              </div>

              {/* File Input */}
              <div className="mb-4">
                <label className={`block text-sm font-medium mb-2 ${isDarkMode ? "text-slate-400" : "text-gray-700"}`}>
                  Select Image <span className="text-red-500">*</span>
                </label>
                <input type="file" accept="image/*" onChange={handleFileChange} className={`w-full text-sm ${isDarkMode ? "text-slate-400" : "text-gray-700"}`} />
              </div>

              {/* Title Input */}
              <div className="mb-4">
                <label className={`block text-sm font-medium mb-2 ${isDarkMode ? "text-slate-400" : "text-gray-700"}`}>
                  Image Title <span className="text-red-500">*</span>
                </label>
                <input type="text" value={imageTitle} onChange={(e) => setImageTitle(e.target.value)} className={`w-full px-3 py-2 rounded-lg border ${isDarkMode ? "bg-slate-700 border-slate-600 text-white" : "bg-white border-gray-300 text-gray-900"}`} placeholder="Enter image title" />
              </div>

              {/* Error Message */}
              {error && <p className="text-red-500 text-sm mb-4">{error}</p>}

              {/* Action Buttons */}
              <div className="flex justify-end gap-2">
                <button type="button" onClick={() => setIsUploadModalOpen(false)} className={`px-4 py-2 text-sm font-medium rounded-lg ${isDarkMode ? "text-slate-400 hover:text-slate-300 bg-slate-700 hover:bg-slate-600" : "text-gray-700 hover:text-gray-900 bg-gray-100 hover:bg-gray-200"}`}>
                  Cancel
                </button>
                <button type="submit" className="px-4 py-2 text-sm font-medium text-white bg-blue-500 rounded-lg hover:bg-blue-600">
                  Upload
                </button>
              </div>
            </form>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default Gallery;
