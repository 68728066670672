import { useState, useEffect, useRef, forwardRef } from "react";
import { Html5Qrcode } from "html5-qrcode";
import { useLocation, useNavigate } from "react-router-dom";
import {
  MagnifyingGlassIcon,
  QrCodeIcon,
  XMarkIcon,
  CameraIcon,
} from "@heroicons/react/24/outline";
import { useTheme } from "../contexts/ThemeContext";
import toast from "react-hot-toast";
import customerService from "../services/customerService";

const CustomerSearch = forwardRef(({ onSearch, className }, ref) => {
  const { theme } = useTheme();
  const isDarkMode = theme === "dark";
  const [searchQuery, setSearchQuery] = useState("");
  const [showScanner, setShowScanner] = useState(false);
  const [scannerError, setScannerError] = useState("");
  const [cameraPermission, setCameraPermission] = useState("prompt"); // 'prompt', 'granted', 'denied'
  const inputRef = useRef(null);
  const scannerRef = useRef(null);
  const isMobile = typeof window !== "undefined" && window.innerWidth < 768;
  const [showPermissionModal, setShowPermissionModal] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [isSearching, setIsSearching] = useState(false);
  const [cameraAvailable, setCameraAvailable] = useState(false);

  // Keep focus on input when route changes
  useEffect(() => {
    const focusInput = () => {
      if (ref?.current) {
        ref.current.focus();
      }
    };

    // Focus only when the component mounts
    focusInput();

    // No cleanup needed since we removed the timeout
  }, [ref]);

  // Check camera permission status
  useEffect(() => {
    const checkCameraPermission = async () => {
      try {
        const result = await navigator.permissions.query({ name: "camera" });
        setCameraPermission(result.state);

        // Listen for permission changes
        result.addEventListener("change", () => {
          setCameraPermission(result.state);
        });
      } catch (error) {
        console.warn("Camera permissions API not supported");
      }
    };

    checkCameraPermission();
  }, []);

  // Check for camera availability
  useEffect(() => {
    const checkCameraAvailability = async () => {
      if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
        try {
          // Request camera access to ensure permissions are granted
          await navigator.mediaDevices.getUserMedia({ video: true });
          
          const devices = await navigator.mediaDevices.enumerateDevices();
          console.log("Devices found:", devices); // Log devices for debugging
          const hasCamera = devices.some(device => device.kind === "videoinput");
          setCameraAvailable(hasCamera);
        } catch (error) {
          console.error("Error accessing camera:", error);
          setCameraAvailable(false);
        }
      } else {
        // If mediaDevices is not available, set cameraAvailable to false
        console.warn("MediaDevices API not available");
        setCameraAvailable(false);
      }
    };

    // Call the function to check camera availability
    checkCameraAvailability();
  }, []);

  console.log("Navigator object:", navigator);

  const handleSearch = async () => {
    if (!searchQuery.trim() || isSearching) return;

    setIsSearching(true);
    try {
      const response = await customerService.searchCustomers(searchQuery.trim());
      
      // Navigate to customer details page with the customer data
      navigate("customer-details", {
        state: { 
          customer: {
            name: response.name,
            email: response.email,
            id: response.memberId,
            walletBalance: response.walletBalance,
            giftcardBalance: response.gcBalance
          }
        },
      });
      
      setSearchQuery(""); // Clear search after successful navigation

      // Refocus the input after search
      setTimeout(() => {
        ref?.current?.focus();
      }, 100);
    } catch (error) {
      // Clear search and refocus immediately
      setSearchQuery("");
      ref?.current?.focus();
    } finally {
      setIsSearching(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSearch();
    }
  };

  const requestCameraPermission = async () => {
    try {
      await navigator.mediaDevices.getUserMedia({ video: true });
      setCameraPermission("granted");
      handleScan(); // Start scanner after permission is granted
    } catch (error) {
      console.error("Camera permission denied:", error);
      setCameraPermission("denied");
      setScannerError("Camera access was denied. Please allow camera access and try again.");
    }
  };

  const openSystemSettings = () => {
    if (
      navigator.userAgent.includes("iPhone") ||
      navigator.userAgent.includes("iPad")
    ) {
      // For iOS devices
      window.location.href = "app-settings:";
    } else if (navigator.userAgent.includes("Android")) {
      // For Android devices
      window.location.href =
        "intent://settings/apps#Intent;scheme=package;package=" +
        window.location.hostname +
        ";end";
    } else {
      // For desktop browsers
      window.open("chrome://settings/content/camera");
    }
  };

  const handleScanClick = async () => {
    if (cameraPermission === "denied") {
      toast.error("Camera access denied. Please allow camera access.");
      return;
    }

    if (cameraPermission === "prompt") {
      await requestCameraPermission();
    } else {
      handleScan();
    }
  };

  const handleScan = async () => {
    try {
      setShowScanner(true);
      setScannerError("");

      // Delay the initialization to ensure the reader element is rendered
      setTimeout(async () => {
        const readerElement = document.getElementById("reader");
        if (!readerElement) {
          throw new Error("HTML Element with id=reader not found");
        }

        const html5QrCode = new Html5Qrcode(readerElement.id);
        scannerRef.current = html5QrCode;

        const config = {
          fps: 10,
          qrbox: { width: 250, height: 250 },
          aspectRatio: 1.0,
        };

        await html5QrCode.start(
          { facingMode: "environment" },
          config,
          async (decodedText) => {
            setSearchQuery(decodedText);
            await handleSearch();
            closeScanner();
          },
          (errorMessage) => {
            if (!errorMessage.includes("No QR code found")) {
              console.warn(`Code scan error = ${errorMessage}`);
            }
          }
        );
      }, 100); // Delay for 100 milliseconds
    } catch (error) {
      console.error("Scanner initialization error:", error);
      setScannerError("Unable to access camera. Please check permissions and try again.");
      setTimeout(closeScanner, 3000);
    }
  };

  const closeScanner = async () => {
    if (scannerRef.current) {
      try {
        await scannerRef.current.stop();
        scannerRef.current = null;
      } catch (error) {
        console.error("Error stopping scanner:", error);
      }
    }
    setShowScanner(false);
    setScannerError("");
  };

  const closePermissionModal = () => {
    setShowPermissionModal(false);
    setScannerError("");
  };

  return (
    <div className={`flex-1 flex items-center ${className}`}>
      <div className="w-full relative">
        <input
          ref={ref}
          type="text"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder="Enter customer's username or member ID"
          disabled={isSearching}
          className={`w-full pl-4 pr-24 py-2 rounded-lg border ${
            isDarkMode
              ? "bg-slate-700 border-slate-600 text-white placeholder:text-slate-400"
              : "bg-white border-gray-300 text-gray-900 placeholder:text-gray-500"
          } focus:outline-none focus:ring-2 focus:ring-blue-500 disabled:opacity-50`}
        />
        <div className="absolute right-2 top-1/2 -translate-y-1/2 flex items-center gap-2">
          {cameraAvailable && (
            <button
              type="button"
              onClick={handleScanClick}
              disabled={isSearching}
              className={`p-2 rounded-lg ${
                isDarkMode
                  ? "hover:bg-slate-600 text-slate-300"
                  : "hover:bg-gray-100 text-gray-600"
              } disabled:opacity-50`}
            >
              <QrCodeIcon className="h-5 w-5" />
            </button>
          )}
          <button
            type="button"
            onClick={handleSearch}
            disabled={isSearching}
            className="p-2 rounded-lg bg-blue-500 text-white hover:bg-blue-600 disabled:opacity-50"
          >
            {isSearching ? (
              <div className="h-5 w-5 border-2 border-white border-t-transparent rounded-full animate-spin" />
            ) : (
              <MagnifyingGlassIcon className="h-5 w-5" />
            )}
          </button>
        </div>
      </div>

      {/* Permission Request Modal */}
      {showPermissionModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div
            className={`relative p-6 rounded-lg max-w-sm w-full mx-4 ${
              isDarkMode ? "bg-slate-800" : "bg-white"
            }`}
          >
            <div className="text-center">
              <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 mb-4">
                <CameraIcon className="h-6 w-6 text-blue-600" />
              </div>
              <h3
                className={`text-lg font-medium mb-2 ${
                  isDarkMode ? "text-white" : "text-gray-900"
                }`}
              >
                Camera Access Required
              </h3>
              <p
                className={`text-sm mb-6 ${
                  isDarkMode ? "text-slate-300" : "text-gray-500"
                }`}
              >
                To scan QR codes and barcodes, we need access to your camera.
                Please enable camera access in your device settings.
              </p>
              <div className="flex flex-col gap-3">
                <button
                  onClick={openSystemSettings}
                  className="w-full px-4 py-2 text-sm font-medium text-white bg-blue-500 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  Open Settings
                </button>
                <button
                  onClick={closePermissionModal}
                  className={`w-full px-4 py-2 text-sm font-medium rounded-lg ${
                    isDarkMode
                      ? "bg-slate-700 text-white hover:bg-slate-600"
                      : "bg-gray-100 text-gray-900 hover:bg-gray-200"
                  }`}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Scanner Modal */}
      {showScanner && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className={`relative p-4 rounded-lg ${isDarkMode ? "bg-slate-800" : "bg-white"}`}>
            <button onClick={closeScanner} className="absolute top-2 right-2 p-1 rounded-full hover:bg-opacity-80">
              <XMarkIcon className="h-5 w-5 text-gray-500" />
            </button>
            {/* Scanner container */}
            <div id="reader" className="w-[300px] h-[300px]"></div>
            {scannerError && (
              <div className="mt-4 text-center text-red-500 text-sm">
                {scannerError}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
});

CustomerSearch.displayName = "CustomerSearch";

export default CustomerSearch;
