import { useEffect, useState } from "react";
import { useTheme } from "../contexts/ThemeContext";
import BusinessService from "../services/businessService";
import {
  GiftIcon,
  TagIcon,
  UsersIcon,
  BuildingStorefrontIcon,
  UserGroupIcon,
} from "@heroicons/react/24/outline";
import PropTypes from "prop-types";

const DashboardOwner = ({ onNavigate }) => {
  const { theme } = useTheme();
  const [stats, setStats] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        setLoading(true);
        const response = await BusinessService.getBusinessStats();
        setStats(response);
      } catch (error) {
        setError("Failed to fetch stats.");
        console.error('Error fetching stats:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchStats();
  }, []);

  if (loading) return (
    <div className="flex justify-center items-center p-8">
      <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-emerald-500"></div>
    </div>
  );
  if (error) return <div>{error}</div>;

  const statsData = [
    {
      title: "Active Giftcards",
      value: stats.gcCount !== undefined ? stats.gcCount : "0",
      icon: GiftIcon,
      view: "giftcards",
    },
    {
      title: "Active Offers",
      value: stats.offersCount !== undefined ? stats.offersCount : "0",
      icon: TagIcon,
      view: "offers",
    },
    {
      title: "Subscribers",
      value: stats.subscribersCount !== undefined ? stats.subscribersCount : "0",
      icon: UsersIcon,
      view: "subscribers",
    },
    {
      title: "Stores",
      value: stats.storeCount !== undefined ? stats.storeCount : "0",
      icon: BuildingStorefrontIcon,
      view: "stores",
    },
    {
      title: "Active Staff",
      value: stats.staffCount !== undefined ? stats.staffCount : "0",
      icon: UserGroupIcon,
      view: "staff",
    },
  ];

  return (
    <div className="container mx-auto px-4 py-6">
      <div className="overflow-x-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
          {statsData.map((stat) => (
            <button
              key={stat.title}
              onClick={() => onNavigate(stat.view)}
              className={`p-4 rounded-lg transition-all ${
                theme === "dark"
                  ? "bg-slate-800 hover:bg-slate-700"
                  : "bg-white hover:bg-gray-50"
              }`}
            >
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <stat.icon
                    className={`h-6 w-6 transition-colors ${
                      theme === "dark"
                        ? "text-slate-400 group-hover:text-slate-300"
                        : "text-gray-400 group-hover:text-gray-500"
                    }`}
                  />
                </div>
                <div className="ml-5 w-0 flex-1">
                  <dl>
                    <dt
                      className={`truncate text-sm font-medium ${
                        theme === "dark" ? "text-slate-400" : "text-gray-500"
                      }`}
                    >
                      {stat.title}
                    </dt>
                    <dd className="mt-1">
                      <span
                        className={`text-2xl font-semibold ${
                          theme === "dark" ? "text-white" : "text-gray-900"
                        }`}
                      >
                        {stat.value}
                      </span>
                    </dd>
                  </dl>
                </div>
              </div>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

DashboardOwner.propTypes = {
  onNavigate: PropTypes.func.isRequired,
};

export default DashboardOwner;
